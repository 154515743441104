<template>
  <div>
    <div class="container">
      <div id="tui-image-editor"></div>
      <div class="control" v-if="this.currentModelType === '3'">
        <!-- 上一步 -->
        <div class="undo icon" @click="undo">
          <img src="../assets/static/undo.png" alt="" />
        </div>
        <!-- 下一步 -->
        <div class="redo icon" @click="redo">
          <img src="../assets/static/redo.png" alt="" />
        </div>
      </div>
      <template v-if="this.currentModelType === '3'">
        <canvas
          class="drawingCanvas"
          ref="drawingCanvas"
          @mousedown="startDrawing"
          @mousemove="draw"
          @mouseup="stopDrawing"
          @mouseout="stopDrawing"
          :width="canvasWidth"
          :height="canvasHeight"
          :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }"
        ></canvas>
      </template>

      <!--<button @click="uploadImg">完成并上传</button>-->
    </div>
  </div>
</template>
<script>
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";

// const ImageEditor = require('tui-image-editor')
import ImageEditor from "tui-image-editor";
const locale_zh = {
  // override default English locale to your custom
  Crop: "裁剪",
  DeleteAll: "全部删除",
  Delete: "删除",
  Undo: "撤销",
  Redo: "反撤销",
  Reset: "重置",
  Flip: "镜像",
  Rotate: "旋转",
  Draw: "画",
  Shape: "形状标注",
  Icon: "图标标注",
  Text: "文字标注",
  Mask: "遮罩",
  Filter: "滤镜",
  Bold: "加粗",
  Italic: "斜体",
  Underline: "下划线",
  Left: "左对齐",
  Center: "居中",
  Right: "右对齐",
  Color: "颜色",
  "Text size": "字体大小",
  Custom: "自定义",
  Square: "正方形",
  Apply: "应用",
  Cancel: "取消",
  "Flip X": "X 轴",
  "Flip Y": "Y 轴",
  Range: "区间",
  Stroke: "描边",
  Fill: "填充",
  Circle: "圆",
  Triangle: "三角",
  Rectangle: "矩形",
  Free: "曲线",
  Straight: "直线",
  Arrow: "箭头",
  "Arrow-2": "箭头2",
  "Arrow-3": "箭头3",
  "Star-1": "星星1",
  "Star-2": "星星2",
  Polygon: "多边形",
  Location: "定位",
  Heart: "心形",
  Bubble: "气泡",
  "Custom icon": "自定义图标",
  "Load Mask Image": "加载蒙层图片",
  Grayscale: "灰度",
  Blur: "模糊",
  Sharpen: "锐化",
  Emboss: "浮雕",
  "Remove White": "除去白色",
  Distance: "距离",
  Brightness: "亮度",
  Noise: "噪音",
  "Color Filter": "彩色滤镜",
  Sepia: "棕色",
  Sepia2: "棕色2",
  Invert: "负片",
  Pixelate: "像素化",
  Threshold: "阈值",
  Tint: "色调",
  Multiply: "正片叠底",
  Blend: "混合色",
  // etc...
};
const customTheme = {
  // image 坐上角度图片
  "common.bi.image": "https://uploadbeta.com/api/pictures/random/", // 在这里换上你喜欢的logo图片
  "common.bisize.width": "0px",
  "common.bisize.height": "0px",
  "common.backgroundImage": "none",
  "common.backgroundColor": "#111",
  "common.border": "1px solid #444",

  // header
  "header.backgroundImage": "none",
  "header.backgroundColor": "#111",
  "header.border": "0px",
  "header.display": "none",

  // load button
  "loadButton.backgroundColor": "#fff",
  "loadButton.border": "1px solid #ddd",
  "loadButton.color": "#222",
  "loadButton.fontFamily": "NotoSans, sans-serif",
  "loadButton.fontSize": "12px",
  "loadButton.display": "none", // 可以直接隐藏掉

  // download button
  "downloadButton.backgroundColor": "#fdba3b",
  "downloadButton.border": "1px solid #fdba3b",
  "downloadButton.color": "#fff",
  "downloadButton.fontFamily": "NotoSans, sans-serif",
  "downloadButton.fontSize": "12px",
  "downloadButton.display": "none", // 可以直接隐藏掉

  // icons default
  "menu.normalIcon.color": "#8a8a8a",
  "menu.activeIcon.color": "#555555",
  "menu.disabledIcon.color": "#434343",
  "menu.hoverIcon.color": "#e9e9e9",
  "submenu.normalIcon.color": "#8a8a8a",
  "submenu.activeIcon.color": "#e9e9e9",

  "menu.iconSize.width": "24px",
  "menu.iconSize.height": "24px",
  "submenu.iconSize.width": "32px",
  "submenu.iconSize.height": "32px",

  // submenu primary color
  "submenu.backgroundColor": "#1e1e1e",
  "submenu.partition.color": "#858585",

  // submenu labels
  "submenu.normalLabel.color": "#858585",
  "submenu.normalLabel.fontWeight": "lighter",
  "submenu.activeLabel.color": "#fff",
  "submenu.activeLabel.fontWeight": "lighter",

  // checkbox style
  "checkbox.border": "1px solid #ccc",
  "checkbox.backgroundColor": "#fff",

  // rango style
  "range.pointer.color": "#fff",
  "range.bar.color": "#666",
  "range.subbar.color": "#d1d1d1",

  "range.disabledPointer.color": "#414141",
  "range.disabledBar.color": "#282828",
  "range.disabledSubbar.color": "#414141",

  "range.value.color": "#fff",
  "range.value.fontWeight": "lighter",
  "range.value.fontSize": "11px",
  "range.value.border": "1px solid #353535",
  "range.value.backgroundColor": "#151515",
  "range.title.color": "#fff",
  "range.title.fontWeight": "lighter",

  // colorpicker style
  "colorpicker.button.border": "1px solid #1e1e1e",
  "colorpicker.title.color": "#fff",
};

export default {
  props: {
    wid: {
      type: [Number, String],
      default: "",
      //     画笔粗细
    },
    clo: {
      type: String,
      default: "",
      //     画笔颜色
    },
    url: {
      type: String,
      default: "",
      //     素材
    },
    val: {
      type: [String, Number],
      default: 0,
      //     素材
    },
    imageWidth: {
      type: Number,
      default: 630,
    },
    imageHeight: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      instance: null,
      canvasWidth: 630,
      canvasHeight: 544,
      drawing: false,
      lastX: 0,
      lastY: 0,
      ctx: null,
      currentModelType: "",
      // 存储绘制操作的数组
      history: [],
      // 存储绘制操作的索引
      historyIndex: 0,
      index: 0,
      allStep: 0,
      preStep: 0,
      nextStep: 0,
      drawingImage: "",
      isPartTu: false,
      exportCanvas: null,
      canvas: null,
      exportContext: null,
      hasTu: false,
    };
  },
  watch: {
    wid: {
      handler(newVal, oldVal) {
        // console.log(newVal)
        // this.wid = newVal
        if (this.instance) {
          this.instance.setBrush({
            width: this.wid,
            color: this.clo,
          });
        }
      },
      deep: true,
      immediate: true,
    },
    clo: {
      handler(newVal, oldVal) {
        // this.clo = newVal
        if (this.instance) {
          this.instance.setBrush({
            width: this.wid,
            color: this.clo,
          });
        }
      },
      deep: true,
      immediate: true,
    },
    url: {
      handler(newVal, oldVal) {
        // this.clo = newVal
        // console.log(newVal)
        this.Surl();
      },
      deep: true,
      immediate: true,
    },
    val: {
      handler(newVal, oldVal) {
        // this.clo = newVal
        this.Sfilter();
      },
      deep: true,
      immediate: true,
    },
    // imageWidth: {
    //   handler(newVal, oldVal) {
    //     console.log("图片宽", newVal);
    //     // canvas.width = newVal;
    //     this.canvasWidth = newVal;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
    // imageHeight: {
    //   handler(newVal, oldVal) {
    //     console.log("图片高", newVal);
    //     // canvas.height = newVal;
    //     this.canvasHeight = newVal;
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  beforeMount() {},
  mounted() {
    localStorage.setItem("hasTu", JSON.stringify(this.hasTu));
    this.instance = new ImageEditor(
      document.querySelector("#tui-image-editor"),
      {
        includeUI: {
          loadImage: {
            path: this.$store.state.scrawlImg,
            // path: this.blankbg,
            name: "image",
          },
          menu: [],
          initMenu: "draw",
          menuBarPosition: "bottom",
          locale: locale_zh,
          theme: customTheme,
          uiSize: {
            // width: this.canvasWidth,
            // height: this.canvasHeight,
            width: "630",
            height: "544",
          },
        },
        // cssMaxWidth: this.canvasWidth,
        // cssMaxHeight: this.canvasHeight,
        cssMaxWidth: 630,
        cssMaxHeight: 544,
      }
    );
    document.getElementsByClassName("tui-image-editor-main")[0].style.top = 0;
    this.currentModelType = localStorage.getItem("selectedModelType");
    if (this.currentModelType == "3") {
      document.querySelector(".tui-image-editor-help-menu").style.display =
        "none";
    }
  },
  methods: {
    startDrawing(event) {
      this.isPartTu = true;
      localStorage.setItem("hasTu", JSON.stringify(this.hasTu));
      ++this.index;
      ++this.preStep;
      this.drawing = true;
      this.ctx.lineJoin = "round";
      const { offsetX, offsetY } = event;
      this.lastX = offsetX;
      this.lastY = offsetY;
      // 添加新的绘制操作
      this.history.splice(
        this.historyIndex,
        this.history.length - this.historyIndex,
        {
          type: "start",
          offsetX: event.offsetX,
          offsetY: event.offsetY,
          lastX: event.offsetX,
          lastY: event.offsetY,
          color: this.clo,
          width: this.wid,
        }
      );
      this.historyIndex++;
    },
    draw(event) {
      this.hasTu = true;
      if (!this.drawing) return;
      const { offsetX, offsetY } = event;
      // 画线
      // this.ctx.globalAlpha = 0.1
      this.ctx.beginPath();
      this.ctx.lineJoin = "round";
      this.ctx.lineCap = "round";
      this.ctx.strokeStyle = this.clo;
      this.ctx.lineWidth = this.wid;
      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(offsetX, offsetY);
      this.ctx.stroke();

      this.lastX = offsetX;
      this.lastY = offsetY;

      this.history.push({
        type: "draw",
        offsetX: event.offsetX,
        offsetY: event.offsetY,
        lastX: event.offsetX,
        lastY: event.offsetY,
        color: this.clo,
        width: this.wid,
      });
      this.historyIndex++;

      // this.history.splice(this.historyIndex, this.history.lengtn - this.historyIndex, {
      //     type: 'draw',
      //     offsetX: event.offsetX,
      //     offsetY: event.offsetY,
      //     lastX: event.offsetX,
      //     lastY: event.offsetY,
      // })
      // this.historyIndex++
      // // 更新绘制操作的最后位置
      // this.history[this.historyIndex].lastX = offsetX
      // this.history[this.historyIndex].lastY = offsetY
    },
    stopDrawing() {
      this.drawing = false;
    },

    getDrawingCanvas() {
      // 获取设备的分辨率
      const devicePixelRatio = window.devicePixelRatio || 1;
      // const canvas = this.$refs.drawingCanvas;
      this.canvas = this.$refs.drawingCanvas;

      this.ctx = this.canvas.getContext("2d");

      const element = document.getElementsByClassName("tui-image-editor")[0];
      this.ctx.lineJoin = "round";
      this.canvas.width = element.offsetWidth;
      this.canvas.height = element.offsetHeight;
      // 缩放Canvas以适应CSS像素大小
      this.ctx.scale(devicePixelRatio, devicePixelRatio);
      this.canvasWidth = element.offsetWidth;
      this.canvasHeight = element.offsetHeight;

      this.exportCanvas = document.createElement("canvas");
      const camvaseSize = this.instance.getCanvasSize();
      // console.log("camvaseSize", camvaseSize);
      this.exportCanvas.width = camvaseSize.width;
      this.exportCanvas.height = camvaseSize.height;
      this.exportContext = this.exportCanvas.getContext("2d");
    },

    handleMessage(i) {
      this.type = i;
      this.$router.push("/");
    },
    Sfilter() {
      // let filter = {
      //     type: 'Contrast',
      //     options: {
      //         contrast: 2 // 应用1.5倍的对比度调整
      //     },
      //     isSilent: false // 触发相关事件或回调函数
      // };
      const options = {
        contrast: this.val,
      };
      if (this.instance) {
        this.instance.applyFilter("Contrast", options, false);
      }
    },
    Surl() {
      if (this.instance) {
        this.instance.addImageObject(this.url);
      }
    },
    Sdraw() {
      // console.log('画笔触发', this.isDraw)
      if (this.currentModelType === "3" && !this.ctx) {
        this.getDrawingCanvas();
        return;
      }
      this.instance.startDrawingMode("FREE_DRAWING", {
        width: this.wid,
        color: this.clo,
      });
    },

    getDrawingImages() {
      // const canvas = this.$refs.drawingCanvas;
      // this.drawingImage = "";
      // this.drawingImage = this.canvas.toDataURL("image/png");
      this.exportContext.drawImage(
        this.canvas, // 源画布
        0, // 源X坐标
        0, // 源Y坐标
        this.canvasWidth, // 源宽度
        this.canvasHeight, // 源高度
        0, // 目标X坐标
        0, // 目标Y坐标
        this.exportCanvas.width, // 目标宽度
        this.exportCanvas.height // 目标高度
      );
      this.drawingImage = "";
      this.drawingImage = this.exportCanvas.toDataURL("image/png");
      this.uploadImg();
    },

    // 涂鸦上传图片
    uploadImg() {
      // console.log("uploadImg");
      const base64String = this.instance.toDataURL();
      const data =
        this.currentModelType == "3" && this.isPartTu
          ? window.atob(this.drawingImage.split(",")[1])
          : window.atob(base64String.split(",")[1]);

      const ia = new Uint8Array(data.length);
      for (let i = 0; i < data.length; i++) {
        ia[i] = data.charCodeAt(i);
      }

      const blob = new Blob([ia], { type: "image/png" });
      const fd = new FormData();
      const fileName = "image_" + new Date().getTime() + ".png"; // 自定义文件名，加上时间戳作为后缀
      fd.append("file", blob, fileName);
      fd.append("path", "image");
      this.$axios
        .post("upload", fd, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async (res) => {
          this.currentModelType == "3"
            ? this.$store.commit("getDrawingImage", res.data.url)
            : this.$store.commit("saveSurl", res.data.url);
          console.log("涂鸦上传图片res", res);
          let imageSize = await this.getImageSize(res.data.url);
          console.log("涂鸦层得图片尺寸", imageSize);
          this.$emit("close", true);
          // this.$store.commit('setUploadCompleted', true)
          // if (this.currentModelType == '3') {
          //     this.$store.commit('getDrawingImage', res.data.url)
          // } else {
          //     this.$store.commit('saveSurl', res.data.url)
          // }
          // this.avatar = res.data.url
          // this.avatar = require('@/assets/static/phone.png')
          // this.myCropper.replace(this.avatar, false)
          // 处理上传成功后的响应
        })
        .catch((error) => {
          // 处理上传失败后的响应
        });
      // upload fd
    },

    undo() {
      // console.log('上一步', this.index)
      // this.preStep--
      // this.nextStep++
      // if (this.preStep <= 0) return
      // this.redraw()

      if (this.historyIndex > 0) {
        this.historyIndex--;

        while (
          this.historyIndex > 0 &&
          this.history[this.historyIndex - 1].type !== "start"
        ) {
          this.historyIndex--;
        }
        this.redraw();
      }
    },
    redo() {
      // console.log('下一步', this.index)
      // if (this.nextStep > 0) {
      //     this.nextStep--
      //     this.preStep++
      //     console.log('this.nextStep < this.preStep', this.nextStep, this.preStep)
      //     if (this.nextStep < this.preStep) return
      //     this.redraw()
      // }

      if (this.historyIndex < this.history.length) {
        this.historyIndex++;
        while (
          this.historyIndex < this.history.length &&
          this.history[this.historyIndex].type !== "start"
        ) {
          this.historyIndex++;
        }
        this.redraw();
      }
    },
    redraw() {
      // 清空画布
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
      // 重新绘制历史操作
      for (let i = 0; i < this.historyIndex; i++) {
        const action = this.history[i];
        // console.log('type', action, i)
        const { offsetX, offsetY, lastX, lastY, width, color } = action;
        if (action.type === "start") {
          this.ctx.globalAlpha = 0.1;
          this.ctx.beginPath();
          this.ctx.strokeStyle = color;
          this.ctx.lineWidth = width;
          this.ctx.moveTo(lastX, lastY);
          this.ctx.lineTo(offsetX, offsetY);
          this.ctx.stroke();
        } else if (action.type === "draw") {
          // const { offsetX, offsetY, lastX, lastY } = action
          // console.log('offsetX, offsetY, lastX, lastY', offsetX, offsetY, lastX, lastY, width, color)
          // this.ctx.beginPath()
          // this.ctx.strokeStyle = color
          // this.ctx.lineWidth = width
          // this.ctx.moveTo(lastX, lastY)
          this.ctx.globalAlpha = 0.1;
          this.ctx.lineTo(offsetX, offsetY);
          this.ctx.stroke();
        }
      }
    },
    // clearDrawing() {
    //     const canvas = this.$refs.drawingCanvas
    //     const ctx = canvas.getContext('2d')
    //     ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    // },
    // 添加一个方法，用于获取原始图片尺寸
    getImageSize(imageSrc) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = function () {
          resolve({ width: this.width, height: this.height });
        };
        img.onerror = function () {
          reject(new Error("Failed to load image"));
        };
        img.src = imageSrc;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 700px;
  width: 1185px;
  /*height: 90vh;*/
  text-align: center;
  position: relative;
  .tui-image-editor-container {
    .tui-image-editor-wrap {
      font-size: 30px;
      /*滚动条样式*/
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width: 4.5px; /*高宽分别对应横竖滚动条的尺寸*/
        height: 3px;
      }

      &::-webkit-scrollbar-thumb {
        /*滚动条里面滚动条*/
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 5px #595959;
        background-color: #595959;
      }

      &::-webkit-scrollbar-track {
        /*滚动条里面轨道（背景）*/
        -webkit-box-shadow: inset 0 0 5px #454545;
        border-radius: 0;
        background-color: #454545;
      }
    }
  }

  .control {
    width: 160px;
    height: 45px;
    border-radius: 18px;
    // border: 1px solid;
    position: absolute;
    top: 5%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: rgba(51, 51, 51, 0.4);
    .icon {
      margin-top: 8px;
    }
  }

  .drawingCanvas {
    position: absolute;
    top: 45%; /* 子盒子顶部位于父盒子中间 */
    // left: 50%; /* 子盒子左侧位于父盒子中间 */
    transform: translate(-50%, -50%); /* 使用transform属性来水平和垂直居中 */
    // border: 1px solid red;
    z-index: 9999;
  }
}
</style>
