import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 新消息
        newMes: {},
        // 用户信息
        user: {},
        // 头部导航下标
        headerType: 2,
        sharedValue: '',
        headList: [
            {
                name: '首页',
                image: '',
            },
            {
                name: '社区',
                image: '',
            },
            {
                name: '工作台',
                image: '',
            },

            {
                name: '创意版',
                image: '',
            },
            {
                name: 'PRO',
                image: '',
            },
            // {
            //     name: '创意中心',
            //     image: '',
            // },

            // {
            // 	name: '消息',
            // 	image: ''
            // },
            // {
            // 	name: '',
            // 	image: require('@/assets/static/my.png')
            // }
        ],
        Workbenchimage: 'dsfdasf',
        messageType: false,
        myType: false,
        threeImg: '', //3d图片
        type: '', // three
        AItype: '',
        AIdata: null,
        scrawlImg: '',
        surl: '',
        //     局部数据
        Partdata: null,
        //     一键生成数据
        Onedata: null,
        //     照片美化
        Beatdata: null,
        // 展示工作台模块返回
        showPatternBack: true,
        // 文字模式只上传一直
        onlyUplImg: true,
        userInfo: {},
        drawingImage: '',
        uploadingCompleted: false,
    },
    getters: {},
    mutations: {
        saveSurl(state, i) {
            state.surl = ''
            state.surl = i
        },
        delSurl(state, i) {
            state.surl = ''
        },
        // 裁剪
        saveScrawl(state, i) {
            state.scrawlImg = ''
            state.scrawlImg = i
        },
        delScrawl(state, i) {
            state.scrawlImg = null
        },
        // 文本生成
        saveAIdata(state, i) {
            state.AIdata = i
        },
        delAIdata(state, i) {
            state.AIdata = null
        },
        // 局部修改
        savePartdata(state, i) {
            state.Partdata = i
        },
        delPartdata(state, i) {
            state.Partdata = null
        },
        // 一键生成
        saveOnedata(state, i) {
            state.Onedata = i
        },
        delOnedata(state, i) {
            state.Onedata = null
        },
        // 照片美化
        saveBeatdata(state, i) {
            state.Beatdata = i
        },
        delBeatdata(state, i) {
            state.Beatdata = null
        },
        saveAI(state, i) {
            state.AItype = i
        },
        delAI(state, i) {
            state.AItype = ''
        },
        savetype(state, i) {
            state.type = i
        },
        deltype(state, i) {
            state.type = ''
        },
        saveThree(state, i) {
            state.threeImg = i
        },
        delThree(state) {
            state.threeImg = ''
        },
        newMesHandel(state, i) {
            state.newMes = i
        },
        userHandel(state, i) {
            state.user = i
        },
        headerTypeHandel(state, i) {
            state.headerType = i
        },
        myHandel(state, i) {
            state.myType = i
        },
        messageHandel(state, i) {
            state.messageType = i
        },
        changeWorkbenchImage(state, payload) {
            state.Workbenchimage = payload
            // console.log('state.Workbenchimage', state.Workbenchimage)
        },
        delChangeWorkbenchImage(state) {
            state.Workbenchimage = ''
        },
        changeValue(state, payload) {
            state.sharedValue = payload
            if (state.headerType != 2) {
                state.headerType = 2
            }
        },
        SHOW_SUCCESS_MESSAGE(state, message) {
            state.successMessage = message // 更新状态中的 successMessage
        },
        SET_USER_INFO(state, userInfo) {
            // state.key = key;
        },
        changePatternBack(state, i) {
            state.showPatternBack = i
        },
        updateOnlyImgStatus(state, i) {
            state.onlyUplImg = i
        },
        getDrawingImage(state, src) {
            state.drawingImage = ''
            console.log('局部src', src)
            state.drawingImage = src
        },
        delDrawingImage(state) {
            state.drawingImage = ''
        },
        setUploadCompleted(state, value) {
            state.uploadingCompleted = value
            console.log('uploadingCompleted', state.uploadingCompleted)
        },
    },
    actions: {
        showSuccessMessage({ commit }, message) {
            Vue.prototype.$message({
                message,
                type: 'error',
            })
            commit('SHOW_SUCCESS_MESSAGE', message) // 提交 mutation 显示成功消息
        },
        login({ commit }, userInfo) {
            for (let key in userInfo) {
                localStorage.setItem(key, userInfo[key])
                commit('SET_USER_INFO', { key: userInfo[key] })
            }
        },
        unauthorized({ commit }, message) {
            Vue.prototype.$message({
                message,
                type: 'error',
            })
            localStorage.clear()
            commit('messageHandel', false)
            commit('myHandel', false)
            commit('changeValue', 1)
            setTimeout(() => {
                if (location.hash.indexOf('/login') == -1) {
                    router.push({ name: 'welcome' })
                }
            }, 2500)
        },
    },
    modules: {},
})
