<template>
    <div class="box1 boxs" id="box1">
        <div class="text-container">
            <div class="text active">
                <div class="p1">10秒点亮生设计创意</div>
                <div class="p2">设计效率快人一步</div>
            </div>
            <div class="text">
                <div class="p1">你可以在社区查看设计师们的创作</div>
                <div class="p2">让AI 为你找到最佳灵感</div>
            </div>
            <div class="text">
                <div class="p1">在工作台选择设计标签</div>
                <div class="p2">让AI无限设计大师点亮创意</div>
            </div>
            <div class="text">
                <div class="p1">把图片上传到工作台</div>
                <div class="p2">让AI帮你修改设计概念图</div>
            </div>
        </div>
        <div class="p3">
            <img
                class="phone"
                src="https://aiinfdesign.oss-cn-zhangjiakou.aliyuncs.com/static/image/phone.png"
                alt="" />
            <img
                class="computer"
                src="https://aiinfdesign.oss-cn-zhangjiakou.aliyuncs.com/static/image/computer.png"
                alt="" />
        </div>
        <div class="p4">
            <div>
                <img style="margin-right: 5px" src="../assets/static/apple.png" alt="" />
                iOS下载
                <div class="abso">
                    <img src="../assets/static/ios-qr.png" alt="" />
                </div>
            </div>
            <div>
                <img style="margin-right: 5px" src="../assets/static/Android.png" alt="" />
                Android下载
                <div class="abso">
                    <!--<img src="../assets/static/android-qr.png" alt="">-->
                    <img src="../assets/static/Android-pgy.png" alt="" />
                </div>
            </div>
        </div>
        <div style="height: 14rem"></div>
        <!-- <div>23423423f234234234234234242342342</div> -->
    </div>
</template>
<script>
export default {
    activated() {
        // 在这里编写组件显示时所需要执行的代码
    },
    deactivated() {
        // 在这里编写组件停用时所需要执行的代码
    },
    mounted() {
        this.textHandel()
        // window.addEventListener('wheel', this.handleMouseWheel, {
        //   passive: false
        // })
    },
    beforeDestroy() {
        // window.removeEventListener('wheel', this.handleMouseWheel, {
        //   passive: false
        // })
    },
    data() {
        return {
            y: 0,
        }
    },
    methods: {
        // handleMouseWheel(i) {
        //   if (i == 'bottom') {
        //     this.$store.commit('headerTypeHandel', 1)
        //   }
        // },
        // handleMouseWheel(e) {
        //   this.y += e.wheelDeltaY
        //   if (this.y < -120) {
        //     this.$store.commit('headerTypeHandel', 1)
        //   }
        //   if (this.y > 120) {
        //     this.y = 0
        //   }
        // },
        textHandel() {
            let currentText = 0

            const textContainer = document.querySelector('.text-container')

            const texts = textContainer.querySelectorAll('.text')

            // 设置在文本元素之间循环的间隔
            function nextText() {
                texts[currentText].classList.remove('active')
                texts[currentText].style.transform = 'translateX(100%)'
                currentText = (currentText + 1) % texts.length
                texts[currentText].classList.add('active')
                setTimeout(() => {
                    texts[currentText].style.transform = 'translateX(0%)'
                }, 1000)
            }

            nextText()

            // // 在页面加载完成后立即触发一次文本轮播
            // window.addEventListener('load', () => {
            // nextText()
            // })

            // 将文本轮播的间隔时间延长，避免文本元素瞬间消失
            setInterval(() => {
                nextText()
            }, 8000)
        },
    },
}
</script>
<style>
.box1 {
    /* margin-top: 2rem; */
    height: calc(100vh - 65px);
    padding-top: 3.875rem;
    box-sizing: border-box;
    background: url('https://aiinfdesign.oss-cn-zhangjiakou.aliyuncs.com/static/image/newsbg.png') no-repeat 0px 0px /
        100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
}

.box1 .p1 {
    /* margin-top: 78px; */
    height: 4.25rem;
    /* text-align: center; */
    font-size: 2.5rem;
    font-weight: 500;
    color: #ffffff;
    line-height: 4.25rem;
}

.box1 .p2 {
    height: 2.25rem;
    font-size: 1.75rem;
    font-weight: 400;
    color: #ffffff;
    line-height: 2.25rem;
    margin-bottom: 1.875rem;
}

.box1 > .p3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.box1 > .p3 .phone {
    width: 192px;
    height: 368px;
}

.box1 > .p3 .computer {
    width: 491px;
    height: 368px;
}

/* .box1>.p3 .phone{
  width: 12rem;
  height: 23rem;
}
.box1>.p3 .computer{
  width: 491px;
  height: 376px;
} */

.box1 > .p4 {
    display: flex;
    margin-top: 2.8125rem;
}

.box1 > .p4 > div {
    display: flex;
    align-items: center;
    width: 11.5rem;
    height: 3.4375rem;
    width: 11.5rem;
    height: 3.4375rem;
    background: #111111;
    border-radius: 0.4375rem;
    justify-content: center;
    color: white;
    margin: 0 1.25rem;
    cursor: pointer;
    position: relative;
    -webkit-border-radius: 0.4375rem;
    -moz-border-radius: 0.4375rem;
    -ms-border-radius: 0.4375rem;
    -o-border-radius: 0.4375rem;
}

.box1 > .p4 > div:hover .abso {
    display: block;
}

.abso {
    left: 0;
    position: absolute;
    top: 4rem;
    width: 11.5rem;
    height: 11.5rem;
    border-radius: 0.625rem;
    -webkit-border-radius: 0.625rem;
    -moz-border-radius: 0.625rem;
    -ms-border-radius: 0.625rem;
    -o-border-radius: 0.625rem;
    padding: 1.375rem;
    box-sizing: border-box;
    display: none;
    background: #111111;
}

.abso img {
    width: 8.625rem;
    height: 8.625rem;
    border-radius: 0.3125rem;
    -webkit-border-radius: 0.3125rem;
    -moz-border-radius: 0.3125rem;
    -ms-border-radius: 0.3125rem;
    -o-border-radius: 0.3125rem;
}

/* 文字淡入淡出样式 */
.text-container {
    position: relative;
    overflow: hidden;
    width: 37.5rem;
    height: 8.75rem;
    flex-shrink: 0;
    /* margin-bottom: 100px; */
}

.text-container .text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    /*margin-bottom: 10px;
  */
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: opacity 5s, transform 3s;
    transform: translateX(0%);
    -webkit-transition: opacity 5s, transform 3s;
    -moz-transition: opacity 5s, transform 3s;
    -ms-transition: opacity 5s, transform 3s;
    -o-transition: opacity 5s, transform 3s;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
}

.text-container .active {
    opacity: 1;
    transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
}
</style>
