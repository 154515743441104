import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err)
}

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeMerge.vue'),
    },
    {
        path: '/welcome',
        name: 'welcome',
        component: () => import('../views/Welcome.vue'),
    },
    {
        path: '/pic',
        name: 'pic',
        component: () => import('../views/Pic.vue'),
    },
    {
        path: '/Comment',
        name: 'Comment',
        component: () => import('../views/Comment.vue'),
    },
    {
        path: '/My',
        name: 'My',
        component: () => import('../views/My.vue'),
        children: [
            {
                path: '/my/', // 子路由1
                name: 'personal',
                component: () => import('../views/my/personal.vue'),
            },
            {
                path: '/my/Aboutus', // 子路由2
                name: 'Aboutus',
                component: () => import('../views/my/Aboutus.vue'),
            },
            {
                path: '/my/Loginpassword', // 子路由2
                name: 'Loginpassword',
                component: () => import('../views/my/Loginpassword.vue'),
            },
            {
                path: '/my/PrivacyAgreement', // 子路由2
                name: 'PrivacyAgreement',
                component: () => import('../views/my/PrivacyAgreement.vue'),
            },
            {
                path: '/my/Useragreement', // 子路由2
                name: 'Useragreement',
                component: () => import('../views/my/Useragreement.vue'),
            },
            {
                path: '/my/Myneeds', // 子路由2
                name: 'Myneeds',
                component: () => import('../views/my/Myneeds.vue'),
            },
            {
                path: '/my/Bindphone', // 子路由2
                name: 'Bindphone',
                component: () => import('../views/my/Bindphone.vue'),
            },
        ],
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/Login.vue'),
    },
    {
        path: '/Message',
        name: 'Message',
        component: () => import('../views/Message.vue'),
    },
    {
        path: '/workPattern',
        name: 'workPattern',
        component: () => import('../views/workPattern.vue'),
    },
    {
        path: '/DemandDetail',
        name: 'DemandDetail',
        component: () => import('../views/my/DemandDetail.vue'),
    },
    // {
    //     path: '/operating',
    //     name: 'operating',
    //     component: () => import('../components/operating.vue'),
    // },
    {
        path: '/merge',
        name: 'merge',
        component: HomeView,
        // meta: { keepAlive: true } // 启用缓存
    },
    {
        path: '/Settlein',
        name: 'Settlein',
        component: () => import('../views/Settlein.vue'),
    },
    {
        path: '/SelectPicture',
        name: 'SelectPicture',
        component: () => import('../views/SelectPicture.vue'),
    },
    {
        path: '/CreationCentre',
        name: 'CreationCentre',
        component: () => import('../views/CreationCentre.vue'),
    },
    {
        path: '/CreationList',
        name: 'CreationList',
        component: () => import('../views/CreationList.vue'),
    },
    {
        path: '/CreationDetail',
        name: 'CreationDetail',
        component: () => import('../views/CreationDetail.vue'),
    },
    {
        path: '/Freeentry',
        name: 'Freeentry',
        component: () => import('../views/Freeentry.vue'),
        //     设计师入驻
    },
    {
        path: '/FirmEnter',
        name: 'FirmEnter',
        component: () => import('../views/FirmEnter.vue'),
        //     公司入驻
    },
    {
        path: '/wantEnter',
        name: 'wantEnter',
        component: () => import('../views/wantEnter.vue'),
        //     选择入驻
    },
    {
        path: '/3dphoto',
        name: '3dphoto',
        component: () => import('../views/3dphoto.vue'),
        //     3d图片
    },
    {
        path: '/phoneThree',
        name: 'phoneThree',
        component: () => import('../views/phoneThree.vue'),
        //     3d图片
    },
    {
        path: '/exam',
        name: 'exam',
        component: () => import('../views/exam.vue'),
    },
    // {
    //     path: '/ceshi',
    //     name: 'ceshi',
    //     component: () => import('../views/ceshi.vue')
    //     //     3d图片
    // },
    {
        path: '/designsucess',
        name: 'designsucess',
        component: () => import('../views/DesignCompleted.vue'),
    },
    {
        path: '/need',
        name: 'need',
        component: () => import('../views/Need.vue'),
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/pro',
        name: 'PRO',
        component: () => import('../components/Myinfo.vue'),
    },
]

const router = new VueRouter({
    mode: 'hash',
    routes,
    base: './',
})
router.beforeEach((to, from, next) => {
    const isLoggedIn = localStorage.getItem('token') !== null // 自定义函数，用于检查用户是否已登录

    if (to.path === '/welcome' || to.path === '/login' || to.path === 'phoneThree') {
        // 已登录时禁止跳转到登录页或首页
        if (isLoggedIn) {
            next('/') // 已登录时重定向到其他页面，例如'/home'
        } else {
            next() // 未登录允许访问登录页或首页
        }
    } else {
        // 其他页面不受限制，允许通过
        next()
    }
})

// 判断用户是否已登录
function isLoggedIn() {
    return localStorage.getItem('token') !== null
}

export default router
