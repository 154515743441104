<template>
    <div class="merge scroll" ref="container" v-scroll="handleMouseWheel" @scroll="handleScroll">
        <news></news>
        <community :rows="rows" :total="total"></community>

    </div>
</template>
<script>
import page from '@/config/page'

import news from '@/components/news.vue'
import community from '@/components/community'

export default {
    mixins: [page],
    data() {
        return {
            action: '/design/works/page'
        }
    },
    mounted() {
        this.getList(1)
    },
    methods: {
        handleMouseWheel(e) {
            console.log(e)
        }
    },
    components: {news, community}
}
</script>
<style lang="scss">
.merge {
    overflow: scroll;
}
</style>