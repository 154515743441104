<template>
    <div class="wrap">
        <FullLoading :show="cjUploading || tyUploading"></FullLoading>
        <div class="fiexdwrap" v-if="fixedType"></div>
        <div class="header">
            <div style="height: 25px"></div>
            <div class="title">
                <!--<div> 121212</div>-->
                <!---->
                <div class="new" @click="noviceGuide">
                    <img style="width: 20px; height: 20px" src="@/assets/static/new.png" />
                    <div class="new-text">新手引导</div>
                </div>
            </div>
        </div>

        <!-- 文字模式 -->
        <div class="wrapflex">
            <div class="left">
                <!--AI文本描述-->
                <div class="AI-box">
                    <!--AI标题-->
                    <div class="AI-title">输入想要生成的内容描述</div>
                    <!--    想要的效果文本-->
                    <div class="want-text">
                        <textarea
                            v-model="needData.prompt"
                            placeholder="请输入文字描述，例如：别墅的真实照片，由 Arata lsozaki 设计，现代别墅，色彩鲜明，白色立面，白色油漆立面，石头，建筑外部的建筑摄影，美丽的花园，道路，花草围绕，深色瓦屋顶，全景照片"
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            @input="sendData"
                            @blur="sendData"></textarea>
                    </div>
                    <!--    想要删除的标题-->
                    <div class="del-title">输入不想要的内容描述</div>
                    <div class="del-text">
                        <textarea
                            v-model="needData.negative_prompt"
                            placeholder="请输入文字描述不想看到的内容，例如：人物，丑陋的"
                            name=""
                            id=""
                            cols="30"
                            rows="10"
                            @input="sendData"
                            @blur="sendData"></textarea>
                        <!--    @focus="sendData"-->
                    </div>

                    <!--上传图片和参考图-->
                    <div class="img-box">
                        <!--上传图片-->
                        <div class="img-list">
                            <div
                                class="img-item"
                                v-show="needData.image.length > 0"
                                v-for="(item, index) in needData.image"
                                :key="index">
                                <img :src="item" class="img-data" />
                                <div class="del-icon" @click="delsample(index)">
                                    <img src="@/assets/static/close-two.png" />
                                </div>
                            </div>
                            <div class="img-item" style="position: relative" v-show="needData.image.length < 1">
                                <img class="add-img" src="@/assets/static/add1.png" />
                                <input type="file" ref="sampleInput" @change="sampleFileUpload" />
                                <div class="text-description">上传照片</div>
                            </div>
                        </div>
                        <!--上传图片-->
                        <div class="img-list">
                            <div
                                class="img-item"
                                v-show="needData.image2.length > 0"
                                v-for="(item, index) in needData.image2"
                                :key="index">
                                <img :src="item" class="img-data" />
                                <div class="del-icon" @click="delrefer(index)">
                                    <img src="@/assets/static/close-two.png" />
                                </div>
                            </div>
                            <div class="img-item" style="position: relative" v-show="needData.image2.length < 1">
                                <img class="add-img" src="@/assets/static/add1.png" />
                                <!-- <div class="my-add"></div> -->
                                <input type="file" ref="referInput" @change="referFileUpload" />
                                <div class="text-description">添加风格参考</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div
                v-if="!classList.length"
                style="display: flex; align-items: center; justify-content: center"
                class="right">
                <loading></loading>
            </div>
            <div v-else class="right">
                <div style="display: flex; flex-direction: row; flex-wrap: wrap">
                    <div
                        @click="HouseHandel(el, idx)"
                        :style="{
                            background: 'url(' + el.image[0].url + ') center no-repeat',
                            backgroundSize: 'cover',
                        }"
                        class="box pointer"
                        v-for="(el, idx) in classList"
                        :key="idx"
                        v-show="houseType == 0">
                        <div class="option" :class="el.state ? 'action' : ''">
                            <div class="binding">
                                <img v-if="!el.is_permit" src="@/assets/static/lock.png" />
                                <span v-else style="opacity: 0">空白</span>
                                <span>{{ el.name }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 尺寸选择 模块-->
        <div class="fix" v-if="fixedType">
            <!-- <div v-if="!scrawlShow"> -->
            <div class="title">
                <div class="l">选择尺寸</div>
                <div @click="fixedType = false" class="r pointer">
                    <i class="el-icon-close"></i>
                </div>
            </div>
            <div class="box">
                <div class="l">
                    <div class="imagewrap">
                        <img ref="image" :src="avatar" />
                    </div>
                </div>
                <div class="r">
                    <div class="name">尺寸</div>
                    <div @click="verticalHandel()" :class="frameType == 0 ? 'patternActive' : ''" class="pattern">
                        <img src="@/assets/static/11.png" />
                        <span> 1:1</span>
                    </div>
                    <div @click="nineHandel()" :class="frameType == 2 ? 'patternActive' : ''" class="pattern">
                        <img src="@/assets/static/916.png" />
                        <span>9:16</span>
                    </div>
                    <div @click="sixteenHandel()" :class="frameType == 1 ? 'patternActive' : ''" class="pattern">
                        <img src="@/assets/static/169.png" />
                        <span>16:9</span>
                    </div>

                    <div v-if="cjUploading" class="patternBtn" style="color: #ccc; cursor: auto">确定...</div>
                    <div v-else class="patternBtn" @click="sureSava">确定</div>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
</template>
<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import loading from '@/components/loading'

export default {
    name: 'Creative',
    components: {
        loading,
    },
    mounted() {
        const user = this.$store.state.user
        if (user && user.permit && user.permit.length == 0) {
            if (user.free_use == 0) {
                this.$message({
                    message: '免费次数已使用完',
                    type: 'error',
                })
                this.$store.commit('headerTypeHandel', 4)
                setTimeout(() => {
                    this.$router.push('/merge')
                }, 1500)
            }
        }
        this.$axios.post('design/category/page').then((res) => {
            this.classList = res.data
        })
        const oldObj = JSON.parse(localStorage.getItem('old_data'))
        // const isAgain = this.$route.query.again
        if (oldObj && Object.keys(oldObj).length !== 0 && this.$route.query.again) {
            // this.needData.image.push(oldObj.image)
            oldObj.image2 ? this.needData.image2.push(oldObj.image2) : (this.needData.image2 = [])
            oldObj.image ? this.needData.image.push(oldObj.image) : (this.needData.image = [])

            this.needData.differ = oldObj.differ
            // this.needData.invert = oldObj.invert
            // this.needData.category_id = oldObj.category_id
            this.needData.size = oldObj.size
            this.needData.num = oldObj.num
            this.needData.prompt = oldObj.prompt
            this.needData.negative_prompt = oldObj.negative_prompt
            // console.log('旧数据', this.needData)

            // this.$axios.post('design/category/page').then((res) => {
            //     console.log('classList', res.data)
            //     const result = res.data.filter((item) => oldObj.category_id.includes(item.id))
            //     console.log('data', result)
            //     this.HouseHandel(result)
            //     // res.data.map(item => {
            //     //     if(item.id == )
            //     // })
            // })
        } else {
            this.needData = {
                image: [],
                image2: [],
                prompt: '',
                negative_prompt: '',
                differ: '',
                size: '',
                num: '1',
                category_id: [],
            }
        }
    },
    watch: {
        needData: {
            handler() {},
            deep: true,
        },
    },
    data() {
        return {
            isBack: false,
            // 子级返回标题
            chidName: '',
            // 二级返回标题
            chidName2: '',
            houseType: 0,
            housechid1: [],
            housechid2: [],
            fixedType: false,
            classList: [],
            avatar: '',
            frameType: 0,
            afterImg: '1',
            firstIdx: '', //一级下标
            needData: {
                image: [],
                image2: [],
                prompt: '',
                negative_prompt: '',
                differ: '',
                size: '',
                num: '1',
                category_id: [],
            },
            scrawlShow: false,
            selectedClassItems: [],
            cjUploading: false,
            cropperWidthAndHeight: {
                width: '1024',
                height: '1024',
            },
        }
    },
    methods: {
        // 发送数据
        sendData() {
            this.$store.commit('saveAIdata', this.needData)
        },

        // 选择分类
        HouseHandel(data, idx) {
            if (!data.is_permit) return this.$message.error('没有权限')
            let count = 0
            // let selectedItems = []
            const index = this.selectedClassItems.indexOf(data)
            // for (let i = 0; i < this.classList.length; i++) {
            //     if (this.classList[i].state === true) {
            //         count++
            //         selectedItems.push(this.classList[i])
            //         // console.log(count)
            //     }
            // }
            if (index > -1) {
                // 点击已选中的元素时，取消选择
                this.selectedClassItems.splice(index, 1)
                data.state = false
            } else {
                if (this.selectedClassItems.length < 2) {
                    // 最多选择两个元素
                    data.state = true
                    this.selectedClassItems.push(data)
                } else {
                    // 已选择两个元素，无法再选择更多
                    this.$message.error('最多选择两个分类')
                }
            }
            // if (count >= 2) {
            //     return this.$message.error('最多选择两个分类')
            // }
            // data.state = !data.state
            this.needData.category_id = this.classList.filter((it) => it.state).map((it) => it.id)
            // console.log('this.needData', this.needData)
            this.$store.commit('saveAIdata', this.needData)
            this.$forceUpdate()
        },
        // 上传图片
        sampleFileUpload() {
            let file = this.$refs.sampleInput.files[0]
            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')
            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    // console.log('发送上传请求', res)
                    // this.SettleData.img = res.data.url
                    // this.needData.image.push(res.data.url)
                    this.avatar = res.data.url
                    // 处理上传成功后的响应
                    this.fixedType = true
                    this.$store.commit('updateOnlyImgStatus', true)
                    this.$nextTick(() => {
                        this.init()
                    })
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        },
        delsample(index) {
            this.$store.commit('updateOnlyImgStatus', true)
            this.needData.image.splice(index, 1)
        },

        // 上传参照图
        referFileUpload() {
            let file = this.$refs.referInput.files[0]
            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')

            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    // this.SettleData.img = res.data.url
                    // this.needData.image2.push(res.data.url)
                    this.avatar = res.data.url
                    // console.log(this.needData.image2)
                    this.fixedType = true
                    this.$store.commit('updateOnlyImgStatus', false)
                    // 处理上传成功后的响应
                    this.$nextTick(() => {
                        this.init()
                    })
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        },
        delrefer(index) {
            this.$store.commit('updateOnlyImgStatus', true)
            this.needData.image2.splice(index, 1)
        },

        verticalHandel() {
            this.$emit('frameHandel', 0)
            this.frameType = 0
            this.myCropper.setAspectRatio(1)
            this.cropperWidthAndHeight.width = '1024'
            this.cropperWidthAndHeight.height = '1024'
        },
        sixteenHandel() {
            this.$emit('frameHandel', 1)
            this.frameType = 1
            this.myCropper.setAspectRatio(1.75)
            this.cropperWidthAndHeight.width = '1344'
            this.cropperWidthAndHeight.height = '768'
        },
        nineHandel() {
            this.$emit('frameHandel', 2)
            this.frameType = 2
            this.myCropper.setAspectRatio(0.5714285714285714)
            this.cropperWidthAndHeight.width = '768'
            this.cropperWidthAndHeight.height = '1344'
        },
        sureSava() {
            this.cjUploading = true
            this.fixedType = false
            if (this.myCropper) {
                const code64 = this.myCropper
                    .getCroppedCanvas({
                        // imageSmoothingQuality: "high",
                        width: this.cropperWidthAndHeight.width,
                        height: this.cropperWidthAndHeight.height,
                    })
                    .toDataURL('image/png')
                this.$axios.post('upload/upload64', { code64 }).then((res) => {
                    // this.afterImg = res.data.url
                    // this.$emit('imageHandel', this.afterImg)
                    // this.scrawlShow = true
                    // this.$store.commit('saveScrawl', res.data.url)
                    // this.scrawImg()
                    if (this.$store.state.onlyUplImg) {
                        this.needData.image.push(res.data.url)
                        this.$store.commit('updateOnlyImgStatus', false)
                    } else {
                        this.needData.image2.push(res.data.url)
                        this.$store.commit('updateOnlyImgStatus', false)
                    }
                    this.$store.commit('delChangeWorkbenchImage')
                    this.cjUploading = false
                    this.$forceUpdate()
                    // this.$router.push('/ceshi')
                })
            }
        },

        init() {
            this.myCropper = new Cropper(this.$refs.image, {
                viewMode: 2,
                // aspectRatio: 1024 / 768,
                // cropBoxMovable: false,
                dragMode: 'move',
                initialAspectRatio: 2, //裁剪形状
                aspectRatio: 0,
                // preview: '.before',
                background: false,
                autoCropArea: 1,
                zoomOnWheel: true,
                // checkCrossOrigin: true,
                wheelZoomRatio: 0.1, //通过鼠标缩放图片
                crop: function (event) {},
            })

            this.verticalHandel()
        },
        // 新手引导
        noviceGuide() {
            this.$axios.post('design/guide/guide', { type: 4 }).then((res) => {
                // this.guideList = res.data.image
                this.$emit('noviceGuide', res.data.image)
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.wrap {
    padding: 0 100px;

    .header {
        padding: 0 120px;
        display: flex;
        padding-top: 20px;
        flex-direction: column;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .new {
                padding: 8px 10px;
                border-radius: 37.54px;
                border: 1.22px solid rgba(255, 255, 255, 0.4);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;

                .new-text {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22.4px;
                    color: rgba(255, 255, 255, 0.7);
                    margin-left: 5px;
                    cursor: pointer;
                }
            }
        }
    }

    .fixed {
        .title {
            height: 54px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .box {
            border-top: 1px solid #222222;
            display: flex;

            .r {
                .patternBtn {
                    position: absolute;
                    bottom: 60px;
                    width: 168px;
                    line-height: 48px;
                    text-align: center;
                    cursor: pointer;
                    height: 48px;
                    left: 42px;
                    background: #ff4600;
                    border-radius: 6px 6px 6px 6px;
                }

                .patternActive {
                    background: #ff4600;
                }

                .pattern {
                    img {
                        margin-right: 6px;
                    }

                    margin-top: 10px;
                    width: 210px;
                    height: 48px;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #666666;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    box-sizing: border-box;
                }

                position: relative;
                // margin-top: 20px;
                padding-left: 20px;
                width: 250px;
                height: 544px;
                background: #333333;
                box-sizing: border-box;
            }

            .l {
                flex: 1;
                background: #222222;
                height: 544px;
                width: 650px;
                overflow: hidden;
                padding: 20px 0;
                box-sizing: border-box;

                .imagewrap {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: contain; /* 图片自适应，保持比例不变，同时在子元素内部居中显示 */
                    img {
                        max-width: 100%; /* 图片宽度不超过父元素宽度 */
                        height: auto; /* 图片高度根据宽高比自动计算 */
                        max-height: 100%;
                    }
                }
            }
        }

        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        border-radius: 20px;
        top: 50%;
        width: 900px;
        height: 598px;
        background: #333333;
        border-radius: 4px 4px 0px 0px;
    }

    .fiexdwrap {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
    }

    .right {
        height: 566px;
        display: flex;
        flex-direction: column;
        width: 900px;
        overflow-y: auto;

        scrollbar-width: none; /* Firefox 64以上支持 */
        -ms-overflow-style: none; /* IE和Edge */
        &::-webkit-scrollbar {
            /* 对于WebKit内核的浏览器 (Chrome, Safari) */
            display: none;
        }

        .box {
            margin: 0 10px 20px;
            width: 269px;
            height: 135px;
            position: relative;

            .option {
                .binding {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 130px;
                    width: 130px;
                    justify-content: center;
                }

                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                font-size: 20px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                overflow: hidden;

                &.action {
                    border: 1px solid rgb(255, 70, 0);
                    border-radius: 10px;
                }

                img {
                    width: 28px;
                    height: 28px;
                }
            }

            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .left {
        .Creative {
            display: flex;
            flex-direction: column;

            .CreativeUp {
                .inputwrap {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    color: white;
                }

                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-bottom: 20px;
                width: 505px;
                height: 273px;
                border-radius: 20px 20px 20px 20px;
                // opacity: 0.4;
                border: 2px dashed #666;
            }
        }

        //.btn {
        //    text-align: center;
        //    line-height: 48px;
        //    cursor: pointer;
        //    width: 280px;
        //    height: 48px;
        //    background: #ff4600;
        //    border-radius: 10px 10px 10px 10px;
        //}

        //添加图片、线稿、3D
        .btn-box {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .btn {
                width: 274px;
                height: 55px;
                border-radius: 10px;
                background: rgba(40, 40, 40, 1);
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                color: rgba(255, 255, 255, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 65px;

                &:last-child {
                    margin-top: 32px;
                }

                &:hover {
                    background: rgba(255, 70, 0, 1);
                }

                &:first-child {
                    margin-top: 0px;
                }
            }

            .tip {
                width: 274px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 19.6px;
                color: rgba(255, 255, 255, 0.5);
                margin-top: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .afterImg {
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: contain; /* 图片自适应，保持比例不变，同时在子元素内部居中显示 */
            width: 100%;
            height: 566px;
            border: 1px dashed white;
            border-radius: 10px;

            img {
                max-width: 100%; /* 图片宽度不超过父元素宽度 */
                height: auto; /* 图片高度根据宽高比自动计算 */
                max-height: 100%;
            }
        }

        //AI文本描述
        .AI-box {
            display: flex;
            flex-direction: column;
            position: relative;
            height: 100%;
            //AI标题-
            .AI-title {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                color: rgba(255, 255, 255, 1);
                position: absolute;
                top: -40px;
            }

            //想要的效果文本
            .want-text {
                //margin-top: 16px;

                textarea {
                    width: 474px;
                    height: 110px;
                    padding: 20px;
                    border-radius: 20px;
                    outline: none;
                    flex: 1;
                    color: white;
                    background: rgba(255, 255, 255, 0.2);
                    resize: none;
                    border: transparent;
                }
            }

            //想要删除的标题
            .del-title {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                color: rgba(255, 255, 255, 1);
                margin-top: 18px;
            }

            .del-text {
                margin-top: 18px;

                textarea {
                    width: 474px;
                    height: 84px;
                    padding: 20px;
                    border-radius: 12px;
                    outline: none;
                    color: white;
                    background: rgba(255, 255, 255, 0.2);
                    resize: none;
                    border: transparent;
                }
            }

            //上传图片和参考图
            .img-box {
                display: flex;
                flex-direction: row;
                margin-top: 18px;
                //上传图片
                .img-list {
                    display: flex;

                    .img-item {
                        width: 200px;
                        height: 200px;
                        border-radius: 24px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 22px;
                        position: relative;
                        // background: rgb(65 65 65);
                        background: rgba(0, 0, 0, 1);
                        object-fit: contain; /* 图片自适应，保持比例不变，同时在子元素内部居中显示 */
                        .add-img {
                            width: 50px;
                            height: 50px;
                        }
                        .img-data {
                            // width: 100%;
                            // height: 100%;
                            max-width: 100%; /* 图片宽度不超过父元素宽度 */
                            height: auto; /* 图片高度根据宽高比自动计算 */
                            max-height: 100%;
                            border-radius: 24px;
                        }

                        input {
                            position: absolute;

                            width: 100%;
                            height: 100%;
                            opacity: 0;
                        }
                        .text-description {
                            position: absolute;
                            bottom: 25px;
                        }
                        .del-icon {
                            width: 20px;
                            height: 20px;
                            position: absolute;
                            right: 10px;
                            top: 10px;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
            }
        }

        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        margin-right: 50px;
    }

    .wrapflex {
        height: 570px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    .fix {
        .title {
            height: 54px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .box {
            border-top: 1px solid #222222;
            display: flex;

            .ri {
                width: 315px;
                position: relative;
                // margin-top: 20px;
                padding: 0 15px;
                //width: 250px;
                //flex: 0;
                height: 744px;
                background: #333333;
                box-sizing: border-box;

                //确定
                .suerBtn {
                    position: absolute;
                    width: 168px;
                    text-align: center;
                    cursor: pointer;
                    height: 48px;
                    background: #ff4600;
                    border-radius: 6px 6px 6px 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 20px;
                    left: 65px;
                }
            }

            .r {
                .patternBtn {
                    position: absolute;
                    bottom: 60px;
                    width: 168px;
                    line-height: 48px;
                    text-align: center;
                    cursor: pointer;
                    height: 48px;
                    left: 42px;
                    background: #ff4600;
                    border-radius: 6px 6px 6px 6px;
                }

                .patternActive {
                    background: #ff4600;
                }

                .pattern {
                    img {
                        margin-right: 6px;
                    }

                    margin-top: 10px;
                    width: 210px;
                    height: 48px;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #666666;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    box-sizing: border-box;
                }

                position: relative;
                // margin-top: 20px;
                padding-left: 20px;
                width: 250px;
                height: 744px;
                background: #333333;
                box-sizing: border-box;
            }

            .le {
                //width: 1100px;
                //height: 700px;
                flex: 1;
            }

            .l {
                flex: 1;
                background: #222222;
                height: 744px;
                width: 650px;
                overflow: hidden;
                padding: 20px 0;
                box-sizing: border-box;

                .imagewrap {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: contain; /* 图片自适应，保持比例不变，同时在子元素内部居中显示 */
                    img {
                        max-width: 100%; /* 图片宽度不超过父元素宽度 */
                        height: auto; /* 图片高度根据宽高比自动计算 */
                        max-height: 100%;
                    }
                }
            }
        }

        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        border-radius: 20px;
        top: 50%;
        width: 1500px;
        height: 798px;
        background: #333333;
        border-radius: 4px 4px 0px 0px;
    }
    // margin: 30px 0 50px;
    // display: flex;
    // justify-content: center;
    // height: 500px;
}
</style>
