<template>
    <div class="operating">
        <workPattern v-if="showPattern" @handleModel="modeSelet"></workPattern>

        <div class="operating-main" v-else>
            <div class="pattern-back" v-if="showPatternBack" @click="backPattern">
                <i class="el-icon-arrow-left"></i>返回
            </div>
            <div class="mode" @click.stop="modeChange">模式选择</div>
            <!--模式选择弹窗-->
            <div class="mode-type" v-if="modeShow">
                <div class="mode-title">模式选择</div>
                <div class="mode-box">
                    <div
                        class="mode-item"
                        :class="item.state ? 'aciton' : ''"
                        v-for="(item, index) in modeList"
                        :key="index"
                        @click.stop="modeSelet(item)">
                        {{ item.name }}
                    </div>
                </div>
            </div>
            <div v-if="pagetype">
                <!-- 按钮模式 -->
                <div v-if="modeType === 1">
                    <Creative
                        @imageHandel="imageHandel"
                        @specsHandel="specsHandel"
                        @frameHandel="frameHandel"
                        @category="category"
                        @backHanel="backChange"
                        @noviceGuide="noviceGuide"></Creative>
                </div>

                <!--文本生成-->
                <div v-if="modeType === 2">
                    <AICread @frameHandel="frameHandel" @noviceGuide="noviceGuide"></AICread>
                </div>
                <!--局部修改-->
                <div v-if="modeType === 3">
                    <PartCread
                        @imageHandel="imageHandel"
                        @frameHandel="frameHandel"
                        @noviceGuide="noviceGuide"></PartCread>
                </div>
                <!--一键生成-->
                <div v-if="modeType === 4">
                    <OneCreative
                        @imageHandel="imageHandel"
                        @specsHandel="specsHandel"
                        @category="category"
                        @frameHandel="frameHandel"
                        @noviceGuide="noviceGuide"></OneCreative>
                </div>
                <!--照片美化-->
                <div v-if="modeType === 5">
                    <BeatCreative
                        @imageHandel="imageHandel"
                        @frameHandel="frameHandel"
                        @noviceGuide="noviceGuide"></BeatCreative>
                </div>
                <div class="base">
                    <!-- 左侧图片比例 -->
                    <div class="base-box">
                        <div class="top-box">
                            <div v-if="modeType === 2" class="top">
                                <div
                                    @click.stop="frameHandel(0)"
                                    :style="{ background: frameType == 0 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/11.png" />
                                    1:1
                                </div>
                                <div
                                    @click.stop="frameHandel(2)"
                                    :style="{ background: frameType == 2 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/916.png" />
                                    9:16
                                </div>
                                <div
                                    @click.stop="frameHandel(1)"
                                    :style="{ background: frameType == 1 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/169.png" />
                                    16:9
                                </div>
                            </div>
                            <div v-else class="top">
                                <div
                                    @click.stop="frameHandel(0)"
                                    :style="{ background: frameType == 0 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/43.png" />
                                    4:3
                                </div>
                                <div
                                    @click.stop="frameHandel(1)"
                                    :style="{ background: frameType == 1 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/34.png" />
                                    3:4
                                </div>
                                <div
                                    @click.stop="frameHandel(2)"
                                    :style="{ background: frameType == 2 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/11.png" />
                                    1:1
                                </div>
                                <div
                                    @click.stop="frameHandel(3)"
                                    :style="{ background: frameType == 3 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/916.png" />
                                    9:16
                                </div>
                                <div
                                    @click.stop="frameHandel(4)"
                                    :style="{ background: frameType == 4 ? '#ff4600' : '' }"
                                    class="fk pointer">
                                    <img style="margin: 3px 10px 0 0" src="@/assets/static/169.png" />
                                    16:9
                                </div>
                                <div class="part-box" v-if="modeType === 3">
                                    <input
                                        type="text"
                                        placeholder="输入涂抹的内容描述"
                                        v-model="smearTxt"
                                        class="part-input" />
                                </div>
                            </div>

                            <!-- <div class="inp" v-if="creativeobj.image || modeType === 2"> -->
                            <div class="inp" v-if="modeType !== 3 && modeType !== 4 && modeType !== 5">
                                <div class="inp-slider">
                                    <span>差异</span>
                                    <!-- <input
                                        type="range"
                                        min="0"
                                        max="20"
                                        v-model="creativeobj.differ"
                                        :disabled="modeType === 3 || (modeType === 2 && onlyUploadImg)" /> -->
                                    <el-slider
                                        :min="0"
                                        :step="1"
                                        :max="20"
                                        :show-tooltip="false"
                                        v-model="creativeobj.differ"
                                        :disabled="modeType === 2 && onlyUploadImg">
                                    </el-slider>
                                    <div class="fill-dot fill-dot1"></div>
                                    <div class="fill-dot fill-dot2"></div>
                                </div>
                                <span class="inpfk">{{ creativeobj.differ }}</span>
                            </div>
                        </div>
                        <!-- 左侧底部提示 -->
                        <div class="bottom-box">
                            <!--目前什么模式-->
                            <div class="mode-tips">
                                {{ modeText }}
                                <span>{{ modeTips }}</span>
                            </div>
                            <div class="btn-box">
                                <!-- <div class="part-box" v-if="modeType === 3">
                                    <span>增加</span>
                                    <input
                                        type="text"
                                        placeholder="输入涂抹的内容描述"
                                        v-model="smearTxt"
                                        class="part-input" />
                                </div> -->
                                <div v-if="this.modeType === 4" style="display: flex">
                                    <div @click.stop="generate('3')" class="create">毛坯照片</div>
                                    <div @click.stop="generate('5')" class="create" style="margin: 0 11px">
                                        模型草图
                                    </div>
                                    <div @click.stop="generate('6')" class="create">手绘草图</div>
                                </div>
                                <div v-else @click.stop="generate" class="create">生成</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 图片生成中 -->
            <div class="screenloading" v-else style="background: #111">
                <!-- <img src="@/assets/static/loading.gif" /> -->
                <loading></loading>
                <!--      <div class="text">点一下生成大师Ai设计</div>-->
                <div class="loading">
                    <el-progress
                        define-back-color="#343434"
                        :show-text="false"
                        color="white"
                        :percentage="percentages"></el-progress>
                </div>
                <div class="progress-txt">{{ randomLoadingText }}</div>
                <div class="cancel" @click.stop="dialogFlag = true">
                    <img src="../assets/static/cancel.png" />
                </div>
            </div>

            <el-dialog :visible.sync="dialogFlag" title="提示" width="30%" align-center>
                <span>创意正在生成中，确定取消吗？</span>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click.stop="dialogFlag = false">继续生成</el-button>
                        <el-button type="primary" @click.stop="cancel"> 确定 </el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
        <!-- 新手引导 -->
        <div v-if="showGuide" class="novice-guide">
            <img :src="guideList[currentGuideIndex].url" alt="" @click="renderNextImage" />
        </div>
    </div>
</template>
<script>
import Creative from '@/components/operating/Creative'
import loading from '@/components/loading'
import AICread from '@/components/operating/AICread.vue'
import PartCread from '@/components/operating/PartCread.vue'
import OneCreative from '@/components/operating/OneCreative.vue'
import BeatCreative from '@/components/operating/BeatCreative.vue'
import workPattern from '@/views/workPattern.vue'
export default {
    components: {
        Creative,
        loading,
        AICread,
        PartCread,
        OneCreative,
        BeatCreative,
        workPattern,
    },
    data() {
        return {
            // 模式选择打开
            modeShow: false,
            modeText: '按钮模式',
            modeTips: '选择按钮上传图片生成创意图',
            // 当前模式
            modeType: 1,
            // 模式数据
            modeList: [
                {
                    name: '文字模式',
                    state: false,
                    value: 2,
                    tips: '输入文字和图片生成创意图',
                },
                {
                    name: '按钮模式',
                    state: true,
                    value: 1,
                    tips: '选择按钮上传图片生成创意图',
                },

                {
                    name: '局部修改',
                    state: false,
                    value: 3,
                    tips: '局部涂抹输入文字生成创意图',
                },
                {
                    name: '一键生成',
                    state: false,
                    value: 4,
                    tips: '输入毛坯照片生成装修效果图',
                },
                {
                    name: '照片美化',
                    state: false,
                    value: 5,
                    tips: '输入人物照片进行照片优化',
                },
            ],
            percentages: 0,
            pagetype: true,
            frameType: 0,
            dialogFlag: false,
            creativeobj: {
                image: '', //图片url
                size: '1024X768', //图片尺寸
                num: 1, //张数
                differ: 0, //差异
                category_id: '', //分类id
                specs: '', //分类规格/数组
                invert: 0,
                v: 2,
            },
            aiShow: false,
            btnList: [
                {
                    name: '接近原图',
                    state: false,
                    value: 0,
                },
                {
                    name: '风格切换',
                    state: false,
                    value: 1,
                },
                {
                    name: 'AI决定',
                    state: false,
                    value: 3,
                },
            ],
            AiBtn: false,
            smearTxt: '', // 涂抹描述文字
            showPatternBack: false,
            showPattern: true,
            onlyUploadImg: true,
            guideList: [],
            showGuide: false,
            currentGuideIndex: 0,
        }
    },
    watch: {
        '$store.state.showPatternBack': {
            handler(newValue) {
                this.showPatternBack = newValue
            },
            immediate: true, // 立即执行回调函数
        },
        '$store.state.onlyUplImg': {
            handler(newValue) {
                this.onlyUploadImg = newValue
            },
            immediate: true, // 立即执行回调函数 userInfo
        },
    },
    computed: {
        randomLoadingText() {
            const user = this.$store.state.user
            if (user && user.loading_text) {
                const randomIndex = Math.floor(Math.random() * user.loading_text.length)
                return user.loading_text[randomIndex]
            } else {
                return '加载中...'
            }
        },
    },
    created() {
        if (this.$route.query.again) {
            this.pagetype = false
            this.creativeobj = JSON.parse(localStorage.getItem('old_data'))
            this.modeType = this.creativeobj.modeType
            this.generate()
        } else if (this.$route.query.backType) {
            // this.delRoutePath()
            const query = this.$route.query.backType
            const currentObj = this.modeList.find((item) => item.value == Number(query))
            this.modeSelet(currentObj)
        } else if (this.$route.query.cyBackType) {
            const query = this.$route.query.cyBackType
            const currentObj = this.modeList.find((item) => item.value == Number(query))
            console.log('currentObj', currentObj)
            this.modeSelet(currentObj)
            // localStorage.removeItem('selectedModelType')
            // this.$store.commit('delChangeWorkbenchImage')
        }
    },

    methods: {
        // 模式选择打开
        modeChange() {
            this.modeShow = !this.modeShow
            this.$forceUpdate()
        },
        // 模式选择
        modeSelet(item) {
            // console.log('我选择的模式', item)
            this.modeList.map((item) => {
                item.state = false
            })

            item.state = true
            this.modeText = item.name
            this.modeTips = item.tips
            this.modeType = item.value
            console.log('this.type', this.modeType)
            if (this.modeType == 2) {
                this.creativeobj.size = '1024X1024'
            }
            this.modeShow = false
            this.showPattern = false
            localStorage.setItem('selectedModelType', item.value)
            this.$forceUpdate()
        },
        backChange(e) {
            if (e) {
                this.creativeobj.image = ''
                this.aiShow = false
            }
        },
        imageHandel(pic) {
            this.creativeobj.image = pic
        },
        specsHandel(arr) {
            this.creativeobj.specs = arr.map((el) => {
                return el.id
            })
            // console.log('传过来的的specs', arr)
        },
        category(el) {
            this.creativeobj.category_id = el.id
            // console.log('传过来的categoryid', el.id)
        },
        cancel() {
            this.$axios.post('design/creative/cancelCreate').then((res) => {
                this.dialogFlag = false
                this.pagetype = true
                // this.$router.push({ path: '/' })
                // console.log(res)
                this.creativeobj = {
                    image: '', //图片url
                    size: '1024X768', //图片尺寸
                    num: 1, //张数
                    differ: 0, //差异
                    category_id: '', //分类id
                    specs: '', //分类规格/数组
                    invert: 0,
                    v: 2,
                }
            })
        },
        // 生成
        generate(invert = null) {
            if (this.modeType === 2) {
                // console.log('文字模式', this.creativeobj)

                let list = this.$store.state.AIdata
                if (this.$store.state.AIdata == null) return this.$message.error('请填写完整信息内容')
                list.size = this.creativeobj.size
                list.invert = this.creativeobj.invert
                list.differ = this.creativeobj.differ
                if (!list.prompt) return this.$message.error('请描述你想要的效果')
                if (!list.category_id || !list.category_id.length > 0) return this.$message.error('请选择分类')
                if (list.differ <= 0 && list.image.length > 0 && list.image2.length > 0)
                    return this.$message.error('差异值不能为零')
                // console.log('AI关键词生成', list)
                this.pagetype = false
                const r = setInterval(() => {
                    this.percentages += 2
                    if (this.percentages > 80) {
                        clearInterval(r)
                    }
                }, 200)
                // console.log('list', list)
                let newList = { ...list }
                // if (newList.image.length > 0 || newList.image2.length > 0) {
                //     newList.image = newList.image[0] ? newList.image[0] : ''
                //     newList.image2 = newList.image2[0] ? newList.image2[0] : ''
                // }
                if (newList.image.length > 0 && newList.image2.length === 0) {
                    newList.image = newList.image[0]
                    newList.image2 = ''
                } else if (newList.image2.length > 0 && newList.image.length === 0) {
                    newList.image = newList.image2[0]
                    newList.image2 = ''
                } else if (newList.image.length > 0 && newList.image2.length > 0) {
                    newList.image = newList.image[0]
                    newList.image2 = newList.image2[0]
                } else if (newList.image2.length === 0 && newList.image.length === 0) {
                    const { image, image2, ...obj } = list
                    // newList = {}
                    newList = obj
                }

                // console.log("newList", newList);
                // list = {}
                localStorage.setItem('old_data', JSON.stringify(newList))
                this.$axios
                    .post('design/creative/textV2', newList)
                    .then((res) => {
                        clearInterval(r)
                        if (res.code == 500) {
                            this.$message.error('图片生成失败！请稍后再试！')
                            this.pagetype = true
                            this.$store.commit('delAI')
                            this.$store.commit('delAIdata')
                            return
                        }
                        this.percentages = 100
                        this.aiShow = false
                        setTimeout(() => {
                            setTimeout(() => {
                                this.percentages = 0
                            }, 500)
                            // this.pagetype = true

                            this.$router.push({
                                name: 'designsucess',
                                params: { ...res.data, modeType: this.modeType },
                            })
                            this.$store.commit('delAI')
                            this.$store.commit('delAIdata')
                        }, 500)
                    })
                    .catch((res) => {
                        this.$message.error('图片生成失败！请稍后再试！')
                        this.pagetype = true
                    })
                this.$store.commit('delAI')
                this.$store.commit('delAIdata')
            } else if (this.modeType === 3) {
                // console.log('局部修改', this.creativeobj)
                if (!this.smearTxt) return this.$message.error('涂抹描述文字不能为空')

                this.creativeobj.invert = this.$store.state.type == 3 ? 3 : this.creativeobj.invert
                this.pagetype = false
                const r = setInterval(() => {
                    this.percentages += 2
                    if (this.percentages > 80) {
                        clearInterval(r)
                    }
                }, 200)
                const image = localStorage.getItem('partUrl')
                // if (image === null) {
                //     this.$message.error('请重新选择图片')
                //     return
                // }
                this.creativeobj.image2 = this.creativeobj.image
                this.creativeobj.image = image
                this.creativeobj.prompt = this.smearTxt
                // const data = {
                //     image: image,
                //     image2: this.creativeobj.image,
                //     prompt: this.smearTxt,
                //     size: this.creativeobj.size,
                // }
                localStorage.setItem('old_data', JSON.stringify(this.creativeobj))
                this.$axios
                    .post('design/creative/inpaintV2', this.creativeobj)
                    .then((res) => {
                        clearInterval(r)
                        if (res.code == 500) {
                            this.$message.error('图片生成失败！请稍后再试！')
                            this.pagetype = true
                            this.$store.commit('delPartdata')
                            return
                        }
                        this.percentages = 100
                        // this.aiShow = false
                        setTimeout(() => {
                            setTimeout(() => {
                                this.percentages = 0
                            }, 500)
                            // this.pagetype = true
                            this.$router.push({
                                name: 'designsucess',
                                params: { ...res.data, modeType: this.modeType },
                            })
                            // this.$store.commit('delAI')
                            this.$store.commit('delPartdata')
                        }, 500)
                        this.smearTxt = ''
                        this.$store.commit('delDrawingImage')
                    })
                    .catch((res) => {
                        console.log('我是局部修改error', res)
                        this.$message.error('图片生成失败！请稍后再试！')
                        this.pagetype = true
                        this.$store.commit('delDrawingImage')
                        console.log('llll', this.$store.state.drawingImage)
                    })
                this.creativeobj = {
                    image: '', //图片url
                    size: '1024X768', //图片尺寸
                    num: 1, //张数
                    differ: 0, //差异
                    category_id: '', //分类id
                    specs: '', //分类规格/数组
                    invert: 0,
                    v: 2,
                }
                // .finally(() => {
                //     if (image) localStorage.setItem('partUrl', null)
                // })
            } else if (this.modeType === 4) {
                // console.log('一键生成', this.creativeobj)
                if (this.creativeobj.image == '') return this.$message.error('请先选择图片')
                if (this.creativeobj.category_id == '') return this.$message.error('请选择分类')
                if (this.creativeobj.specs[0] == '') return this.$message.error('分类规格不能为空')
                // if (this.creativeobj.image) {
                //   if (this.creativeobj.differ <= 0)
                //     return this.$message.error("差异值不能为零");
                // }
                // this.creativeobj.image2 = this.creativeobj.image
                this.creativeobj.image = this.$store.state.scrawlImg
                this.creativeobj.invert = invert
                this.pagetype = false
                const r = setInterval(() => {
                    this.percentages += 2
                    if (this.percentages > 80) {
                        clearInterval(r)
                    }
                }, 200)
                localStorage.setItem('old_data', JSON.stringify(this.creativeobj))
                // console.log("一键生成 ++++++=this.creativeobj", this.creativeobj);
                this.$axios
                    .post('design/creative/createV2', this.creativeobj)
                    .then((res) => {
                        clearInterval(r)
                        if (res.code == 500) {
                            this.$message.error('图片生成失败！请稍后再试！')
                            this.pagetype = true
                            this.creativeobj.image = ''
                            this.$store.commit('delOnedata')
                            return
                        }
                        this.percentages = 100
                        setTimeout(() => {
                            setTimeout(() => {
                                this.percentages = 0
                            }, 500)
                            // this.pagetype = true
                            // 跳转到生成的弹层页面
                            this.$router.push({
                                name: 'designsucess',
                                params: { ...res.data, modeType: this.modeType },
                            })
                            this.$store.commit('delOnedata')
                            this.$store.commit('deltype')
                        }, 500)
                    })
                    .catch((res) => {
                        this.$message.error('图片生成失败！请稍后再试！')
                        this.pagetype = true
                    })
            } else if (this.modeType === 5) {
                // console.log('照片美化', this.creativeobj)

                if (this.creativeobj.image == '') return this.$message.error('请选择图片')
                // if (this.creativeobj.image) {
                //   if (this.creativeobj.differ <= 0)
                //     return this.$message.error("差异值不能为零");
                // }
                this.creativeobj.image2 = this.creativeobj.image
                this.creativeobj.image = this.$store.state.scrawlImg
                this.creativeobj.invert = '2'
                // this.creativeobj.specs = this.creativeobj.specs || []
                delete this.creativeobj.specs
                if (this.creativeobj.image || this.creativeobj.image2) {
                    this.creativeobj.image = this.creativeobj.image || ''
                    this.creativeobj.image2 = this.creativeobj.image2 || ''
                }
                this.pagetype = false
                const r = setInterval(() => {
                    this.percentages += 2
                    if (this.percentages > 80) {
                        clearInterval(r)
                    }
                }, 200)
                localStorage.setItem('old_data', JSON.stringify(this.creativeobj))
                this.$axios
                    .post('design/creative/createV2', this.creativeobj)
                    .then((res) => {
                        clearInterval(r)
                        if (res.code == 500) {
                            this.$message.error('图片生成失败！请稍后再试！')
                            this.pagetype = true
                            this.creativeobj.image = ''
                            return
                        }
                        this.percentages = 100
                        // console.log('成功数据', res)
                        setTimeout(() => {
                            setTimeout(() => {
                                this.percentages = 0
                            }, 500)
                            // this.pagetype = true
                            // 跳转到生成的弹层页面
                            this.$router.push({
                                name: 'designsucess',
                                params: { ...res.data, modeType: this.modeType },
                            })
                            this.$store.commit('deltype')
                        }, 500)
                    })
                    .catch((res) => {
                        this.$message.error('图片生成失败！请稍后再试！')
                        this.pagetype = true
                    })
                // .finally(() => {
                //     this.$store.commit('delScrawl')
                // })
            } else {
                // console.log('按钮模式', this.creativeobj)
                if (!this.creativeobj.image) return this.$message.error('请选择图片')
                if (this.creativeobj.category_id == '') return this.$message.error('请选择分类')
                if (this.creativeobj.specs == '' || this.creativeobj.specs[0] == '')
                    return this.$message.error('分类规格不能为空')
                if (this.creativeobj.image) {
                    if (this.creativeobj.differ <= 0) return this.$message.error('差异值不能为零')
                }

                this.creativeobj.invert = this.$store.state.type == 2 ? '2' : this.$store.state.type == 3 ? '5' : '1'
                this.pagetype = false
                const r = setInterval(() => {
                    this.percentages += 2
                    if (this.percentages > 80) {
                        clearInterval(r)
                    }
                }, 200)
                // console.log('按钮模式', this.creativeobj)
                localStorage.setItem('old_data', JSON.stringify(this.creativeobj))
                this.$axios
                    .post('design/creative/createV2', this.creativeobj)
                    .then((res) => {
                        clearInterval(r)
                        if (res.code == 500) {
                            this.$message.error('图片生成失败！请稍后再试！')
                            this.pagetype = true
                            this.creativeobj.image = ''
                            return
                        }
                        this.percentages = 100
                        setTimeout(() => {
                            setTimeout(() => {
                                this.percentages = 0
                            }, 500)
                            // this.pagetype = true
                            this.$store.commit('deltype')
                            // 跳转到生成的弹层页面
                            this.$router.push({
                                name: 'designsucess',
                                params: { ...res.data, modeType: this.modeType },
                            })
                        }, 500)
                    })
                    .catch((res) => {
                        this.$message.error('图片生成失败！请稍后再试！')
                        this.pagetype = true
                    })
                this.creativeobj = {
                    image: '', //图片url
                    size: '1024X768', //图片尺寸
                    num: 1, //张数
                    differ: 0, //差异
                    category_id: '', //分类id
                    specs: '', //分类规格/数组
                    invert: 0,
                    v: 2,
                }
            }
        },
        frameHandel(i) {
            console.log('lll', this.creativeobj.image)
            if (this.creativeobj.image) return
            // console.log("this.$store.state.onlyUplImg", this.$store.state.onlyUplImg);
            // if (this.modeType === 2 && !this.$store.state.onlyUplImg) return;
            this.frameType = i

            // console.log('frameHandel--this.modeType', this.modeType)
            if (this.modeType === 2) {
                // this.frameType = i
                this.creativeobj.size =
                    this.frameType == 0 ? '1024X1024' : this.frameType == 1 ? '1344X768' : '768X1344'
                // console.log('this.modeType ===2 size', this.creativeobj.size)
            } else {
                this.creativeobj.size =
                    this.frameType == 0
                        ? '1024X768'
                        : this.frameType == 1
                        ? '768X1024'
                        : this.frameType == 2
                        ? '960X960'
                        : this.frameType == 3
                        ? '768X1365'
                        : '1365X768'
            }
            this.$forceUpdate()
        },

        delRoutePath() {
            // 获取当前路由信息
            let route = this.$route
            // 去掉 query 值
            route.query = {}
        },

        backPattern() {
            this.showPattern = true
            this.$store.commit('delDrawingImage')
            // this.showPatternBack = false
        },

        noviceGuide(value) {
            this.showGuide = true
            this.guideList = value
        },

        renderNextImage() {
            if (this.currentGuideIndex < this.guideList.length - 1) {
                this.currentGuideIndex++
            } else {
                // 渲染到最后一张时结束
                this.showGuide = false
                this.currentGuideIndex = 0
            }
        },
        // dotClick(value) {
        //     this.creativeobj.differ = value
        // },
    },
}
</script>
<style lang="scss" scoped>
::v-deep .el-switch__label {
    color: #fff;
}

::v-deep .el-switch__label.is-active {
    color: #ff4600;
}

.operating {
    color: white;
    //padding: 0 100px;
    min-height: calc(100vh - 60px);
    background: #111111;
    position: relative;

    .mode {
        width: 113px;
        height: 38px;
        border-radius: 10px 0px 0px 10px;
        background: rgba(40, 40, 40, 1);
        position: absolute;
        right: 0;
        top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0px;
        line-height: 22.4px;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
    }
    .pattern-back {
        text-align: center;
        line-height: 29px;
        color: white;
        cursor: pointer;
        font-size: 14px;
        width: 80px;
        height: 29px;
        position: absolute;
        left: 220px;
        top: 20px;
        background: rgba(51, 51, 51, 1);
        border-radius: 4px;
        z-index: 9;
        .el-icon-arrow-left {
            margin-right: 4px;
        }
    }

    //模式选择弹窗
    .mode-type {
        height: 100%;
        width: 341px;
        background: rgba(34, 34, 34, 1);
        position: absolute;
        z-index: 333;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        align-items: center;

        .mode-title {
            margin-top: 45px;
            font-size: 24px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 33.6px;
            color: rgba(255, 255, 255, 1);
        }

        .mode-box {
            margin-top: 31px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .mode-item {
                width: 341px;
                height: 68px;
                font-size: 24px;
                font-weight: 400;
                line-height: 34.75px;
                color: rgba(255, 255, 255, 1);
                border-bottom: 1px solid rgba(255, 255, 255, 0.09);
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &.aciton {
                    background: rgba(51, 51, 51, 1);
                }
            }
        }
    }

    .screenloading {
        .loading {
            width: 240px;
            padding: 0 0 20px;
            margin-top: -30px;
        }

        .cancel {
            margin-top: 50px;
            cursor: pointer;
        }

        height: calc(100vh - 64px);

        .text {
            // margin-top: 20px;
            margin-bottom: 10px;
            font-size: 24px;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: white;
        z-index: 9999;
        position: relative;
    }

    // .footer {
    //     margin-top: 80px;
    //     display: flex;
    //     align-items: center;
    //     justify-content: space-between;

    //     [type='range'] {
    //         -webkit-appearance: none;
    //         appearance: none;
    //         outline: 0;
    //         // margin-left: 50px;
    //         background-color: transparent;
    //         width: 160px;
    //     }

    //     [type='range']::-webkit-slider-runnable-track {
    //         height: 4px;
    //         background: #eee;
    //     }

    //     [type='range' i]::-webkit-slider-container {
    //         height: 20px;
    //         overflow: hidden;
    //     }

    //     [type='range']::-webkit-slider-thumb {
    //         -webkit-appearance: none;
    //         appearance: none;
    //         width: 20px;
    //         height: 20px;
    //         border-radius: 50%;
    //         background-color: $main-color;
    //         border: 1px solid transparent;
    //         margin-top: -8px;
    //         border-image: linear-gradient($main-color, $main-color) 0 fill / 8 20 8 0 / 0px 0px 0 2000px;
    //     }

    //     .right {
    //         .btn1 {
    //             width: 168px;
    //             height: 48px;
    //             border-radius: 10px 10px 10px 10px;
    //             opacity: 1;
    //             border: 1px solid #999999;
    //             text-align: center;
    //             line-height: 48px;
    //         }

    //         .btn2 {
    //             width: 168px;
    //             height: 48px;
    //             background: #ff4600;
    //             border-radius: 10px 10px 10px 10px;
    //             text-align: center;
    //             line-height: 48px;
    //             margin-left: 10px;
    //         }

    //         display: flex;
    //     }

    //     .inp {
    //         display: flex;
    //         align-items: center;
    //         margin: 0 30px 0 20px;

    //         // input {
    //         //     margin: 0 10px;
    //         // }
    //         .inp-slider {
    //             display: flex;
    //             align-items: center;

    //             ::v-deep .el-slider {
    //                 margin: 0 10px 0 18px;
    //                 width: 346px;
    //                 .el-slider__runway {
    //                     height: 6px;
    //                     margin: 0;
    //                     background: rgba(94, 94, 94, 1);
    //                     .el-slider__bar {
    //                         background-color: #ff4600;
    //                     }
    //                     .el-slider__button-wrapper {
    //                         z-index: 5;
    //                         .el-slider__button {
    //                             background: rgba(255, 70, 0, 1);
    //                             border: 2px solid rgba(255, 255, 255, 1);
    //                         }
    //                     }
    //                 }
    //             }
    //         }

    //         .inpfk {
    //             width: 52px;
    //             height: 24px;
    //             display: inline-block;
    //             background: #282828;
    //             text-align: center;
    //             line-height: 24px;
    //             border-radius: 4px 4px 4px 4px;
    //         }
    //     }

    //     .fk {
    //         display: flex;
    //         justify-content: center;
    //         align-items: center;
    //         width: 116px;
    //         height: 36px;

    //         margin-right: 10px;
    //         background: #282828;
    //         border-radius: 6px 6px 6px 6px;
    //     }

    //     .left {
    //         display: flex;
    //     }

    //     display: flex;
    // }

    .base {
        width: 100%;
        max-height: 180px;
        opacity: 1;
        border-radius: 48px 48px 0px 0px;
        background: rgba(31, 31, 31, 1);
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        .base-box {
            // padding: 30px 230px 0;
            padding: 30px 166px 30px 230px;
            display: flex;
            flex-direction: column;

            .top-box {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                .top {
                    // width: 644px;
                    display: flex;
                    // flex-direction: row;
                    align-items: center;
                    // justify-content: space-between;

                    .fk {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 116px;
                        height: 36px;

                        margin-right: 16px;
                        background: #282828;
                        border-radius: 6px 6px 6px 6px;
                    }
                    .part-box {
                        font-size: 24px;
                        font-weight: 700;
                        margin-left: 150px;
                        .part-input {
                            width: 700px;
                            height: 42px;
                            opacity: 1;
                            border-radius: 12px;
                            padding-left: 7px;
                            background: rgba(51, 51, 51, 1);
                            border: transparent;
                            color: rgba(255, 255, 255, 1);
                            &:focus {
                                outline: none;
                            }

                            &::placeholder {
                                font-size: 18px;
                                color: rgba(255, 255, 255, 1);
                            }
                        }
                    }
                }

                .inp {
                    display: flex;
                    align-items: center;
                    //margin: 0 30px 0 20px;

                    // input {
                    //     margin: 0 10px;
                    // }
                    .inp-slider {
                        display: flex;
                        align-items: center;
                        position: relative;
                        ::v-deep .el-slider {
                            margin: 0 10px 0 18px;
                            width: 346px;
                            .el-slider__runway {
                                height: 6px;
                                margin: 0;
                                background: rgba(94, 94, 94, 1);

                                .el-slider__bar {
                                    background-color: #ff4600;
                                }
                                .el-slider__button-wrapper {
                                    z-index: 5;
                                    .el-slider__button {
                                        height: 16px;
                                        width: 16px;
                                        background: rgba(255, 70, 0, 1);
                                        border: 2px solid rgba(255, 255, 255, 1);
                                    }
                                }
                            }
                        }

                        .fill-dot {
                            position: absolute;
                            top: 6px;
                            width: 6px;
                            height: 6px;
                            border-radius: 3px;
                            opacity: 0.2;
                            background: rgba(255, 255, 255, 1);
                        }

                        .fill-dot1 {
                            right: 25%;
                        }
                        .fill-dot2 {
                            right: 67%;
                        }
                    }

                    .inpfk {
                        width: 52px;
                        height: 24px;
                        display: inline-block;
                        background: #282828;
                        text-align: center;
                        line-height: 24px;
                        border-radius: 4px 4px 4px 4px;
                    }
                }
            }

            .bottom-box {
                margin-top: 36px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                //目前什么模式
                .mode-tips {
                    display: flex;
                    flex-direction: column;
                    font-size: 24px;
                    font-weight: 700;
                    color: rgba(255, 255, 255, 1);

                    span {
                        font-size: 12px;
                        font-weight: 700;
                        color: rgba(255, 255, 255, 1);
                    }
                }

                .btn-box {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;

                    .create {
                        width: 168px;
                        height: 48px;
                        opacity: 1;
                        border-radius: 10px;
                        background: rgba(255, 70, 0, 1);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        font-weight: 700;
                        letter-spacing: 0px;
                        line-height: 23.17px;
                        color: rgba(255, 255, 255, 1);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .novice-guide {
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        // .guide-img {
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        // }
    }
}
</style>
