<template>
  <div class="fixedr">
    <div class="auto">
      <img class="pic" :src="SettleData.avatar" />

      <div class="title">
        <span>更换头像</span>
        <input type="file" ref="fileInput" @change="handleFileUpload">

      </div>
      <!-- <button @click="submit">上传</button> -->

    </div>
    <div class="content">
      <div class="p1">
        <div class="name">姓名</div>
        <input type="text" v-model="SettleData.real_name" placeholder="输入姓名">
      </div>
      <!-- <div class="p1">
            <div class="name">性别</div>
            <div class="fk">
              <div class="l active">男</div>
              <div class="r">女</div>
            </div>
          </div> -->
      <div class="p1">
        <div class="name">手机号</div>
        <input type="text" v-model="SettleData.mobile" placeholder="输入手机号">
      </div>
      <div class="p1">
        <div class="name">公司</div>
        <input type="text" v-model="SettleData.company" placeholder="输入公司">
      </div>
      <div class="p1">
        <div class="name">入职类型</div>
        <select v-model="SettleData.type_id">
          <option v-for="el in getTypeList" :value="el.id" :key="el.id">{{el.name}}</option>

        </select>
      </div>
      <div class="p1">
        <div class="name">职业</div>
        <select v-model="SettleData.occ_id">
          <option v-for="el in getOccList" :value="el.id" :key="el.id">{{el.name}}</option>

        </select>
      </div>
      <div class="p1">
        <div class="name">简介</div>
        <textarea v-model="SettleData.introduction" placeholder="输入你想说的"></textarea>
      </div>
    </div>
    <div class="bottom">
      <div @click="settleHandel()" class="btn">免费入驻平台</div>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    lrtype: [Boolean, String, Number]
  },
  data() {
    return {
      SettleData: {
        avatar: '',
        real_name: '',
        mobile: '',
        company: '',
        type_id: '',
        occ_id: '',
        introduction: ''
      },
      //职业列表
      getOccList: [],
      // 入驻类型
      getTypeList: []
    }
  },
  mounted() {
    this.$axios.post('design/settled/getOccList').then(({ data }) => {
      this.getOccList = data
    })
    this.$axios.post('design/settled/getTypeList').then(({ data }) => {
      this.getTypeList = data
    })
  },
  methods: {
    // 右边图片上传
    handleFileUpload() {
      let file = this.$refs.fileInput.files[0]
      let formData = new FormData()
      formData.append('file', file)
      formData.append('path', 'image')

      // 发送上传请求
      this.$axios
        .post('upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => {
          this.SettleData.avatar = res.data.url
          // 处理上传成功后的响应
        })
        .catch((error) => {
          // 处理上传失败后的响应
        })
    },
    // 入驻
    settleHandel() {
      this.$axios.post('design/settled/create', this.SettleData).then((res) => {
        this.$message.success(res.msg)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.fixedr {
  position: absolute;
  top: 0;
  right: 0;
  color: white;

  height: 100%;
  width: 350px;
  background: #222222;
  .list {
    margin-top: 20px;
    padding: 0 20px;
    .p {
      // width: 30px;
      margin-top: 20px;
      white-space: normal; /* 处理空格和换行符 */
      word-wrap: break-word; /* 允许单词内部换行 */
    }
    .btn {
      display: flex;
      justify-content: flex-end;
      span {
        background: $main-color;
        padding: 5px 15px;
        border-radius: 5px;
      }
    }
  }
  .class {
    display: flex;
    justify-content: space-around;
    .item {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50px;
      }
      span {
        margin-top: 10px;
      }
    }
  }
  .bottom {
    margin-top: 120px;
    color: white;
    display: flex;
    justify-content: center;
    .btn {
      width: 150px;
      cursor: pointer;
      height: 40px;
      text-align: center;
      line-height: 40px;
      border-radius: 10px;
      background: $main-color;
    }
  }
  .auto {
    .pic {
      width: 50px;
      height: 50px;

      background: #404040;
      border-radius: 50px;
    }
    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: white;
      position: relative;
      input {
        position: absolute;
        left: -60px;
        opacity: 0;
      }
      margin-top: 10px;
    }
    margin-top: 100px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .content {
    padding: 0 40px;
    .p1 {
      display: flex;
      .fk {
        .active {
          background: $main-color;
          border-radius: 10px;
          cursor: pointer;
        }
        display: flex;
        width: 200px;
        background: #404040;
        border-radius: 10px;
        height: 30px;
        div {
          flex: 1;
          color: white;
          line-height: 30px;
          text-align: center;
        }
      }
      .name {
        width: 80px;
        color: white;
      }
      input,
      select {
        background: #404040;
        outline: none;
        border: 0;
        height: 30px;
        color: white;
        border-radius: 10px;
        padding-left: 10px;
        width: 180px;
      }
      select {
        width: 200px;
      }
      textarea {
        background: #404040;
        outline: none;
        border: 0;
        width: 180px;
        height: 200px;

        color: white;
        border-radius: 10px;
        padding-left: 5px;
      }
      margin-bottom: 20px;
    }
  }
}
</style>