<template xmlns="http://www.w3.org/1999/html">
    <div class="box" @click="handleScroll">
        <div class="top">
            <div class="top-box" @click="goNeed">
                <img src="@/assets/static/inV.png" />
                <div class="btn">找设计和施工</div>
            </div>
            <div class="top-box" @click="goEnter">
                <img src="@/assets/static/cread-bg.png" />
                <div class="btn">入驻提交信息</div>
            </div>
        </div>
        <div class="main">
            <img src="@/assets/static/start-10.png" />
            <div class="main-box">
                <div class="tip">
                    平面图计价标准： <br />
                    • 同一平面单个方案每平方10元 <br />• 同一平面两个不同方案每平方12元 <br />•
                    同一平面三个不同方案每平方15元 <br />计费面积按照图纸实测为准 <br />PDF和CAD格式交付
                </div>
                <div class="btn" @click="open">提交装修平面图让设计师完成专业设计</div>
            </div>
        </div>
        <div class="fixed" v-if="fixedShow">
            <div class="modal" v-show="modal"></div>
            <div class="content">
                <div class="title">
                    <div class="l">提交平面图获取平面设计报价</div>
                    <div @click="fixedShow = false" class="r pointer">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="msg">
                    <div class="msg-box">
                        <!--上传图片和提示-->
                        <div class="img-tips">
                            <!--上传图片-->
                            <div class="img-box">
                                <div class="img-title">上传平面图</div>
                                <div class="img-list">
                                    <div
                                        class="img-item"
                                        v-show="image.length > 0"
                                        v-for="(item, index) in image"
                                        :key="index">
                                        <img :src="item" />
                                        <div class="del-icon" @click="delsample(index)">
                                            <img src="@/assets/static/close-two.png" />
                                        </div>
                                    </div>
                                    <div class="img-item" style="position: relative" v-show="image.length < 1">
                                        <img src="@/assets/static/my-add.png" />
                                        <input type="file" ref="sampleInput" @change="sampleFileUpload" />
                                    </div>
                                </div>
                            </div>
                            <!--提示-->
                            <div class="tips">
                                平面图计价标准： <br />
                                • 同一平面单个方案每平方10元 <br />• 同一平面两个不同方案每平方12元 <br />•
                                同一平面三个不同方案每平方15元 <br />计费面积按照图纸实测为准 <br />PDF和CAD格式交付
                            </div>
                        </div>
                        <!--    文本输入-->
                        <div class="inp-box">
                            <div class="inp-item">
                                <div class="label">平面类型</div>
                                <input v-model="formData.type" type="text" placeholder="请输入" />
                            </div>
                            <div class="inp-item">
                                <div class="label">手机号</div>
                                <input v-model="formData.mobile" type="text" placeholder="请输入您的手机号" />
                            </div>
                            <div class="inp-item">
                                <div class="label">称谓</div>
                                <input v-model="formData.name" type="text" placeholder="请输入" />
                            </div>
                            <div class="inp-item">
                                <div class="label">大约面积</div>
                                <input v-model="formData.area" type="text" placeholder="请输入" />
                            </div>
                            <div class="inp-item">
                                <div class="label">更多需求</div>
                                <textarea
                                    v-model="formData.need"
                                    placeholder="请输入"
                                    name=""
                                    id=""
                                    cols="30"
                                    rows="10"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="btn-box">
                        <div class="btn">提交</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            modal: false,
            fixedShow: false,
            image: [],
            formData: {
                type: '', //平面类型
                mobile: '', //手机号
                name: '', //称谓
                area: '', //大约面积
                need: '', //更多需求
            },
        }
    },
    created() {
        // this.getList(1)
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        // 上传图片
        sampleFileUpload() {
            let file = this.$refs.sampleInput.files[0]
            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')

            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    // this.SettleData.img = res.data.url
                    this.image.push(res.data.url)
                    console.log(this.image)
                    // 处理上传成功后的响应
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        },
        delsample(index) {
            this.image.splice(index, 1)
        },
        goDetail(item) {
            if (this.fixedShow) {
                return
            }
            this.$router.push({
                path: '/CreationDetail',
                query: { detail: item },
            })
        },
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            const clientHeight = document.documentElement.clientHeight || window.innerHeight
            if (scrollTop + clientHeight >= scrollHeight) {
                if (this.rows.length < this.total) {
                    this.getList()
                }
            }
        },
        //     需求
        goNeed() {
            if (this.fixedShow) {
                return
            }
            // this.$router.push('/Need')
            this.$router.push('/CreationList')
        },
        //     入驻
        goEnter() {
            if (this.fixedShow) {
                return
            }
            this.$router.push('/wantEnter')
        },
        open() {
            if (this.fixedShow) {
                return
            }
            this.modal = true
            this.fixedShow = true
        },
    },
}
</script>
<style lang="scss" scoped>
::-webkit-scrollbar {
    width: 0 !important;
}

.box {
    padding: 63px 260px 0;
    //height: calc(100vh - 64px);
    //background: url('/src/assets/static/purchase.png') no-repeat 0 0 / 100% 100%;
    display: flex;
    //justify-content: center;
    background: #111111;
    flex-direction: column;
    align-items: center;
    //找设计师、加V
    .top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .top-box {
            width: 640px;
            height: 352px;
            border-radius: 10px;
            background: rgba(0, 0, 0, 0.5);
            // display: flex;
            // align-items: center;
            // justify-content: center;
            position: relative;
            &:first-child {
                margin-right: 15px;
            }

            img {
                width: 640px;
                height: 352px;
            }

            .btn {
                position: absolute;
                z-index: 9;
                width: 348px;
                height: 48px;
                border-radius: 10px;
                background: rgba(255, 70, 0, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 700;
                line-height: 25.2px;
                color: rgba(255, 255, 255, 1);
                bottom: 40px;
                right: 46px;
                cursor: pointer;
            }
        }
    }

    .main {
        // width: 100%;
        height: 720px;
        opacity: 1;
        border-radius: 10px;
        margin-top: 15px;
        // display: flex;
        // align-items: center;
        // justify-content: center;
        position: relative;
        img {
            width: 1295px;
            height: 720px;
        }

        .main-box {
            position: absolute;
            z-index: 9;
            display: flex;
            flex-direction: column;
            bottom: 63px;
            right: 46px;

            .tip {
                font-size: 20px;
                font-weight: 400;
                line-height: 30px;
                color: rgba(255, 255, 255, 1);
            }

            .btn {
                width: 345px;
                height: 48px;
                opacity: 1;
                border-radius: 8px;
                background: rgba(255, 70, 0, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
                font-weight: 900;
                line-height: 25.2px;
                color: rgba(255, 255, 255, 1);
                margin-top: 20px;
                cursor: pointer;
            }
        }
    }
}

.fixed {
    .modal {
        // display: none; /* 默认隐藏 */
        position: fixed; /* 固定定位 */
        z-index: 99; /* 确保在其他内容之上 */
        left: 0;
        top: 0;
        width: 100%; /* 宽度全屏 */
        height: 100%; /* 高度全屏 */
        overflow: auto; /* 如果需要滚动条，启用 */
        background-color: rgb(0, 0, 0); /* 遮罩颜色 */
        background-color: rgba(0, 0, 0, 0.6); /* 遮罩透明度 */
    }

    .content {
        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        top: 50%;
        width: 1300px;
        height: 798px;
        background: #333333;
        // background: #111111;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        flex-direction: column;
        .title {
            height: 54px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 16px;
            color: rgba(255, 255, 255, 1);

            .l {
                height: 68px;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 68px;
            }
        }

        .msg {
            flex: 1;
            padding: 30px 30px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .msg-box {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex: 1;

                //    上传图片和提示
                .img-tips {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    //上传图片
                    .img-box {
                        display: flex;
                        flex-direction: column;

                        .img-title {
                            font-size: 24px;
                            font-weight: 500;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                        }

                        .img-list {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-top: 23px;

                            .img-item {
                                width: 102px;
                                height: 102px;
                                border-radius: 10px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                position: relative;
                                background: #383838;

                                img {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 10px;
                                }

                                input {
                                    position: absolute;

                                    width: 100%;
                                    height: 100%;
                                    opacity: 0;
                                }

                                .del-icon {
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    right: 10px;
                                    top: 10px;

                                    img {
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }
                    }

                    //提示
                    .tips {
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 24px;
                        color: rgba(255, 255, 255, 1);
                    }
                }

                //文本输入
                .inp-box {
                    display: flex;
                    flex-direction: column;

                    .inp-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        margin-top: 34px;

                        &:first-child {
                            margin-top: 0;
                        }

                        &:last-child {
                            align-items: flex-start;
                        }

                        .label {
                            width: 96px;
                            font-size: 24px;
                            font-weight: 500;
                            letter-spacing: 0px;
                            line-height: 24px;
                            color: rgba(255, 255, 255, 1);
                            margin-right: 16px;
                            text-align: right;
                        }

                        input {
                            padding: 12px 24px;
                            outline: none;
                            color: white;
                            border: 0;
                            width: 554px;
                            //height: 40px;
                            background: rgba(255, 255, 255, 0.2);
                            border-radius: 48px 48px 48px 48px;

                            &::placeholder {
                                font-size: 16px;
                                font-weight: 400;
                                // line-height: 16px;
                                color: rgba(255, 255, 255, 0.7);
                            }
                        }

                        textarea {
                            color: white;
                            outline: none;
                            background: #474747;
                            flex: 1;
                            border: 0;
                            height: 220px;
                            border-radius: 24px;
                            padding: 12px 24px;
                            resize: none;
                            &::placeholder {
                                font-size: 16px;
                                font-weight: 400;
                                // line-height: 16px;
                                color: rgba(255, 255, 255, 0.7);
                            }
                        }
                    }
                }
            }

            .btn-box {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 100px;

                .btn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 296px;
                    height: 55px;
                    border-radius: 12px;
                    background: rgba(255, 70, 0, 1);
                    font-size: 20px;
                    font-weight: 500;
                    letter-spacing: 0px;
                    line-height: 41px;
                    color: rgba(255, 255, 255, 1);
                }
            }
        }
    }
}
</style>
