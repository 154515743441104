<template>
    <div class="headerWarp">
        <div class="header">
            <div class="l">
                <img class="img" src="../assets/static/logo.png" />
            </div>
            <div class="c">
                <input @input="changeHandel()" v-model="sharedValue" type="text" placeholder="看看已生成的作品" />
                <img src="../assets/static/cd.png" />
            </div>
            <div class="r">
                <div
                    v-for="(el, idx) in headList"
                    :key="idx"
                    @click="sendMesHandel(idx)"
                    :class="$store.state.headerType == idx ? 'active' : ''"
                    class="item">
                    <span v-if="el.name"> {{ el.name }}</span>
                    <img v-if="el.image" style="width: 25px; height: 25px" :src="el.image" />
                </div>
                <div @click="messageHandel()" class="item">消息</div>
                <div class="item" @click="myHandel" v-if="$store.state.user.avatar">
                    <div class="avatar-box">
                        <img
                            class="overall pic"
                            v-if="$store.state.user.avatar"
                            :src="$store.state.user.avatar[0].url" />
                        <img class="overall pic" v-else src="@/assets/static/my.png" />
                        <div class="selected" @click="myHandel" v-if="$store.state.user.settled == 3">
                            <img src="@/assets/static/ruzhu.png" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Entrymessage v-if="$store.state.messageType"></Entrymessage>
        <Personal v-if="$store.state.myType"></Personal>
    </div>
</template>
<script>
import Entrymessage from '@/components/staging/Entrymessage'
import Personal from '@/components/Personal'
import { mapState } from 'vuex'

export default {
    components: { Entrymessage, Personal },
    props: {
        type: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        ...mapState(['headList']), // 映射名为'headList'的状态
    },
    data() {
        return {
            sharedValue: '',
        }
    },
    created() {
        this.$axios.post('user/info').then((res) => {
            this.$store.commit('userHandel', res.data.info)
            this.WebSocketHandel()
        })
    },

    mounted() {
        const path = this.$route.path
        if (path == '/merge' || path == '/') {
            if (this.$store.state.headerType >= 2 && path != '/merge') {
                this.$router.push('/merge')
            }
            if (this.$store.state.headerType < 2 && path != '/') {
                this.$router.push('/')
            }
        }

        document.addEventListener('click', this.handlePageClick)
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handlePageClick)
    },
    beforeDestroy() {
        document.removeEventListener('click', this.handlePageClick)
    },

    methods: {
        handlePageClick(event) {
            if (event.target.innerText == '消息') {
                this.$store.commit('messageHandel', !this.$store.state.messageType)
                this.$store.commit('myHandel', false)

                return
            }

            // 在这里处理点击事件
            const clickedElement = event.target
            const classNames = clickedElement.classList
            if (classNames[0] == 'overall') {
                this.$store.commit('myHandel', !this.$store.state.myType)
                this.$store.commit('messageHandel', false)

                return
            }
            if (classNames[0] == 'fixedr') return
            if (
                classNames[0] == 'picWrap' ||
                classNames[0] == 'name' ||
                classNames[0] == 'fk' ||
                classNames[0] == 'p1'
            ) {
                return
            }
            this.$store.commit('messageHandel', false)
            this.$store.commit('myHandel', false)

            // if (this.$store.state.messageType) {
            //   this.$store.commit('messageHandel', false)
            //   return
            // }
        },
        WebSocketHandel() {
            // 上线
            this.$websocket.sendSocketMsg({
                type: 'online',
                data: {
                    uid: this.$store.state.user.id,
                },
            })

            //开启数据监听
            this.$websocket.addListener('msg', (res) => {
                if (res.type == 'msg') {
                    this.$store.commit('newMesHandel', res.data)
                }
            })
        },
        myHandel() {
            // this.$store.commit('myHandel', !this.$store.state.myType)
            // this.$store.commit('messageHandel', false)
        },
        messageHandel() {
            // this.$store.commit('messageHandel', !this.$store.state.messageType)
            // this.$store.commit('myHandel', false)
        },
        changeHandel() {
            if (this.$route.path != '/') {
                this.$router.push('/')
            }
            // this.$router.push('/home')
            this.$store.commit('changeValue', this.sharedValue)
        },
        sendMesHandel(i) {
            // this.$store.state.headerType = i
            console.log(i)
            if (i >= 2 && this.$route.path != '/merge') {
                this.$router.push('/merge')
            }
            if (i < 2 && this.$route.path != '/') {
                this.$router.push('/')
            }
            // if(i == 5){
            //     this.$router.push('/CreationCentre')
            // }

            this.$store.commit('headerTypeHandel', i)
            this.$emit('send-message', i)
        },
    },
}
</script>
<style lang="scss" scoped>
.headerWarp {
    height: 60px;
    z-index: 13;
    position: relative;

    .header {
        .l {
            min-width: 250px;
            flex-basis: 15%;

            .img {
                width: 200px;
                height: 30px;
            }
        }

        .c {
            min-width: 750px;
            display: flex;
            flex-basis: 60%;
            align-items: center;

            img {
                margin-left: 10px;
                width: 25px;
                height: 25px;
            }

            input {
                width: 92%;
                background: #222222;
                border: none;
                color: white;
                height: 30px;
                padding-left: 10px;
                border-radius: 10px;
            }
        }

        .r {
            flex-basis: 25%;
            min-width: 300px;
            .item {
                cursor: pointer;
                line-height: 55px;
                display: flex;
                align-items: center;
                border-bottom: 3px solid transparent;

                .avatar-box {
                    line-height: 55px;
                    display: flex;
                    align-items: center;
                    position: relative;

                    .pic {
                        border-radius: 50px;
                        width: 25px;
                        height: 25px;
                    }

                    .selected {
                        width: 12px;
                        position: absolute;
                        right: -5px;
                        bottom: 0;
                        line-height: 0;

                        img {
                            width: 12px;
                        }
                    }
                }
            }

            .active {
                line-height: 55px;
                border-bottom: 3px solid white;
            }

            margin-top: 5px;
            display: flex;
            justify-content: space-between;
            color: rgba(255, 255, 255, 0.7);
        }

        height: 64px;
        background: #111111;
        border-bottom: 0.0625rem solid rgb(104, 102, 102);
        display: flex;
        box-sizing: border-box;
        align-items: center;
        padding: 20px 100px;
        color: white;
        box-sizing: border-box;
        position: fixed;
        width: 100%;
    }

    @media screen and (max-width: 1200px) {
        .header {
            /* 修改样式以适应小屏幕 */
            // flex-direction: column;
            /* 其他修改 */
            .c {
                flex-basis: 45%;
                min-width: 600px;
            }

            .r {
                flex-basis: 40%;
            }
        }
    }
}
</style>
