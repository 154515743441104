export default {
    data() {
        return {
            loading: true,
            page: {
                pageNo: 1,
                pageSize: 10,
                more: 'loadmore',
                filter: {},
                op: {},
                search: '',
            },
            filter: {}, // 额外的查询参数，避免与多级查询混淆
            total: 0,
            rows: [],
        }
    },

    methods: {
        handleScroll() {
            // 获取当前滚动位置与页面总高度的差值
            const scrollHeight = this.$refs.container.scrollHeight
            const scrollTop = this.$refs.container.scrollTop
            const clientHeight = this.$refs.container.clientHeight
            const diff = scrollHeight - scrollTop - clientHeight

            // 判断是否已经滚动到底部
            if (diff <= 10) {
                this.loading = true
                this.getList()
            }
        },
        searchFilter({ filter, sort, op }) {
            if (filter) {
                this.page.filter = filter
            }
            if (sort) {
                this.page.order = sort.order
                this.page.sort = sort.sort
            }
            if (op) {
                this.page.op = op
            }
            this.getList(1)
        },
        getList(pageNo) {
            if (!this.action) return
            if (pageNo == 1) {
                this.page.pageNo = 1
                this.$axios.post(this.action, this.page).then((res) => {
                    const row = res.data.rows.map((res) => {
                        return { ...res, select: false }
                    })
                    console.log('row', row)
                    this.rows = row
                    this.total = res.data.total
                    this.loading = false
                })
            } else {
                this.page.pageNo += 1
                this.$axios.post(this.action, this.page).then((res) => {
                    const row = res.data.rows.map((res) => {
                        return { ...res, select: false }
                    })
                    this.rows = this.rows.concat(row)
                    this.loading = false
                })
            }
        },
    },
}
