import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
let baseSize = 16;
function setRem() {
	const screenWidth = document.documentElement.clientWidth;
	const screenHeight = document.documentElement.clientHeight;
	switch (true) {
		// case screenWidth <= 1360:
		// 	baseSize = 12;
		// 	break;
		case screenWidth <= 1580:
			baseSize = 12;
			break;
		case screenWidth <= 1600:
			baseSize = 14;
			break;
		case screenWidth <= 1720:
			baseSize = 15;
			break;
		case screenWidth <= 1920:
			baseSize = 16;
			break;
		case screenWidth <= 2560:
			baseSize = 19;
			break;
		case screenWidth <= 3440:
			baseSize = 24;
			break;
		default:
		// 默认逻辑处理
	}
	let scale = document.documentElement.clientWidth / screenWidth;

	if (screenHeight <= 700) {
		scale = 0.8;
	}
	document.documentElement.style.fontSize = baseSize * Math.min(scale, 2) + 'px';
}
setRem();
window.onresize = function () {
	setRem();
};

Vue.use(ElementUI);

export default function install(Vue) {
	Vue.prototype.$message = ElementUI.Message;
	Vue.prototype.$notify = ElementUI.Notification;
}
