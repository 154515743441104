// directives.js
import Vue from 'vue';
Vue.directive('scroll', {
	bind(el, binding) {
		el.addEventListener('wheel', (event) => {
			// 检查滚轮方向
			const delta = Math.max(-1, Math.min(1, event.wheelDelta || -event.detail));

			// 检查元素的滚动位置
			const isScrolledToTop = el.scrollTop === 0;
			const isScrolledToBottom = el.scrollTop + el.clientHeight >= el.scrollHeight;

			// 根据滚轮方向和滚动位置进行相应操作
			if (delta < 0 && isScrolledToBottom) {
				// 元素已经滚动到底部
				binding.value('bottom');
			} else if (delta > 0 && isScrolledToTop) {
				// 元素已经滚动到顶部
				binding.value('top');
			}
		});
	}
});

Vue.directive('scroll-x', {
	bind(el, binding) {
		el.addEventListener('mousedown', function (event) {
			// 记录初始位置和鼠标按下时的坐标
			const initialScrollLeft = el.scrollLeft;
			const initialClientX = event.clientX;

			function handleMouseMove(event) {
				// 计算鼠标移动的距离
				const distance = event.clientX - initialClientX;

				// 设置容器元素的滚动位置
				el.scrollLeft = initialScrollLeft - distance;
			}

			function handleMouseUp() {
				// 在鼠标释放时移除事件监听器
				document.removeEventListener('mousemove', handleMouseMove);
				document.removeEventListener('mouseup', handleMouseUp);
			}

			// 在鼠标按下时添加事件监听器
			document.addEventListener('mousemove', handleMouseMove);
			document.addEventListener('mouseup', handleMouseUp);
		});
	}
});
Vue.directive('lazys', {
	bind(el, binding) {
		// 创建一个新的 Intersection Observer 实例
		const observer = new IntersectionObserver(
			(entries, observer) => {
				// 遍历每个观察的元素
				entries.forEach((entry) => {
					// 当元素进入视口时
					if (entry.isIntersecting) {
						// 加载图片
						const image = new Image();
						image.onload = function () {
							// 图片加载成功后替换为实际图片
							el.src = binding.value.src;
							observer.unobserve(el); // 停止观察该元素
						};
						image.onerror = function () {
							// 加载失败时显示错误图
							el.src = binding.value.errorSrc;
							observer.unobserve(el); // 停止观察该元素
						};
						image.src = binding.value.src;
					}
				});
			},
			{
				rootMargin: '0px', // 设置视口的边界
				threshold: 0.1 // 设置交叉比例阈值
			}
		);

		// 开始观察元素
		observer.observe(el);
	}
});
Vue.directive('scroll-bottom', {
	bind(el, binding) {
		el.addEventListener('scroll', () => {
			const scrollHeight = el.scrollHeight;
			const scrollTop = el.scrollTop;
			const clientHeight = el.clientHeight;

			if (scrollHeight - scrollTop === clientHeight) {
				// 滚动条到达底部的处理逻辑
				binding.value();
			}
		});
	}
});
