export default {
    data() {
        return {
            loading: true,
            page: {
                pageNo: 1,
                pageSize: 10,
                more: 'loadmore',
                filter: {},
                op: {},
                search: '',
            },
            filter: {}, // 额外的查询参数，避免与多级查询混淆
            total: 0,
            rows: [],
            scrollTop: 0,
        }
    },

    methods: {
        handleScroll() {
            // 获取当前滚动位置与页面总高度的差值
            console.log('执行了')
            const scrollHeight = this.$refs.container.scrollHeight
            const scrollTop = this.$refs.container.scrollTop
            const clientHeight = this.$refs.container.clientHeight
            const diff = scrollHeight - scrollTop - clientHeight
            this.scrollTop = scrollTop
            // 判断是否已经滚动到底部
            if (diff <= 5) {
                this.loading = true
                this.getList()
            }
        },
        searchFilter({ filter, sort, op }) {
            if (filter) {
                this.page.filter = filter
            }
            if (sort) {
                this.page.order = sort.order
                this.page.sort = sort.sort
            }
            if (op) {
                this.page.op = op
            }
            this.getList(1)
        },
        getList(pageNo) {
            if (!this.action) return
            if (pageNo == 1) {
                this.page.pageNo = 1
                this.$axios.post(this.action, this.page).then((res) => {
                    this.rows = res.data.rows
                    this.total = res.data.total
                    this.loading = false
                })
            } else {
                this.page.pageNo += 1
                this.$axios.post(this.action, this.page).then((res) => {
                    this.rows = this.rows.concat(res.data.rows)
                    this.loading = false
                })
            }
        },
    },
}
