<template>
    <div class="parentWrap">
        <div class="parent">
            <div class="container" v-for="(rows, index) in list" :key="index">
                <div @click="toHandel(el)" class="child" v-for="(el, idx) in rows" :key="idx">
                    <!--:style="{height:el.height + 'px'}"-->
                    <div class="p">
                        <div class="p1" :title="el.title">{{ el.title }}</div>
                    </div>
                    <img
                        v-if="el.cover[0]"
                        v-lazy="{ src: el.cover[0].url, loading: el.loading, error: '/image/LoadFail.png' }"
                        style="border-radius: 10px" />
                </div>
            </div>
        </div>
        <div class="loading">
            <span>{{ rows.length == total ? '没有更多了!' : '加载中' }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'community',
    props: {
        rows: {
            type: Array,
            default: [],
        },
        total: Number,
        default: 0,
    },
    methods: {
        // handleScrolls(position) {
        //   if (position === 'top') {
        //     this.$store.commit('headerTypeHandel', 0)
        //   }
        // },
        // handleMouseWheel(e) {
        //   this.y += e.wheelDeltaY
        //   if (this.y > 120 && this.scrollTop == 0) {
        //     this.$store.commit('headerTypeHandel', 0)
        //   }
        //   if (this.y < -120) {
        //     this.y = 0
        //   }
        // },
        toHandel(el) {
            this.$router.push({
                path: '/Comment',
                query: { id: el.id },
            })
        },
    },
    watch: {
        // '$store.state.headerType'(n) {
        //   if (n == 1) {
        //     window.addEventListener('wheel', this.handleMouseWheel, {
        //       passive: false
        //     })
        //   } else {
        //     window.removeEventListener('wheel', this.handleMouseWheel, {
        //       passive: false
        //     })
        //   }
        // },
    },
    computed: {
        list() {
            const rows = [[], [], []]
            const len = rows.length
            this.rows.forEach((it, i) => {
                const size = it.size.split('X')
                it.height = (((75 - 1.25 * 2) / 3) * size[1]) / size[0]
                it.loading = `/image/loading/${Math.floor(Math.random() * 14) + 1}.png`
                rows[i % len].push(it)
            })
            return rows
        },
    },
    beforeDestroy() {},
}
</script>
<style lang="scss" scoped>
.parentWrap {
    // height: calc(100vh - 64px);
    // overflow: auto;
    // background: #000000;
    min-height: calc(100vh - 64px);
    // padding-top: 100px;
    padding-top: 26px;
    background-color: white;

    .loading {
        font-size: 30px;
        text-align: center;
        font-weight: bold;
        padding: 20px 0;
        color: white;
    }

    .parent {
        width: 75rem;
        margin: 0 auto 15px;
        display: flex;
        gap: 15px;

        .container {
            flex: 1;
        }

        .child {
            display: inline-block;
            width: 100%;
            margin-bottom: 15px;
            position: relative;
            border-radius: 10px;
            overflow: hidden;

            img {
                // z-index: 1;
                // position: relative;
            }

            .p {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
                position: absolute;
                bottom: 0;
                display: flex;
                align-items: flex-end;
                // justify-content: ;
                color: white;
                height: 100px;
                padding: 10px;
                width: 100%;
                text-align: right;

                .p1 {
                    line-height: 40px;
                    height: 40px;
                    padding: 0 13.28px;
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 4px;
                    max-width: 345px; /* 设置容器宽度 */
                    white-space: nowrap; /* 防止文字换行 */
                    overflow: hidden; /* 文字超出时隐藏 */
                    text-overflow: ellipsis; /* 文字超出时显示省略号 */
                }
            }
        }

        .child img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
