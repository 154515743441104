import pako from "./pako.js"
import {
	fromByteArray,
	toByteArray
} from 'base64-js'

//加密
export function encode(str, key) {

	let len = key.length;
	let b = fromByteArray(pako.deflate(str))
	let ns = fromByteArray(pako.deflate(b.substr(0, len) + key + b.substr(len)))
	let nl = ns.length,
		l = len > nl ? nl : len;
	return ns.substr(l - 2, nl - l) + ns.substr(0, l - 2) + ns.substr(nl - 2)

}

export function decode(str, key) {
	if (str.indexOf('{') > -1) {
		return false
	}
	let len = key.length;
	let nl = str.length,
		l = len > nl ? nl : len;
	let os = str.substr(nl - l, l - 2) + str.substr(0, nl - l) + str.substr(nl - 2)
	try {
		let restored = pako.inflate(toByteArray(os), {
			to: 'string'
		});
		os = restored.replace(key, "");
		restored = pako.inflate(toByteArray(os), {
			to: 'string'
		});
		return restored;
	} catch (e) {
		return false
	}
}
