<template>
  <div class="page-staging">
    <div class="staging" v-if="pagetype">
      <Stagingtop></Stagingtop>
      <Stagingbottom @createHandel="createHandel"></Stagingbottom>
      <picloding :lrtype="lrtype" @lrtypeHandel="lrtype=false"></picloding>
      <Applyentry :lrtype="lrtype" v-if="lrtype && settled==0"></Applyentry>
      <Entrymessage v-if="lrtype && settled==3"></Entrymessage>
      <!-- 两边锁起来的小窗口 -->
      <div class="fixeds pointer" v-if="!lrtype" @click="lrtype=!lrtype">
        <img style="width:40px;height:40px;" src="@/assets/static/lr.png" />
      </div>
    </div>
    <!-- 点击图片生成的界面 -->
    <div class="screenloading" v-else style="background:black;">
      <img src="@/assets/static/loading.gif" />
<!--      <div class="text">点一下生成大师Ai设计</div>-->
      <div class="loading" style="padding: 0 0 20px;">
        <el-progress define-back-color="#343434" :show-text="false" color="white" :percentage="percentages"></el-progress>
      </div>
    </div>
  </div>
</template>
<script>
// import 'swiper/swiper-bundle.css'
import picloding from '@/components/staging/picloding'
import Entrymessage from '@/components/staging/Entrymessage'
import Stagingtop from '@/components/staging/Stagingtop'
import Stagingbottom from '@/components/staging/Stagingbottom'
import Applyentry from '@/components/staging/Applyentry'
export default {
  components: { picloding, Entrymessage, Stagingtop, Stagingbottom, Applyentry },
  data() {
    return {
      lrtype: true,
      pagetype: true,
      percentages: 10,
      // 是否入驻
      settled: 0
    }
  },
  mounted() {
    // 是否入驻
    this.$axios.post('user/info').then((res) => {
      this.settled = res.data.info.settled
    })
  },
  methods: {
    createHandel() {
      this.pagetype = false
      const r = setInterval(() => {
        this.percentages += 30
        if (this.percentages >= 100) {
          clearInterval(r)
          this.$router.push({
            path: '/designsucess'
          })
        }
      }, 1000)
    }
  }
}
</script>
<style lang="scss" scoped>
.page-staging {
  .screenloading {
    .loading {
      width: 240px;
      padding: 0 0 20px;
      margin-top: -30px;
    }
    height: calc(100vh - 64px);
    .text {
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 24px;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
  .staging {
    position: relative;
    .fixeds {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      left: 0;
      height: 100%;
      width: 50px;
      background: #777777;
    }

    height: calc(100vh - 64px);
    display: flex;
    // padding: 0 100px;
    // box-sizing: border-box;
    flex-direction: column;
  }
}
</style>
