<template>
    <div class="my">
        <div class="myTop">
            <div class="l" v-for="(el, idx) in MyinfoList.mytop1" :key="idx">
                <img class="img" :src="el.img" style="width: 100%; height: 100%" />
                <!---->
                <div class="title">
                    <div class="title-l">{{ el.title }}</div>
                    <div class="title-r">
                        <div class="pic">{{ el.pic }}</div>
                        <div :style="{ background: el.notice.bg }" class="tie">{{ el.notice.text }}</div>
                    </div>
                </div>
                <div class="bom">
                    <ul class="ul-list">
                        <li v-for="(el1, idx) in el.uls" :key="idx">{{ el1.name }}</li>
                    </ul>
                    <!-- <div>
                        <img style="width: 120px" :src="el.qrcode" />
                    </div> -->
                </div>
            </div>
        </div>
        <div class="myBottom">
            <div
                class="item"
                :class="el.checked ? 'action' : ''"
                v-for="(el, idx) in myBottom"
                :key="el.id"
                @click="chang(el.id)">
                <img class="img" v-if="el.image.length > 0" :src="el.image[0].url" />
                <div class="p1">{{ el.pid_text }}</div>
                <div class="p1">{{ el.name }}</div>
                <div class="p3">￥{{ Number(el.price) }}</div>
                <!--<div class="p4">免费入驻平台</div>-->
                <!--<div class="p4">最划算</div>-->
                <!--<div v-if="el.p6" class="p5">{{ el.p6 }}</div>-->
                <div class="line" v-show="el.line_price > 0">￥{{ Number(el.line_price) }}</div>
                <!-- <div class="meng"></div> -->
                <div class="tips" v-show="el.tip === '1'">限时优惠</div>
            </div>
        </div>
        <div class="pay-btn">
            <div class="btn" @click="getOrder">支付</div>
        </div>

        <el-dialog
            :visible.sync="show"
            width="30%"
            title="请扫码支付"
            center
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false">
            <div class="code">
                <qrcode-vue :value="wx_value" :size="size" level="H" />
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancel">取 消</el-button>
            </span>
        </el-dialog>

        <el-dialog
            :visible.sync="vipshow"
            width="30%"
            title="会员权益介绍"
            center
            :close-on-click-modal="false"
            :close-on-press-escape="false"
            :show-close="false">
            <div class="vipImage">
                <el-image
                    style="width: 100%"
                    :src="vip_image.length > 0 ? vip_image[0].url : ''"
                    fit="contain"></el-image>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="vipcancel">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'

export default {
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            myBottom: [],
            myImg: [
                {
                    img: require('@/assets/static/pro1.png'),
                },
                {
                    img: require('@/assets/static/pro2.png'),
                },
            ],
            MyinfoList: {
                mytop1: [
                    {
                        title: '人工AI生成服务',
                        pic: '5999元/月',
                        // img: require('@/assets/static/my1.png'),
                        // qrcode: require('@/assets/static/contact.jpg'),
                        img: require('@/assets/static/pro1.png'),
                        qrcode: '',
                        notice: {
                            bg: '#ff4200',
                            text: '限时优惠中',
                        },
                        uls: [
                            { name: '一对一服务，和客服提出需求后无需操作' },
                            { name: '每月限200张' },
                            { name: '图像画质细节提升' },
                            { name: '设计参考图/方案/效果图 平替解决方案' },
                            // { name: '不限使用次数' },
                            // { name: '按照需求定制' },
                        ],
                    },
                    {
                        title: '企业定制版',
                        pic: '99999元/年',
                        // img: require('@/assets/static/my2.png'),
                        img: require('@/assets/static/pro2.png'),
                        qrcode: require('@/assets/static/contact.jpg'),
                        notice: {
                            bg: '#141baa',
                            text: '限时优惠中',
                        },
                        uls: [
                            { name: '专属登录入口' },
                            { name: '专属独享服务器，没有繁忙时段' },
                            { name: '省去充值扣费环节' },
                            { name: '独立训练专属模型' },
                            { name: '图像分辨率大幅提升' },
                            { name: '不限使用次数' },
                        ],
                    },
                ],
                mytop: [],
            },
            show: false,
            // 企业微信二维码
            code_img: [],
            //     商品id
            id: '',
            //     支付二维码
            wx_value: '',
            size: 200,
            //     订单编号
            order_no: '',
            timer: null,
            vipshow: false,
            vip_image: [],
        }
    },
    components: {
        QrcodeVue,
    },
    mounted() {
        this.getVipList()
        this.getPayModelData()
        this.getCode()
    },
    methods: {
        getPayModelData() {
            this.$axios.post('design/customize/customize', { type: 4 }).then((res) => {
                this.MyinfoList.mytop = { ...res.data.image, myImg: this.myImg }
            })
        },
        getVipList() {
            this.$axios.post('design/vip/package/list', { edition: 3 }).then((res) => {
                res.data.forEach((el, idx) => {
                    el.checked = false
                    // console.log('lll', el)
                    this.myBottom.push(el)
                })
            })
        },
        chang(num) {
            // console.log(num)

            this.myBottom.forEach((el, idx) => {
                el.checked = false

                if (el.id === num) {
                    el.checked = true
                    this.id = el.id
                    if (el.right_image.length > 0) {
                        this.vip_image = el.right_image
                        this.vipshow = true
                    }
                }
            })
        },
        // 关闭弹出层
        cancel() {
            this.show = false
            clearInterval(this.timer)
            this.timer = null
        },
        //     获取二维码
        getCode() {
            this.$axios.post('index/getAppInfo').then((res) => {
                // console.log('获取二维码', res)
                this.code_img = res.data.value.wx_qrcode
            })
        },
        //     获取订单编号
        getOrder() {
            const list = {
                goods_id: this.id,
                pay_way: 1,
            }
            this.$axios.post('design/orders/create', list).then((res) => {
                // console.log(res)
                this.getWxCode(res.data)
                this.order_no = res.data.order_no
            })
        },
        //     获取支付二维码
        getWxCode(item) {
            const list = {
                pay_way: item.pay_way,
                order_no: item.order_no,
                panel: 'native',
            }
            this.$axios.post('design/orders/payOrder', list).then((res) => {
                // console.log(res)
                this.wx_value = res.data.code_url
                setTimeout(() => {
                    this.show = true
                    // 查询订单情况
                    this.getFound()
                }, 500)
            })
        },
        // 查询订单情况
        getFound() {
            this.timer = setInterval(() => {
                this.$axios.post('design/orders/checkOrder', { order_no: this.order_no }).then((res) => {
                    // console.log(res)
                    if (res.data.order_status === '-1') {
                        this.show = false
                        clearInterval(this.timer)
                        this.timer = null
                        this.$message({
                            message: '支付失败',
                            type: 'error',
                        })
                    } else if (res.data.order_status === '1') {
                        this.show = false
                        clearInterval(this.timer)
                        this.timer = null
                        this.$message({
                            message: '支付成功',
                            type: 'success',
                        })
                    }
                })
            }, 3000)
        },
        vipcancel() {
            this.vipshow = false
        },

        // 提取数字部分
        extractNumber(pic) {
            return pic.match(/\d+/g)[0]
        },
        // 提取单位部分
        extractUnit(pic) {
            return pic.match(/[^\d]+/g).join('')
        },
    },
}
</script>
<style lang="scss" scoped>
.my {
    height: calc(100vh - 64px);
    background: url('/src/assets/static/purchase.png') no-repeat 0 0 / 100% 100%;
    display: flex;
    justify-content: center;
    // background: #111111;
    flex-direction: column;
    align-items: center;

    .myBottom {
        .item {
            position: relative;
            font-size: 14px;
            display: flex;
            flex-direction: column;
            color: white;
            justify-content: center;
            align-items: center;
            padding: 20px 30px;
            margin: 0 20px;
            width: 500px;
            cursor: pointer;
            border-radius: 10px;
            background: rgba(255, 255, 255, 0.1);
            .img {
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                opacity: 0.3;
            }

            .p4 {
                z-index: 9;
            }

            .p1 {
                text-align: center;
                display: flex;
                align-items: center;
                z-index: 9;
                color: rgba(255, 255, 255, 0.7);
            }

            overflow: hidden;
            position: relative;

            .p5 {
                z-index: 9;
                position: absolute;
                right: 0;
                top: 0;
                width: 60px;
                border-bottom-left-radius: 10px;
                height: 25px;
                line-height: 25px;
                text-align: center;
                background: #fe773c;
            }

            .p3 {
                z-index: 9;
                line-height: 40px;
                font-size: 20px;
                color: #ff4200;
            }

            .line {
                text-decoration: line-through;
                color: rgba(255, 255, 255, 0.7);
            }

            .meng {
                width: 100%;
                height: 100%;
                background-color: rgba(17, 17, 17, 0.6);
                position: absolute;
                z-index: 8;
            }

            .tips {
                width: 109px;
                height: 39px;
                opacity: 1;
                border-radius: 0 10px 0 10px;
                background: rgba(255, 70, 0, 1);
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ffffff;
                position: absolute;
                z-index: 10;
                top: 0;
                right: 0;
            }

            &.action {
                border: 1px solid rgba(255, 70, 0, 1);
                background: rgba(255, 70, 0, 0.4);
                .p3 {
                    color: #ffffff;
                }

                .meng {
                    background-color: rgba(17, 17, 17, 0);
                }
            }
        }

        display: flex;
        width: 1400px;
        justify-content: space-between;
    }

    .myTop {
        width: 1400px;
        display: flex;
        margin-bottom: 30px;

        .l {
            background: rgba(255, 255, 255, 0.05);

            .img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                opacity: 0.3;
            }

            .bom {
                display: flex;
                justify-content: space-between;

                .ul-list {
                    position: relative;
                    z-index: 9;

                    li {
                        z-index: 9;
                    }

                    font-size: 14px;
                    line-height: 30px;
                    margin-top: 50px;
                }

                img {
                    margin-top: 50px;
                    margin-right: 5px;
                }
            }

            .title {
                position: relative;
                z-index: 9;
                display: flex;
                justify-content: space-between;

                .title-l {
                    font-size: 32px;
                }

                .title-r {
                    font-size: 48px;
                    font-weight: 500;

                    .tie {
                        height: 20px;
                        line-height: 20px;
                        margin-top: 10px;
                        font-size: 12px;
                        background: #ff4200;
                        text-align: center;
                    }

                    .pic {
                        font-size: 24px;
                    }
                }
            }

            position: relative;
            height: 360px;
            flex: 1;
            padding: 20px;
            border-radius: 10px;
            overflow: hidden;
            margin: 20px;
            color: white;
            // background: linear-gradient(to top left, rgba(29, 29, 29, 0.5), rgba(67, 51, 37, 0.5));
            background-size: 100% 100%;
        }
    }

    //支付按钮
    .pay-btn {
        margin-top: 40px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .btn {
            width: 220px;
            height: 44px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #ff4600;
            border-radius: 19px;
            font-size: 19px;
            font-weight: 500;
            color: #ffffff;
            line-height: 1;
            cursor: pointer;
        }
    }

    .code {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }

    .vipImage {
        width: 100%;
    }

    :deep(.el-dialog) {
        margin-top: 27vh !important;
    }

    :deep(.el-dialog__header) {
        background-color: #222222;

        .el-dialog__title {
            color: #909399;
        }
    }

    :deep(.el-dialog__body) {
        background-color: #222222;
    }

    :deep(.el-dialog__footer) {
        background-color: #222222;
    }
}
</style>
