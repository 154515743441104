<template>
  <div class="fixedl" v-if="lrtype">
    <div class="types">
      <div class="typewrap">
        <img @click="lrtypeHandel()" src="@/assets/static/lr.png" />
      </div>
    </div>
    <div class="wrap " ref="container" @scroll="handleScroll">
      <img style="width:100%;margin-bottom:10px;" :src="el.image[0].url" v-for="(el,idx) in rows" :key="idx" />
      <div class="loading">
        <span>{{rows.length==total ? '没有更多了!':'加载中'}}</span>
      </div>
    </div>

  </div>
</template>
<script>
import page from '@/config/page'

export default {
  props: {
    lrtype: {
      type: Boolean,
      default: false
    }
  },
  mixins: [page],
  data() {
    return {
      action: '/design/works/page'
    }
  },
  methods: {
    lrtypeHandel() {
      this.$emit('lrtypeHandel', false)
    }
  },
  mounted() {
    this.getList(1)
  }
}
</script>
<style lang="scss">
.loading {
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  padding: 20px 0;
  color: white;
}
.fixedl {
  position: absolute;
  top: 0;
  background: #777777;
  width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;

  .types {
    // height: 30px;
    padding: 20px 0;

    display: block;
    // background: red;
    position: relative;
    .typewrap {
      height: 40px;
      left: 0;
      top: 0;
      width: 100%;
      color: white;
      position: absolute;
      text-align: right;
      line-height: 40px;
      cursor: pointer;
    }
  }

  .wrap {
    overflow-y: scroll;
    padding: 0 20px;
    /* 设置滚动条宽度和高度 */
    &::-webkit-scrollbar {
      width: 2px;
      border-radius: 10px;
    }
    /* 设置滚动条背景颜色 */
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: $main-color;
    }
    /* 设置滚动条滑块颜色 */
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $main-color;
    }
    flex-grow: 1;
  }
}
</style>