var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('headers',{attrs:{"type":_vm.type},on:{"send-message":_vm.handleMessage}}),_c('div',{staticClass:"box"},[_c('backBtn',{attrs:{"left":150,"top":20},on:{"back":_vm.back}}),_vm._l((_vm.rows),function(item,index){return _c('div',{key:index,staticClass:"img-box",on:{"click":function($event){return _vm.goDetail(item)}}},[_c('img',{staticClass:"bj-img",attrs:{"src":item.sample && item.sample.length > 0 ? item.sample[0].url : _vm.defaultImg}}),_c('div',{staticClass:"item"},[_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"tx"},[(item.user)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:({
                                src: item.user.avatar[0].url,
                                loading: '/image/commin.png',
                                error: '/image/LoadFail.png',
                            }),expression:"{\n                                src: item.user.avatar[0].url,\n                                loading: '/image/commin.png',\n                                error: '/image/LoadFail.png',\n                            }"}]}):_c('img',{attrs:{"src":require("../assets/static/Ailogo.png"),"alt":""}}),_vm._m(0,true)]),_c('div',{staticClass:"msg"},[_c('div',{staticClass:"name-box"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.user ? item.user.nick_name : '未知设计师'))]),(item.user && item.user.address)?_c('div',{staticClass:"tip"},[_vm._v(_vm._s(item.user.address))]):_vm._e(),(item.type_name)?_c('div',{staticClass:"tip"},[_vm._v(_vm._s(item.type_name))]):_vm._e()]),_c('div',{staticClass:"referral"},[_vm._v(_vm._s(item.introduction))])]),_vm._m(1,true)])])])})],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tx-icon"},[_c('img',{attrs:{"src":require("@/assets/static/ruzhu.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"flex":"1","display":"flex","justify-content":"flex-end","align-items":"center"}},[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":require("@/assets/static/cread-icon.png")}})])])
}]

export { render, staticRenderFns }