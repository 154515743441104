import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './config/http'
import installElement from './config/element'
import 'swiper/css/swiper.css'
import './utils/directives'
// import VueCropper from 'vue-cropper';
// Vue.use(VueCropper);
// import 'sw';
import Swiper from 'swiper'
import websocket from './utils/websocket.js'
import myGlobalPlugin from './utils/myGlobalPlugin'
import FullLoading from '@/components/fullLoading.vue'

Vue.use(myGlobalPlugin)
Vue.prototype.$swiper = Swiper
Vue.use(installElement)
Vue.prototype.$axios = axios
Vue.config.productionTip = false
Vue.prototype.$websocket = websocket
// 全局注册组件
Vue.component('FullLoading', FullLoading)
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload)

import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer)

// // 上线
// websocket.sendSocketMsg({
// 	type: 'online',
// 	data: {
// 		uid: 244
// 	}
// });
// //发送消息
// websocket.sendSocketMsg({
// 	type: 'msg',
// 	data: {}
// });
// //开启数据监听
// websocket.addListener('msg', (res) => {
// });
// //移除数据监听
// websocket.removeListener('msg');
// // 下线
// websocket.sendSocketMsg({
// 	type: 'offline'
// });

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')
