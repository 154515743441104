import pako from 'pako';
import { fromByteArray, toByteArray } from 'base64-js';

export function encode(str, key) {
  const len = key.length;
  const b = fromByteArray(pako.deflate(str));
  const ns = fromByteArray(pako.deflate(b.substr(0, len) + key + b.substr(len)));
  const nl = ns.length;
  const l = len > nl ? nl : len;
  return ns.substr(l - 2, nl - l) + ns.substr(0, l - 2) + ns.substr(nl - 2);
}

export function decode(str, key) {
  const len = key.length;
  const nl = str.length;
  const l = len > nl ? nl : len;
  let os = str.substr(nl - l, l - 2) + str.substr(0, nl - l) + str.substr(nl - 2);
  try {
    let restored = pako.inflate(toByteArray(os), {
      to: 'string',
    });

    os = restored.replace(key, '');
    restored = pako.inflate(toByteArray(os), {
      to: 'string',
    });
    return restored;
  } catch (e) {
    return false;
  }
}
