<template>
    <div class="wrap">
        <FullLoading :show="cjUploading || tyUploading"></FullLoading>
        <div class="fiexdwrap" v-if="fixedType"></div>

        <div class="header">
            <div style="height: 25px"></div>
            <div class="title">
                <!--<div> 121212</div>-->
                <!---->
                <div class="new" @click="noviceGuide">
                    <img style="width: 20px; height: 20px" src="@/assets/static/new.png" />
                    <div class="new-text">新手引导</div>
                </div>
            </div>
        </div>
        <div class="wrapflex">
            <div class="left">
                <div class="Creative" v-if="!afterImg">
                    <div @click="$router.push({ path: '/SelectPicture' })" class="CreativeUp" style="color: white">
                        <img style="width: 40px; height: 40px" src="@/assets/static/CreativeUp.png" />
                        创意版上传
                    </div>
                    <div class="CreativeUp" style="position: relative">
                        <input class="inputwrap" type="file" ref="fileOffline" @change="handleFileUpload" />
                        <img style="width: 40px; height: 40px" src="@/assets/static/localityUp.png" />
                        相册上传

                        <!--@load="loadHandle"-->
                    </div>
                </div>
                <div class="afterImg" v-if="afterImg">
                    <img :src="afterImg" />
                </div>
            </div>
            <div
                v-if="!classList.length"
                style="display: flex; align-items: center; justify-content: center"
                class="right">
                <loading></loading>
            </div>
            <div v-else class="right">
                <!-- <div style="display: flex; flex-direction: row; flex-wrap: nowrap"> -->
                <div
                    @click="HouseHandel(el, idx)"
                    :style="{
                        background: 'url(' + el.image[0].url + ') center no-repeat',
                        backgroundSize: 'cover',
                    }"
                    class="box pointer"
                    v-for="(el, idx) in classList"
                    :key="idx"
                    v-show="houseType == 0">
                    <div class="option" :class="el.state ? 'action' : ''">
                        <div class="binding">
                            <img v-if="!el.is_permit" src="@/assets/static/lock.png" />
                            <span v-else style="opacity: 0">空白</span>
                            <span>{{ el.name }}</span>
                        </div>
                    </div>
                </div>

                <div class="optionHouse" v-if="houseType == 1">
                    <div class="p1 pointer" @click="houseType = 0">
                        <i class="el-icon-arrow-left"></i> {{ chidName }}
                    </div>
                    <div class="chid1">
                        <div
                            @click="HouseHandel1(el)"
                            class="item pointer"
                            v-for="(el, idx) in housechid1"
                            :key="idx"
                            :style="{
                                background: 'url(' + el.image[0].url + ')no-repeat  0 0 / 100% 100%',
                            }">
                            <span class="secName">{{ el.name }}</span>
                        </div>
                    </div>
                </div>
                <div class="optionHouse" v-if="houseType == 2">
                    <div class="p1 pointer" @click="houseType = housechid1.length ? 1 : 0">
                        <i class="el-icon-arrow-left"></i>
                        {{ chidName2 }}
                    </div>
                    <div class="p2wrap">
                        <div class="p2" v-for="(el, idx) in housechid2" :key="idx">
                            <div class="name">{{ el.type_title }}</div>
                            <!-- <div @click="fkwrapHandel(idx,'l')" class="fkwraphd">
                              <i class="el-icon-arrow-left"></i>
                            </div> -->
                            <div class="fkwrap" :ref="'fkwrapscol' + idx" v-scroll-x="scrollX">
                                <div
                                    @click="HouseHandel2(el1)"
                                    :class="el1.selected ? 'fkActive' : ''"
                                    class="fk scroll"
                                    v-for="(el1, idx1) in el.data"
                                    :key="idx1"
                                    :style="{
                                        background: el1.ext ?? '#222',
                                        color: el1.font_color,
                                    }">
                                    {{ el1.name }}
                                </div>
                            </div>
                            <!-- <div @click="fkwrapHandel(idx,'r')" class="fkwraphd">
                              <i class="el-icon-arrow-right"></i>

                            </div> -->
                        </div>
                    </div>

                    <!-- <div class="p2">
                    <div class="name">位置</div>
                    <div class="fk"> 客厅 </div>
                  </div>
                  <div class="p2">
                    <div class="name">风格</div>
                    <div class="fk"> 精0dfsv致</div>
                  </div> -->
                </div>
                <!-- </div> -->

                <!--    提示-->
                <div v-show="houseType == 0" class="tips">对毛坯空间进行识别 给出建议效果图</div>
            </div>
        </div>
        <div class="fixed" v-if="fixedType">
            <div v-if="!scrawlShow">
                <div class="title">
                    <div class="l">选择尺寸</div>
                    <div @click="closeScraw" class="r pointer">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="l">
                        <div class="imagewrap">
                            <img ref="image" :src="avatar" />
                        </div>
                    </div>
                    <div class="r">
                        <div class="name">尺寸</div>
                        <div @click="overrunHandel()" :class="frameType == 0 ? 'patternActive' : ''" class="pattern">
                            <img src="@/assets/static/43.png" />
                            <span>4:3</span>
                        </div>
                        <div @click="aboutHandel()" :class="frameType == 1 ? 'patternActive' : ''" class="pattern">
                            <img src="@/assets/static/34.png" />
                            <span>3:4</span>
                        </div>
                        <div @click="verticalHandel()" :class="frameType == 2 ? 'patternActive' : ''" class="pattern">
                            <img src="@/assets/static/11.png" />
                            <span>1:1</span>
                        </div>
                        <div @click="nineHandel()" :class="frameType == 3 ? 'patternActive' : ''" class="pattern">
                            <img src="@/assets/static/916.png" />
                            <span>9:16</span>
                        </div>
                        <div @click="sixteenHandel()" :class="frameType == 4 ? 'patternActive' : ''" class="pattern">
                            <img src="@/assets/static/169.png" />
                            <span>16:9</span>
                        </div>

                        <div v-if="cjUploading" class="patternBtn" style="color: #ccc; cursor: auto">确定...</div>
                        <div v-else class="patternBtn" @click="sureSava">确定</div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="title">
                    <div class="l">编辑图片</div>
                    <div @click="editCloseHandle" class="r pointer">
                        <i class="el-icon-close"></i>
                    </div>
                </div>
                <div class="box">
                    <div class="le">
                        <ceshi
                            :wid="brushNum"
                            :clo="brushColor"
                            :url="fodderUrl"
                            :val="valInp"
                            ref="cs"
                            @close="close"></ceshi>
                    </div>
                    <div class="ri">
                        <div class="name-box">
                            <div
                                class="name-item"
                                v-for="(item, index) in editType"
                                :key="index"
                                :class="item.state ? 'action' : ''"
                                @click="editChange(index, item)">
                                {{ item.name }}
                            </div>
                        </div>
                        <!--    选择素材分类-->
                        <div class="fodderType">
                            <!--素材-->
                            <div style="display: flex; flex-direction: row" v-show="editIndex === 0">
                                <div class="fodderAdd" style="position: relative">
                                    <!--@click="addS"-->
                                    <img src="@/assets/static/fodder-add.png" />
                                    <input class="inputwrap" type="file" ref="fodderInp" @change="fodderUpload" />
                                </div>
                                <div class="fodderBox">
                                    <div
                                        class="fodderAdd"
                                        v-for="(item, index) in fodderType"
                                        :key="index"
                                        @click="fodderhandle(item)"
                                        :class="item.state ? 'action' : ''">
                                        <img :src="item.image[0].url" />
                                    </div>
                                </div>
                            </div>

                            <!--    画笔粗细-->
                            <div style="display: flex; flex-direction: row" v-show="editIndex === 1">
                                <div
                                    class="brush"
                                    @click="brushChange(index)"
                                    :class="index === brushIndex ? 'action' : ''"
                                    v-for="(item, index) in brushData"
                                    :key="index">
                                    <div
                                        class="brushItem"
                                        :style="{
                                            width: item.wid,
                                            height: item.hei,
                                            background: brushColor,
                                        }"></div>
                                </div>
                            </div>
                            <!--    对比度-->
                            <div
                                style="
                                    display: flex;
                                    flex-direction: row;
                                    flex: 1;
                                    align-items: center;
                                    justify-content: space-between;
                                "
                                v-show="editIndex === 2">
                                <div class="" style="margin-right: 5px">对比度</div>
                                <div style="flex: 1">
                                    <el-slider
                                        :step="0.5"
                                        @input="conInp"
                                        :show-tooltip="false"
                                        v-model="value"
                                        :style="{ '--left': deff_left, '--width': deff_width }">
                                    </el-slider>
                                </div>

                                <div class="contrastVal">{{ valShow }}</div>
                            </div>
                        </div>
                        <!--    素材-->
                        <div class="fodder">
                            <div
                                class="fodderItem"
                                v-for="(item, index) in fodderData"
                                :key="index"
                                @click="fodderChange(index, item)"
                                v-show="editIndex === 0">
                                <img :src="item.image[0].url" />
                            </div>
                            <!--    颜色-->
                            <div
                                class="color"
                                v-for="(item, index) in colorData"
                                :key="index"
                                :class="item.state ? 'action' : ''"
                                @click="colorChange(index, item)"
                                v-show="editIndex === 1">
                                <div
                                    class="colorItem"
                                    :style="{ background: item.col }"
                                    :class="item.state ? 'action' : ''"></div>
                            </div>
                        </div>
                        <!--    确定-->
                        <div v-if="tyUploading" class="suerBtn" style="color: #ccc; cursor: auto">确定...</div>
                        <div v-else class="suerBtn" @click="sureS">确定</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import loading from '@/components/loading'
import ceshi from '@/views/ceshi.vue'

export default {
    name: 'Creative',
    components: {
        loading,
        ceshi,
    },
    mounted() {
        const user = this.$store.state.user
        if (user && user.permit && user.permit.length == 0) {
            if (user.free_use == 0) {
                this.$message({
                    message: '免费次数已使用完',
                    type: 'error',
                })
                this.$store.commit('headerTypeHandel', 4)
                setTimeout(() => {
                    this.$router.push('/merge')
                }, 1500)
            }
        }
        this.$axios.post('design/category/page').then((res) => {
            this.classList = res.data.slice(0, 3)
        })
        if (this.$store.state.type == 3) {
            let file = this.base64ImgtoFile(this.$store.state.threeImg)
            // this.isBack = !this.isBack
            // 创建 FileReader 对象
            const reader = new FileReader()
            reader.onload = (event) => {
                // 读取完成后，将结果赋值给 imageUrl
                this.avatar = event.target.result
                // this.$nextTick((res) => {
                //   this.init()
                // })
                this.fixedType = true
                this.$nextTick(() => {
                    this.init()
                })
                // this.myCropper.replace(this.avatar, false)
            }
            // 开始读取文件
            reader.readAsDataURL(file)
            return
            // let file = this.$refs.fileInput.files[0]

            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')

            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    // this.avatar = res.data.url
                    this.avatar = require('@/assets/static/phone.png')
                    this.myCropper.replace(this.avatar, false)
                    // 处理上传成功后的响应
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        }
    },
    watch: {
        '$store.state.Workbenchimage': {
            handler(newValue) {
                if (newValue.after_image) {
                    this.workbenchImage(newValue.after_image[0].url)
                }
            },
            immediate: true, // 立即执行回调函数
        },
        // '$store.state.uploadingCompleted': {
        //     handler(newValue) {
        //         if (newValue) {
        //             setTimeout(() => {
        //                 this.afterImg = this.$store.state.surl
        //                 this.$emit('imageHandel', this.afterImg)
        //                 this.isBack = false
        //                 this.fixedType = false
        //             }, 2000)
        //         }
        //     },
        //     deep: true,
        //     immediate: true, // 立即执行回调函数
        // },
        value: {
            handler(newValue) {
                this.deff_width = Math.abs(newValue - 50) + '%'
                this.deff_left = (newValue > 50 ? 50 : newValue) + '%'
            },
            immediate: true, // 立即执行回调函数
        },
    },
    data() {
        return {
            isBack: true,
            // 子级返回标题
            chidName: '',
            // 二级返回标题
            chidName2: '',
            houseType: 0,
            housechid1: [],
            housechid2: [],
            fixedType: false,
            classList: [],
            avatar: '',
            frameType: 0,
            afterImg: '',
            firstIdx: '', //一级下标
            wantValue: '', //想要的效果文本
            delValue: '', //想要删除的效果文本
            scrawlShow: false, //涂鸦
            editType: [
                {
                    name: '素材',
                    state: true,
                },
                {
                    name: '画笔',
                    state: false,
                },
                {
                    name: '对比度',
                    state: false,
                },
            ],
            // 分类
            fodderType: [],

            //     素材
            fodderData: [],
            fodderUrl: '',
            //     编辑index
            editIndex: 0,
            brushIndex: 0, //画笔粗细
            brushNum: 4,
            brushData: [
                {
                    wid: '9px',
                    hei: '9px',
                },
                {
                    wid: '15px',
                    hei: '15px',
                },
                {
                    wid: '21px',
                    hei: '21px',
                },
            ],
            colorData: [
                {
                    col: '#FFFFFF',
                    state: true,
                },
                {
                    col: '#393939',
                    state: false,
                },
                {
                    col: '#EF3610',
                    state: false,
                },
                {
                    col: '#FDBB4B',
                    state: false,
                },
                {
                    col: '#01AB0F',
                    state: false,
                },
                {
                    col: '#167EF3',
                    state: false,
                },
                {
                    col: '#9358FF',
                    state: false,
                },
                {
                    col: '#E28AFF',
                    state: false,
                },
            ],
            brushColor: '#FFFFFF',
            value: 50, //对比度拖动条的值
            valShow: 0, //对比度显示的值
            valInp: 0, //传给组件的值
            deff_left: '50',
            deff_width: '0',
            cjUploading: false,
            tyUploading: false,
        }
    },
    methods: {
        base64ImgtoFile(dataurl, filename = 'file') {
            let arr = dataurl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let suffix = mime.split('/')[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {
                type: mime,
            })
        },
        // 添加线稿
        addSketch() {
            this.$store.commit('savetype', 2)
            let file = this.$refs.fileInput.files[0]
            // 创建 FileReader 对象
            const reader = new FileReader()
            reader.onload = (event) => {
                // 读取完成后，将结果赋值给 imageUrl
                this.avatar = event.target.result
                // this.$nextTick((res) => {
                //   this.init()
                // })
                this.fixedType = true
                this.$nextTick(() => {
                    this.init()
                })
                // this.myCropper.replace(this.avatar, false)
            }
            // 开始读取文件
            reader.readAsDataURL(file)
            return
            // let file = this.$refs.fileInput.files[0]

            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')

            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    // this.avatar = res.data.url
                    this.avatar = require('@/assets/static/phone.png')
                    this.myCropper.replace(this.avatar, false)
                    // 处理上传成功后的响应
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        },
        scrollX(e) {
            console.log(e)
        },

        workbenchImage(path) {
            // 发送上传请求
            this.$axios
                .post(
                    'design/creative/getImg64base',
                    { path }
                    // {
                    //   headers: {
                    //     'Content-Type': 'multipart/form-data'
                    //   }
                    // }
                )
                .then((res) => {
                    let src = 'data:image/png;base64,'
                    this.isBack = ''
                    src += res.data
                    this.avatar = src
                    this.fixedType = true
                    this.$nextTick(() => {
                        this.init()
                    })
                    // this.avatar = res.data.url
                    // this.avatar = require('@/assets/static/bg.png')
                    // this.myCropper.replace(this.avatar, false)
                    // 处理上传成功后的响应
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        },
        backHanel() {
            if (this.isBack) {
                // this.isBack = false
            } else if (!this.afterImg && !this.isBack) {
                this.isBack = true
                this.$emit('backHanel', '2222')
            } else {
                this.afterImg = ''
                this.isBack = false
            }
        },
        isBackHandel() {
            this.isBack = !this.isBack
        },
        HouseHandel2(data) {
            let s = ''
            this.housechid2.forEach((el, idx) => {
                el.data.forEach((el1, idx1) => {
                    if (el1.id == data.id) {
                        if (this.housechid2[idx].data[idx1].selected)
                            return (this.housechid2[idx].data[idx1].selected = false)
                        this.housechid2[idx].data.forEach((el) => (el.selected = false))
                        this.housechid2[idx].data[idx1].selected = true
                        // this.$forceUpdate()
                    }
                })
            })
            let specs = []
            this.housechid2.forEach((el, idx) => {
                const arr = this.housechid2[idx].data.filter((el1) => {
                    return el1.selected
                })
                specs = [...specs, ...arr]
            })
            this.$emit('specsHandel', specs)
        },
        HouseHandel1(data) {
            localStorage.setItem('type_name', data.name)
            localStorage.setItem('type_id', data.id)
            this.$emit('category', data)
            if (data.children) {
                this.HouseHandel(data)
                return
            }

            this.houseType = 2
            this.chidName2 = data.name
            this.housechid2 = data.spData
        },
        HouseHandel(data, idx) {
            if (!data.is_permit) return this.$message.error('没有权限')
            // this.$emit('category', data)
            if (!data.children) {
                this.housechid1 = []
                this.HouseHandel1(data)
                return
            }
            this.houseType = 1
            this.chidName = data.name
            this.housechid1 = data.children
            this.firstIdx = idx
        },
        sureSava() {
            // this.isBack = false
            // this.fixedType = false
            this.cjUploading = true
            const code64 = this.myCropper
                .getCroppedCanvas({
                    imageSmoothingQuality: 'high',
                })
                .toDataURL('image/png')
            // this.$axios.post('upload/upload64', {code64}).then((res) => {
            //     this.afterImg = res.data.url
            //     this.$emit('imageHandel', this.afterImg)
            // })
            // this.$store.commit('changeWorkbenchImage', '')
            // this.$store.commit('saveScrawl', code64)
            // this.scrawlShow = true
            // this.scrawImg()
            // this.$forceUpdate()
            this.$axios.post('upload/upload64', { code64 }).then((res) => {
                // this.afterImg = res.data.url
                // this.$emit('imageHandel', this.afterImg)
                this.scrawlShow = true
                // this.$store.commit('saveScrawl', res.data.url)
                this.$store.commit('saveScrawl', res.data.url)
                this.$store.commit('delChangeWorkbenchImage')
                this.scrawImg()
                this.cjUploading = false
                this.$forceUpdate()
                // this.$router.push('/ceshi')
            })
            // this.$store.commit('changeWorkbenchImage', '')
        },
        overrunHandel() {
            this.$emit('frameHandel', 0)
            this.frameType = 0
            this.myCropper.setAspectRatio(1.3333333333333333)
        },
        aboutHandel() {
            this.$emit('frameHandel', 1)
            this.frameType = 1
            this.myCropper.setAspectRatio(0.75)
        },
        verticalHandel() {
            this.$emit('frameHandel', 2)
            this.frameType = 2
            this.myCropper.setAspectRatio(1)
        },
        nineHandel() {
            this.$emit('frameHandel', 3)
            this.frameType = 3
            this.myCropper.setAspectRatio(0.5714285714285714)
        },
        sixteenHandel() {
            this.$emit('frameHandel', 4)
            this.frameType = 4
            this.myCropper.setAspectRatio(1.75)
        },
        loadHandle() {
            this.init()
            this.fixedType = true
        },
        handleFileUpload() {
            this.cjUploading = false
            this.tyUploading = false
            // console.log(this.$refs.fileInput)
            let file = this.$refs.fileOffline.files[0]

            // 创建 FileReader 对象
            const reader = new FileReader()
            reader.onload = (event) => {
                // 读取完成后，将结果赋值给 imageUrl
                this.avatar = event.target.result
                // this.$nextTick((res) => {
                //   this.init()
                // })
                this.fixedType = true
                this.$nextTick(() => {
                    this.init()
                })
                // this.myCropper.replace(this.avatar, false)
            }
            // 开始读取文件
            reader.readAsDataURL(file)
            return
            console.log(22222)
            // let file = this.$refs.fileInput.files[0]
            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')

            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    // this.avatar = res.data.url
                    this.avatar = require('@/assets/static/phone.png')
                    this.myCropper.replace(this.avatar, false)
                    // 处理上传成功后的响应
                })
                .catch((error) => {
                    // 处理上传失败后的响应
                })
        },
        init() {
            this.myCropper = new Cropper(this.$refs.image, {
                viewMode: 2,
                // aspectRatio: 1024 / 768,
                // cropBoxMovable: false,
                dragMode: 'move',
                initialAspectRatio: 2, //裁剪形状
                aspectRatio: 0,
                // preview: '.before',
                background: false,
                autoCropArea: 1,
                zoomOnWheel: true,
                // checkCrossOrigin: true,
                wheelZoomRatio: 0.1, //通过鼠标缩放图片
                crop: function (event) {},
            })

            this.overrunHandel()
        },
        // 编辑选择
        editChange(index, item) {
            this.editType.map((item) => {
                item.state = false
            })
            item.state = true
            this.editIndex = index
            if (index === 1) {
                this.$refs.cs.Sdraw()
            }
        },
        // 自定义素材
        fodderUpload() {
            let file = this.$refs.fodderInp.files[0]
            let formData = new FormData()
            formData.append('file', file)
            formData.append('path', 'image')
            // 发送上传请求
            this.$axios
                .post('upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then((res) => {
                    this.fodderUrl = ''
                    this.fodderUrl = res.data.url
                    // this.$refs.cs.Surl()
                    this.$forceUpdate()
                })
                .catch((error) => {
                    console.log('失败')
                    // 处理上传失败后的响应
                })
        },
        // 素材切换
        fodderhandle(item) {
            console.log('item', item)
            for (let i = 0; i < this.fodderType.length; i++) {
                // 将点击的state设为true，其他设为false
                if (this.fodderType[i].id == item.id) {
                    this.fodderType[i].state = true
                    this.fodderData = item.children
                } else {
                    this.fodderType[i].state = false
                }
            }
            // // 获取第一个元素的children属性
            // this.fodderData = this.fodderType[0].children
        },
        // 素材
        scrawImg() {
            try {
                this.$axios
                    .post('design/resource/image/page')
                    .then((res) => {
                        // console.log('涂鸦素材', res.data)
                        this.fodderType = res.data
                        // 确保fodderType不为空
                        if (this.fodderType.length > 0) {
                            // 遍历fodderType数组
                            for (let i = 0; i < this.fodderType.length; i++) {
                                // 将第一个元素的state设为true，其他设为false
                                this.fodderType[i].state = i === 0
                            }
                            // 获取第一个元素的children属性
                            this.fodderData = this.fodderType[0].children
                        }
                    })
                    .catch((error) => {
                        // 处理上传失败后的响应
                    })
            } catch (error) {
                console.log(error)
            }
        },
        // 素材添加
        fodderChange(index, item) {
            this.fodderUrl = ''
            this.fodderUrl = item.image[0].url
            // this.$refs.cs.Surl()
            this.$forceUpdate()
        },
        //     画笔粗细
        brushChange(e) {
            if (e === 0) {
                this.brushIndex = 0
                this.brushNum = 4
            } else if (e === 1) {
                this.brushIndex = 1
                this.brushNum = 8
            } else if (e === 2) {
                this.brushIndex = 2
                this.brushNum = 12
            }
            this.$refs.cs.Sdraw()
            this.$forceUpdate()
        },
        //     颜色选择
        colorChange(index, item) {
            this.colorData.map((item) => {
                item.state = false
            })
            item.state = true
            this.brushColor = item.col
            this.$refs.cs.Sdraw()
            this.$forceUpdate()
        },
        sureS() {
            this.tyUploading = true
            this.$refs.cs.uploadImg()
            // setTimeout(() => {
            //     this.afterImg = this.$store.state.surl
            //     this.$emit('imageHandel', this.afterImg)
            //     this.isBack = false
            //     this.fixedType = false
            // }, 2000)
        },
        close() {
            setTimeout(() => {
                this.afterImg = this.$store.state.surl
                this.$emit('imageHandel', this.afterImg)
                this.isBack = false
                this.fixedType = false
                this.tyUploading = false
            }, 1500)
        },
        // 对比度
        conInp(e) {
            if (e > 50) {
                this.valShow = (e - 50) * 2
                this.valInp = this.valShow / 100
                // this.$refs.cs.Sfilter()
            } else {
                this.valShow = Number((e - 50) * 2)
                this.valInp = this.valShow / 100
                // this.$refs.cs.Sfilter()
            }
            this.$forceUpdate()
        },
        // 选择尺寸框，关闭
        closeScraw() {
            this.cjUploading = false
            this.fixedType = false
            this.$store.commit('delChangeWorkbenchImage')
        },
        editCloseHandle() {
            this.tyUploading = false
            this.fixedType = false
            this.scrawlShow = false
            this.$store.commit('delScrawl')
        },
        // 新手引导
        noviceGuide() {
            this.$axios.post('design/guide/guide', { type: 4 }).then((res) => {
                // this.guideList = res.data.image
                this.$emit('noviceGuide', res.data.image)
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.wrap {
    padding: 0 100px;

    .header {
        padding: 0 120px;
        display: flex;
        padding-top: 20px;
        flex-direction: column;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .new {
                padding: 8px 10px;
                border-radius: 37.54px;
                border: 1.22px solid rgba(255, 255, 255, 0.4);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                .new-text {
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 22.4px;
                    color: rgba(255, 255, 255, 0.7);
                    margin-left: 5px;
                }
            }
        }
    }

    .fixed {
        .title {
            height: 54px;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .box {
            border-top: 1px solid #222222;
            display: flex;

            .ri {
                width: 315px;
                position: relative;
                // margin-top: 20px;
                padding: 0 15px;
                //width: 250px;
                //flex: 0;
                height: 744px;
                background: #333333;
                box-sizing: border-box;
                //涂鸦
                .name-box {
                    display: flex;
                    flex-direction: row;
                    margin-top: 21px;

                    .name-item {
                        margin-right: 20px;
                        color: rgba(255, 255, 255, 1);
                        cursor: pointer;

                        &.action {
                            padding-bottom: 6px;
                            border-bottom: 2px solid #ff4600;
                            color: #ff4600;
                        }
                    }
                }

                //选择素材分类
                .fodderType {
                    display: flex;
                    flex-direction: row;
                    margin-top: 10px;

                    .fodderAdd {
                        width: 69px;
                        height: 69px;
                        flex: 0 0 69px;
                        border-radius: 15px;
                        background: #333333;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 36.08px;
                            height: 36.08px;
                        }

                        &.action {
                            background: #474747;
                        }

                        .inputwrap {
                            opacity: 0;
                            position: absolute;
                            width: 100%;
                            height: 100%;
                            color: white;
                        }
                    }

                    .fodderBox {
                        width: 200px;
                        overflow-x: scroll;
                        display: flex;
                        flex-direction: row;
                        padding-bottom: 5px;
                        /*滚动条样式*/
                        &::-webkit-scrollbar {
                            /*滚动条整体样式*/
                            width: 4.5px; /*高宽分别对应横竖滚动条的尺寸*/
                            height: 3px;
                        }

                        &::-webkit-scrollbar-thumb {
                            /*滚动条里面滚动条*/
                            border-radius: 5px;
                            -webkit-box-shadow: inset 0 0 5px #595959;
                            background-color: #595959;
                        }

                        &::-webkit-scrollbar-track {
                            /*滚动条里面轨道（背景）*/
                            -webkit-box-shadow: inset 0 0 5px #454545;
                            border-radius: 0;
                            background-color: #454545;
                        }
                    }

                    //    画笔
                    .brush {
                        width: 39px;
                        height: 39px;
                        background: #282828;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        margin-right: 10px;

                        .brushItem {
                            background: #ffffff;
                            border-radius: 50%;
                        }

                        &.action {
                            background: #ffffff;
                        }
                    }
                    ::v-deep .el-slider {
                        margin: 0 10px 0 18px;
                        width: 136px;
                        .el-slider__runway {
                            height: 6px;
                            margin: 0;
                            background: rgba(94, 94, 94, 1);
                            .el-slider__bar {
                                background-color: #ff4600;
                                width: var(--width) !important;
                                left: var(--left) !important;
                            }
                            .el-slider__button-wrapper {
                                z-index: 5;
                                .el-slider__button {
                                    height: 16px;
                                    width: 16px;
                                    background: rgba(255, 70, 0, 1);
                                    border: 2px solid rgba(255, 255, 255, 1);
                                }
                            }
                        }
                    }

                    //    对比
                    .contrastVal {
                        width: 52.5px;
                        height: 27px;
                        border-radius: 6px;
                        background: rgba(255, 255, 255, 0.1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-left: 5px;
                    }
                }

                //素材
                .fodder {
                    margin-top: 20px;
                    height: 300px;
                    overflow-y: scroll;
                    display: grid;
                    grid-template-columns: auto auto auto;
                    grid-row-gap: 10px;
                    grid-column-gap: 5px;

                    .fodderItem {
                        width: 90.6px;
                        height: 90.6px;
                        flex: 0 0 90.6px;
                        border-radius: 6.57px;
                        background: #262525;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            width: 73.95px;
                            height: 72.31px;
                        }
                    }

                    //颜色
                    .color {
                        width: 90.6px;
                        height: 90.6px;
                        flex: 0 0 90.6px;
                        border-radius: 6.57px;
                        background: #262525;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &.action {
                            width: 88.6px;
                            height: 88.6px;
                            border: 1px solid red;
                        }

                        .colorItem {
                            width: 90.6px;
                            height: 90.6px;
                            border-radius: 6.57px;

                            &.action {
                                width: 80.6px;
                                height: 80.6px;
                                border-radius: 6.57px;
                            }
                        }
                    }

                    &::-webkit-scrollbar {
                        /*滚动条整体样式*/
                        width: 4.5px; /*高宽分别对应横竖滚动条的尺寸*/
                        height: 3px;
                    }

                    &::-webkit-scrollbar-thumb {
                        /*滚动条里面滚动条*/
                        border-radius: 5px;
                        -webkit-box-shadow: inset 0 0 5px #595959;
                        background-color: #595959;
                    }

                    &::-webkit-scrollbar-track {
                        /*滚动条里面轨道（背景）*/
                        -webkit-box-shadow: inset 0 0 5px #454545;
                        border-radius: 0;
                        background-color: #454545;
                    }
                }

                //确定
                .suerBtn {
                    position: absolute;
                    width: 168px;
                    text-align: center;
                    cursor: pointer;
                    height: 48px;
                    background: #ff4600;
                    border-radius: 6px 6px 6px 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    bottom: 20px;
                    left: 65px;
                }
            }

            .r {
                .patternBtn {
                    position: absolute;
                    bottom: 60px;
                    width: 168px;
                    line-height: 48px;
                    text-align: center;
                    cursor: pointer;
                    height: 48px;
                    left: 42px;
                    background: #ff4600;
                    border-radius: 6px 6px 6px 6px;
                }

                .patternActive {
                    background: #ff4600;
                }

                .pattern {
                    img {
                        margin-right: 6px;
                    }

                    margin-top: 10px;
                    width: 210px;
                    height: 48px;
                    border-radius: 4px 4px 4px 4px;
                    border: 1px solid #666666;
                    display: flex;
                    align-items: center;
                    padding-left: 10px;
                    box-sizing: border-box;
                }

                position: relative;
                // margin-top: 20px;
                padding-left: 20px;
                width: 250px;
                height: 744px;
                background: #333333;
                box-sizing: border-box;
            }

            .le {
                //width: 1100px;
                //height: 700px;
                flex: 1;
            }

            .l {
                flex: 1;
                background: #222222;
                height: 744px;
                width: 650px;
                overflow: hidden;
                padding: 20px 0;
                box-sizing: border-box;

                .imagewrap {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    object-fit: contain; /* 图片自适应，保持比例不变，同时在子元素内部居中显示 */
                    img {
                        max-width: 100%; /* 图片宽度不超过父元素宽度 */
                        height: auto; /* 图片高度根据宽高比自动计算 */
                        max-height: 100%;
                    }
                }
            }
        }

        position: fixed;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9;
        border-radius: 20px;
        top: 50%;
        width: 1500px;
        height: 798px;
        background: #333333;
        border-radius: 4px 4px 0px 0px;
    }

    .fiexdwrap {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
    }

    .right {
        height: 566px;
        // display: flex;
        // flex-direction: column;
        // width: 900px;
        display: flex;
        width: 900px;
        flex-wrap: wrap;
        overflow: auto;

        .box {
            margin: 0 10px 20px;
            width: 269px;
            height: 269px;
            position: relative;

            .option {
                .binding {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 130px;
                    width: 130px;
                    justify-content: center;
                }

                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                top: 0;
                font-size: 20px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                background: rgba(0, 0, 0, 0.5);
                overflow: hidden;

                img {
                    width: 28px;
                    height: 28px;
                }
            }

            border-radius: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .optionHouse {
            .p2wrap {
                .fkwraphd {
                    height: 60px;
                    width: 20px;
                    line-height: 60px;
                    background: $main-color;
                    margin: 0 10px;
                    border-radius: 5px;
                }

                .fkwrap {
                    &::-webkit-scrollbar {
                        width: 6px; /* 滚动条的宽度 */
                        height: 6px;
                    }

                    &::-webkit-scrollbar-track {
                        background: #000000; /* 滚动条背景颜色 */
                    }

                    &::-webkit-scrollbar-thumb {
                        background-color: #505050; /* 滚动条横条颜色 */
                        border-radius: 4px; /* 滚动条横条的圆角 */
                    }

                    // &::-webkit-scrollbar-thumb:hover {
                    // background-color: rgba(255, 70, 0, 0.5); /* 滚动条横条在鼠标悬停时的颜色 */
                    // }

                    .fk {
                        white-space: nowrap;
                        //  -ms-overflow-style: none; /* IE 10+ *
                    }

                    -webkit-user-select: none; /* Safari/Chrome */
                    -moz-user-select: none; /* Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;
                    overflow-x: auto;
                    flex: 1;
                    display: flex;
                    align-items: center;
                    white-space: nowrap; /* 子元素不换行 */
                }

                overflow-y: scroll;
                height: 500px;

                /*滚动条样式*/
                &::-webkit-scrollbar {
                    /*滚动条整体样式*/
                    width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
                    height: 0px;
                }

                &::-webkit-scrollbar-thumb {
                    /*滚动条里面滚动条*/
                    border-radius: 5px;
                    -webkit-box-shadow: inset 0 0 5px rgba(255, 70, 0, 0.2);
                    background-color: rgba(255, 70, 0, 0.2);
                }

                &::-webkit-scrollbar-track {
                    /*滚动条里面轨道（背景）*/
                    -webkit-box-shadow: inset 0 0 5px rgba(255, 70, 0, 0.2);
                    border-radius: 0;
                    background-color: rgba(255, 70, 0, 0.1);
                }
            }

            .chid1 {
                display: flex;
                flex-wrap: wrap;

                .item {
                    width: 135px;
                    margin: 10px;
                    height: 135px;
                    background: #222222;
                    border-radius: 20px 20px 20px 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .secName {
                        background: rgb(0 0 0 / 30%);
                        border-radius: 15px;
                        width: 135px;
                        height: 135px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 18px;
                    }
                }
            }

            .p1 {
                margin-bottom: 20px;
                font-size: 28px;
            }

            .p2 {
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .name {
                    margin-right: 10px;
                }

                .fk {
                    margin: 10px;
                    width: 120px;
                    height: 120px;
                    background: #222;
                    border-radius: 20px 20px 20px 20px;
                    text-align: center;
                    line-height: 120px;
                    flex-shrink: 0;
                }

                .fkActive {
                    background: $main-color !important;
                }
            }

            display: flex;
            flex-direction: column;
            width: 100%;
            height: 566px;

            background: #333333;
            border-radius: 20px;
            padding: 40px;
            box-sizing: border-box;
        }

        //    提示
        .tips {
            font-size: 36px;
            font-weight: 700;
            letter-spacing: 0px;
            line-height: 50.4px;
            color: rgba(255, 255, 255, 1);
            margin: 160px auto;
            margin-bottom: 0;
        }
    }

    .right::-webkit-scrollbar {
        width: 4px;
    }

    .right::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(255, 255, 255, 0.2);
    }

    .right::-webkit-scrollbar-track {
        border-radius: 0;
        background: rgba(0, 0, 0, 0.1);
    }

    .left {
        .Creative {
            display: flex;
            flex-direction: column;

            .CreativeUp {
                .inputwrap {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    color: white;
                }

                position: relative;
                display: flex;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                margin-bottom: 10px;
                width: 505px;
                height: 271.89px;
                border-radius: 20px 20px 20px 20px;
                // opacity: 0.4;
                border: 2px dashed #666;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        //.btn {
        //    text-align: center;
        //    line-height: 48px;
        //    cursor: pointer;
        //    width: 280px;
        //    height: 48px;
        //    background: #ff4600;
        //    border-radius: 10px 10px 10px 10px;
        //}

        //添加图片、线稿、3D
        .btn-box {
            display: flex;
            flex-direction: column;
            cursor: pointer;

            .btn {
                width: 274px;
                height: 55px;
                border-radius: 10px;
                background: rgba(40, 40, 40, 1);
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                color: rgba(255, 255, 255, 1);
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 65px;

                .inputwrap {
                    opacity: 0;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    color: white;
                }

                &:last-child {
                    margin-top: 32px;
                }

                &:hover {
                    background: rgba(255, 70, 0, 1);
                }

                &:first-child {
                    margin-top: 0px;
                }
            }

            .tip {
                width: 274px;
                font-size: 14px;
                font-weight: 500;
                letter-spacing: 0px;
                line-height: 19.6px;
                color: rgba(255, 255, 255, 0.5);
                margin-top: 13px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .afterImg {
            display: flex;
            justify-content: center;
            align-items: center;
            object-fit: contain; /* 图片自适应，保持比例不变，同时在子元素内部居中显示 */
            width: 100%;
            height: 556px;
            border: 1px dashed white;
            border-radius: 10px;

            img {
                max-width: 100%; /* 图片宽度不超过父元素宽度 */
                height: auto; /* 图片高度根据宽高比自动计算 */
                max-height: 100%;
            }
        }

        //AI文本描述
        .AI-box {
            display: flex;
            flex-direction: column;
            position: relative;
            //AI标题-
            .AI-title {
                font-size: 28px;
                font-weight: 400;
                line-height: 39.2px;
                color: rgba(255, 255, 255, 1);
                position: absolute;
                top: -50px;
            }

            //想要的效果文本
            .want-text {
                //margin-top: 16px;

                textarea {
                    width: 474px;
                    height: 283px;
                    padding: 20px;
                    border-radius: 20px;
                    outline: none;
                    flex: 1;
                    color: white;
                    background: rgba(255, 255, 255, 0.2);
                    resize: none;
                }
            }

            //想要删除的标题
            .del-title {
                font-size: 20px;
                font-weight: 400;
                letter-spacing: 0px;
                line-height: 20px;
                color: rgba(255, 255, 255, 1);
                margin-top: 18px;
            }

            .del-text {
                margin-top: 18px;

                textarea {
                    width: 474px;
                    height: 129px;
                    padding: 20px;
                    border-radius: 12px;
                    outline: none;
                    color: white;
                    background: rgba(255, 255, 255, 0.2);
                    resize: none;
                }
            }
        }

        display: flex;
        width: 500px;
        margin-right: 50px;

        &.action {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .wrapflex {
        height: 570px;
        margin-top: 20px;
        display: flex;
        justify-content: center;
    }

    // margin: 30px 0 50px;
    // display: flex;
    // justify-content: center;
    // height: 500px;
}
</style>
