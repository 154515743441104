<template>
    <div class="container">
        <div class="head">
            <div class="new" @click="noviceGuide">
                <img style="width: 20px; height: 20px" src="@/assets/static/new.png" />
                <div class="new-text">新手引导</div>
            </div>
        </div>
        <div class="main">
            <div class="title">选择工作模式</div>
            <div class="work-box">
                <div
                    class="work-item"
                    :style="{
                        backgroundImage: 'url(' + item.img + ')',
                        backgroundSize: 'cover' /* 或其他值 */,
                    }"
                    v-for="(item, index) in patternList"
                    :key="index"
                    @click="goModel(item)">
                    <div class="txt">{{ item.name }}</div>
                    <div class="tips-txt">{{ item.tips }}</div>
                </div>
            </div>
        </div>
        <!-- 新手引导 -->
        <div v-if="showGuide" class="novice-guide">
            <img :src="guideList[currentGuideIndex].url" alt="" @click="renderNextImage" />
        </div>
    </div>
</template>

<script>
import txtImg from '../assets/static/pattern/text.png'
import btnImg from '../assets/static/pattern/btn.png'
import partImg from '../assets/static/pattern/part.png'
import onceImg from '../assets/static/pattern/once.png'
import beautImg from '../assets/static/pattern/beaut.png'
import dImg from '../assets/static/pattern/3d.png'
export default {
    data() {
        return {
            isBack: true,
            patternList: [
                {
                    value: 2,
                    name: '文字模式',
                    img: txtImg,
                    tips: '文字模式输入文字和图片生成创意图',
                    url: '/pages/staging/text_model/text_model',
                    state: true,
                },
                {
                    value: 1,
                    name: '按钮模式',
                    img: btnImg,
                    tips: '选择按钮上传图片生成创意图',
                    state: false,
                },

                {
                    value: 3,
                    name: '局部修改',
                    img: partImg,
                    tips: '局部涂抹输入文字生成创意图',
                    url: '/pages/staging/part_model/part_model',
                },
                {
                    value: 4,
                    name: '一键生成',
                    img: onceImg,
                    tips: '输入毛坯照片生成装修效果图',
                    state: false,
                },
                {
                    value: 5,
                    name: '照片美化',
                    img: beautImg,
                    tips: '输入人物照片进行照片优化',
                    state: false,
                },
                {
                    value: 6,
                    name: '3D模式',
                    img: dImg,
                    tips: '选择3D文件上传生成创意图',
                    state: false,
                },
            ],
            guideList: [],
            showGuide: false,
            currentGuideIndex: 0,
        }
    },
    methods: {
        noviceGuide() {
            console.log('llllllll')
            this.$axios.post('design/guide/guide', { type: 4 }).then((res) => {
                this.guideList = res.data.image
                this.showGuide = true
                console.log('oololool', res.data.image, this.showGuide)
            })
        },
        renderNextImage() {
            if (this.currentGuideIndex < this.guideList.length - 1) {
                this.currentGuideIndex++
            } else {
                // 渲染到最后一张时结束
                this.showGuide = false
                this.currentGuideIndex = 0
            }
        },
        goModel(item) {
            // console.log('back')
            const obj6 = {
                value: 1,
                name: '按钮模式',
                img: btnImg,
                tips: '选择按钮上传图片生成创意图',
                state: false,
            }
            if (item.value === 6) {
                this.$emit('handleModel', obj6)
                // localStorage.setItem('selectModeData', JSON.stringify(obj6))
                localStorage.setItem('selectedModelType', obj6.value)
            } else {
                this.$emit('handleModel', item)
                // localStorage.setItem('selectModeData', JSON.stringify(item))
                localStorage.setItem('selectedModelType', item.value)
            }

            // this.$router.push({ path: '/merge', query: { backType: item.type } })
        },
    },
}
</script>

<style lang="scss" scoped>
.container {
    overflow: hidden;
    .head {
        display: flex;
        flex-direction: row-reverse;
        margin: 40px 260px 0 0;
        .new {
            padding: 8px 10px;
            border-radius: 37.54px;
            border: 1.22px solid rgba(255, 255, 255, 0.4);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .new-text {
                font-size: 16px;
                font-weight: 500;
                line-height: 22.4px;
                color: rgba(255, 255, 255, 0.7);
                margin-left: 5px;
            }
        }
    }
    .main {
        display: flex;
        flex-direction: column;
        align-items: center;
        color: #fff;
        .title {
            font-size: 36px;
            font-weight: 700;
        }
        .work-box {
            width: 90%;
            margin: 20px auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .work-item {
                width: 600px;
                height: 196px;
                opacity: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                border-radius: 10px;
                margin: 0 22px 24px 0;
                cursor: pointer;

                // &:nth-child(2n) {
                //     margin: 0;
                // }
                .txt {
                    font-size: 36px;
                    font-weight: 700;
                    line-height: 50.4px;
                }

                .tips-txt {
                    font-size: 24px;
                    // font-weight: 700;
                    line-height: 33.6px;
                }
            }
        }
    }

    .novice-guide {
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
        // .guide-img {
        cursor: pointer;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        // }
    }
}
</style>
