<template>
  <div id="app">
    <!-- <keep-alive>

      </keep-alive> -->
    <router-view />

  </div>
</template>
<script>
export default {
  // mounted() {
  //   window.addEventListener('beforeunload', this.handleBeforeUnload)
  // },
  // beforeUnmount() {
  //   window.removeEventListener('beforeunload', this.handleBeforeUnload)
  // },
  // methods: {
  //   handleBeforeUnload(event) {
  //     // 执行在页面刷新之前的操作
  //     // 例如保存数据或发送请求
  //     this.saveData()
  //     // 可以通过返回一个字符串来显示给用户确认离开页面的提示信息
  //     // 注意：大多数现代浏览器不会显示自定义的提示信息，而是显示默认的提示信息
  //     event.returnValue = '确定离开页面吗？'
  //   },
  //   saveData() {
  //     // 执行保存数据的逻辑
  //   }
  // }
}
</script>
<style lang="scss">
</style>
