<template>
    <div class="picWrap">
        <div class="p1">
            <div class="pic">
                <img class="img" :src="$store.state.user.avatar[0].url"/>
                <div class="selected" v-if="$store.state.user.settled == 3">
                    <img src="@/assets/static/ruzhu.png"/>
                </div>
            </div>
            <div class="r">
                <div class="name">{{ $store.state.user.nick_name }}</div>
                <div class="info">
                    <div class="fk">{{ $store.state.user.occ_text }}</div>
                    <div class="fk">{{ $store.state.user.address }}</div>
                </div>
            </div>
        </div>

        <div class="item pointer">
            <div class="line"></div>
            <div @click="$router.push('/my/')" class="p2">个人主页</div>
            <div class="p2" @click="$router.push('/my/Myneeds')">我的需求</div>
            <!--<div @click="$router.push('/Freeentry')" class="p2">免费入驻</div>-->
            <div @click="$router.push('/wantEnter')" class="p2">免费入驻</div>
            <div @click="goService" class="p2">联系客服</div>
            <div class="line"></div>

            <div @click="quitHandel()" class="quit">退出登录</div>
        </div>

    </div>
</template>
<script>
export default {
    methods: {
        quitHandel() {
            this.$axios.post('index/logout').then((res) => {
                localStorage.clear()
                this.$store.commit('messageHandel', false)
                this.$store.commit('myHandel', false)
                this.$router.push('/login')
                this.$store.commit('changeValue', 1)

                // 刷新页面（可选）
            })
        },
        goService() {
            this.$axios.post('design/service/getServiceLink').then(res => {
                this.url = res.data
                setTimeout(() => {
                    window.open(this.url, '_blank')
                }, 500)
            })

        },
    },
    data() {
        return {
            url: null,
        }
    }
}
</script>
<style lang="scss" scoped>
.picWrap {
    .item {
        .line {
            height: 1px;
            background: #666;
            margin: 10px 0;
        }

        .quit {
            line-height: 36px;
        }

        .p2 {
            height: 36px;
            line-height: 36px;
        }

        padding: 0 16px;
        box-sizing: border-box;
    }

    .p1 {
        box-sizing: border-box;
        display: flex;
        margin-left: 18px;
        align-items: center;

        .pic {
            width: 35px;
            height: 35px;
            position: relative;

            img {
                width: 100%;
                border-radius: 50%;
                height: 100%;
            }

            .selected {
                width: 18px;
                position: absolute;
                right: -5px;
                bottom: 0;
                line-height: 0;

                img {
                    width: 18px;
                }
            }
        }

        .r {
            margin-left: 10px;
        }

        .info {
            display: flex;

            .fk {
                height: 15px;
                padding: 2px 5px;
                background: rgba(255, 255, 255, 0.2);
                border-radius: 2px 2px 2px 2px;
                font-size: 12px;
                margin-top: 8px;
                text-align: center;
                line-height: 15px;
                margin-right: 5px;
            }
        }
    }

    padding: 16px;

    color: white;
    box-sizing: border-box;
    border-radius: 10px;
    width: 280px;
    // height: 263px;
    background: #222222;
    z-index: 10;
    position: fixed;
    right: 20px;
    top: 85px;
}
</style>
