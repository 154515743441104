import axios from 'axios'
import store from '@/store'

import { decode, encode } from './pako'
import { SESSION_KEY } from './global'

// 登录地址
const logUrl = '/index/login'
const instance = axios.create({
    // baseURL: 'http://192.168.1.120/20230415gzsz/PHP/public/index.php?s=',
    timeout: 1000000, // 超时时间
    withCredentials: false,
    headers: {
        'Content-Type': 'application/json;charset=UTF-8',
    },
})
let isSecurity = false
// let isSecurity = true
// 根据环境变量设置 baseURL
if (process.env.NODE_ENV === 'development') {
    // instance.defaults.baseURL = 'http://192.168.1.20/qb_pro/20230415gzsz_cyapp/PHP/public/index.php?s=/front';
    instance.defaults.baseURL = 'http://192.168.1.32/20230415gzs_app/PHP/public/index.php?s=/front'
    // instance.defaults.baseURL = 'https://aidesign.aiinfinitedesign.com/front/'
} else {
    isSecurity = true
    instance.defaults.baseURL = 'https://aidesign.aiinfinitedesign.com/front/'
}

// 添加请求拦截器

instance.interceptors.request.use(
    (config) => {
        // 在发送请求之前做些什么
        let token = localStorage.getItem('token')
        const key = localStorage.getItem('key')
        // config.Token = ss;
        // config.headers.Authorization = 'Bearer ' + token; // 添加 Authorization 头信息
        config.headers.Token = token // 添加 Authorization 头信息
        config.headers.Port = 'front' // 添加 Authorization 头信息
        config.headers.Appverion = 2 // 添加 Authorization 头信息

        if (config.data && config.headers['Content-Type'] === 'application/x-www-form-urlencoded;charset=UTF-8') {
            // config.data = qs.stringify(config.data);
        }
        // console.log(encode(JSON.stringify(config.data), SESSION_KEY))
        if (process.env.NODE_ENV != 'development') {
            if (config.data && isSecurity && !(config.data instanceof FormData)) {
                config.data = encode(JSON.stringify(config.data), key || SESSION_KEY)
            }
        }
        // console.log('config', config)
        return config
    },
    (error) => {
        // 对请求错误做些什么
        return Promise.reject(error)
    }
)

const handleData = (response) => {
    let { data } = response
    if (isSecurity && !(data instanceof ArrayBuffer)) {
        const key = localStorage.getItem('key')

        let d = decode(data, key || SESSION_KEY)

        if (d === false && key !== SESSION_KEY) {
            d = decode(data, SESSION_KEY)
        }
        if (d !== false) {
            data = JSON.parse(d)
        } else {
            data = {
                code: 401,
                msg: '登录失效，请重新登录1',
            }
        }
    }
    if (data.code == 500 || data.code == 400) {
        store.dispatch('showSuccessMessage', data.msg) // 触发 action 显示成功消息
    }
    if (data.code == 200 && response.config.url == logUrl) {
        store.dispatch('login', data.data) // 触发 action 显示成功消息
    }

    if (data.code == 401) {
        store.dispatch('unauthorized', data.msg) // 触发 action 显示成功消息
    }

    if (data.code == 200) {
        return data
    }

    return Promise.reject(data)
}

// 添加响应拦截器
instance.interceptors.response.use(
    (response) => handleData(response),
    (err) => {
        const { response } = err
        if (response === undefined) {
            if (err.config.timeout !== 1000) {
                if (env !== 'release') {
                    console.log(err)
                } else {
                    console.log(err, '未可知错误，大部分是由于后端不支持跨域CORS或无效配置引起')
                }
            }

            return {}
        }
        return handleData(response)
    }
)

export default instance
