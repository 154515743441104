import { decode, encode } from './gzip';
import { SESSION_KEY, SOCKET_URL } from '@/config/global';

let socketOpen = false;
let ws;
let id = 0;
let onlineWss = 0;
let socketMsgQueue = [];
const bindQueue = {};
const callback = {};
const onlyCall = {};

function readBlod(blob, callback) {
	if (typeof blob === 'string') {
		callback(blob);
	} else {
		const reader = new FileReader();
		reader.onload = () => {
			callback(reader.result);
		};
		reader.readAsText(blob);
	}
}

function sendSocketMessage(msg) {
	if (socketOpen && ws && ws.readyState === 1) {
		ws.send(msg);
	} else {
		socketMsgQueue.push(msg);
	}
}

function sendSocketMsg(msg) {
	if (msg.type.toLowerCase().indexOf('bind') !== -1 || msg.type.toLowerCase().indexOf('online') !== -1) {
		bindQueue[msg.type] = msg;
	}
	msg = typeof msg === 'string' ? msg : JSON.stringify(msg);
	let data = encode(msg, SESSION_KEY);
	data = new Blob([data], { type: 'text/plain;charset=utf-8' });
	sendSocketMessage(data);
}

function addListener(event, call, isOnly = false) {
	if (isOnly) {
		onlyCall[event] = call;
	} else {
		callback[event] = call;
	}
}

function removeListener(event) {
	delete callback[event];
	delete onlyCall[event];
}

function connectSocketServer(s) {
	// eslint-disable-next-line no-nested-ternary
	const support = 'MozWebSocket' in window ? 'MozWebSocket' : 'WebSocket' in window ? 'WebSocket' : null;
	if (support == null) {
		return;
	}
	if (!s) {
		return;
	}

	if (onlineWss >= 1) {
		// 已经链接
	}
	ws = new window[support]((window.location.protocol === 'https:' ? 'wss://' : 'ws://') + s);
	ws.id = ++id;
	onlineWss++;

	ws.onmessage = (res) => {
		readBlod(res.data, (d) => {
			let data = decode(d, SESSION_KEY);
			if (data === false) {
				data = d;
			}
			data = JSON.parse(data);
			const type = data.type || '';
			switch (type) {
				case 'ping':
					sendSocketMsg({
						type: 'ping'
					});
					break;
				default:
					Object.keys(callback).forEach((key) => {
						// 监听所有事件
						if (typeof callback === 'undefined') {
							return;
						}
						const remove = callback[key](data);
						if (remove) {
							// 等于true 代表只监听一次，返回后删除
							delete callback[key];
						}
					});
					if (typeof onlyCall[type] !== 'undefined') {
						// 只监听对应的事件
						const remove = onlyCall[type](data);
						if (remove) {
							delete onlyCall[type];
						}
					}
			}
		});
	};
	ws.onopen = () => {
		socketOpen = true;
		for (const type in bindQueue) {
			sendSocketMsg(bindQueue[type]);
		}

		for (let i = 0; i < socketMsgQueue.length; i++) {
			sendSocketMessage(socketMsgQueue[i]);
		}
		socketMsgQueue = [];
	};
	ws.onclose = () => {
		socketOpen = false;
		onlineWss--;
		setTimeout(() => {
			connectSocketServer(s);
		}, 10000);
	};
}

connectSocketServer(SOCKET_URL);

export default {
	sendSocketMsg,
	addListener,
	removeListener
};
