<template>
    <div class="box">
        <div class="l" ref="container" @scroll="handleScroll">
            <div v-for="(rows, index) in list" :key="index" class="container">
                <!-- 图片 -->
                <div class="item" v-for="(el, idx) in rows" :key="idx">
                    <el-checkbox
                        v-if="el.select"
                        class="check"
                        @click.stop="delHandel(el, el.index, 2)"
                        v-model="el.select"></el-checkbox>
                    <img
                        @click.self="delHandel(el, el.index, 1)"
                        :class="el.select ? 'active' : ''"
                        v-lazy="{
                            src: el.after_image ? el.after_image[0].url : '',
                            loading: '/image/cyb.png',
                            error: '/image/LoadFail.png',
                        }"
                        style="border-radius: 10px" />
                    <!-- <img @click.self="delHandel(el,idx,1)" :class="el.select ? 'active':''" :src="el.after_image[0].url" /> -->
                    <!-- <img :class="el.select ? 'active':''" style="width:300px;" :src="el.image[0].url" /> -->
                </div>
            </div>
        </div>
        <div class="r">
            <!-- 右边 -->
            <div class="fixed">
                <div id="main" style="width: 200px; height: 200px"></div>
                <!-- <img src="@/assets/static/icon.png" /> -->
                <div>{{ total }} / 200</div>
                <div>可用{{ total }}张</div>

                <div @click="delBtn()" class="delBtn">
                    <i class="el-icon-delete"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import page from '@/config/page2'
import * as echarts from 'echarts'
export default {
    name: 'community',
    mixins: [page],
    methods: {
        delBtn() {
            const arr = this.rows.filter((res) => res.select).map((res) => res.id)
            this.$axios.post('design/creative/del', { ids: arr }).then((res) => {
                this.getList(1)
                this.getList()
                this.isFirstTime = true
            })
            // this.$axios.post('design/creative/image/del', { ids: arr[0] }).then((res) => {
            // })
        },
        delHandel(el, idx, i) {
            this.rows[idx].select = !this.rows[idx].select
            // this.$axios.post('design/creative/del', { id: el.id }).then((res) => {
            // })
        },
        handleScroll() {
            this.getList()
        },
    },
    data() {
        return {
            id: '',
            option: {
                series: [
                    {
                        name: 'Access From',
                        type: 'pie',
                        radius: '50%',
                        label: {
                            show: false,
                        },
                        itemStyle: {
                            color: ['#5d7cd8', '#7ed3f4'],
                        },
                        data: [
                            { value: 200, name: 'Search Engine' },
                            { value: 20, name: 'Direct' },
                        ],
                    },
                ],
            },
            action: 'design/creative/my',
            isFirstTime: true,
            myChart: '',
        }
    },
    watch: {
        rows: {
            handler(newVal, oldVal) {
                // console.log('this.total', this.total)
                if (this.isFirstTime) {
                    this.option.series[0].data[0].value = 200 - this.total
                    this.option.series[0].data[1].value = this.total
                    this.myChart.setOption(this.option)
                    this.isFirstTime = false
                }
            },
        },
    },
    computed: {
        list() {
            const rows = [[], [], [], [], []]
            const len = rows.length
            this.rows.forEach((it, i) => {
                const size = it.size.split('X')
                it.index = i
                it.height = (((75 - 1.25 * 2) / 5) * size[1]) / size[0]
                it.loading = `/image/loading/${Math.floor(Math.random() * 14) + 1}.png`
                rows[i % len].push(it)
            })
            return rows
        },
    },
    mounted() {
        this.page.pageSize = 20

        this.getList(1)
        this.option = {
            series: [
                {
                    name: 'Access From',
                    type: 'pie',
                    radius: '50%',
                    label: {
                        show: false,
                    },

                    data: [
                        { value: 200, name: 'Search Engine' },
                        { value: 50, name: 'Direct' },
                    ],
                },
            ],
        }
        // 基于准备好的dom，初始化echarts实例
        this.myChart = echarts.init(document.getElementById('main'))
        // 绘制图表
        this.myChart.setOption(this.option)
    },
}
</script>
<style lang="scss" scoped>
.box {
    display: flex;
    background: #333;
    height: calc(100vh - 64px);
    overflow-y: hidden;
    .l {
        width: 95rem;
        margin: 15px auto;
        display: flex;
        gap: 15px;
        &::-webkit-scrollbar {
            width: 0px; /* 滚动条的宽度 */
        }
        overflow: auto;
        // padding: 20px;
        // box-sizing: border-box;
        // flex: 1;
        // // display: grid;
        // column-count: 5;
        // column-gap: 10px;
        .container {
            flex: 1;
        }
        .item {
            position: relative;
            .check {
                position: absolute;
                right: 20px;
                top: 10px;
            }
            margin-bottom: 10px;
            img {
                border: 2px solid transparent;
                width: 99%;
            }
            .active {
                border: 2px solid $main-color;
            }
        }
    }
    .r {
        width: 300px;
        .fixed {
            .delBtn {
                position: absolute;
                bottom: 200px;
                width: 100px;
                height: 40px;
                border-radius: 10px;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
            }
            img {
                width: 120px;
                height: 120px;
                margin-top: 50px;
            }
            div {
                margin-top: 20px;
            }
            z-index: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            color: white;
            position: fixed;
            width: 300px;
            height: calc(100vh - 64px);
            background: #222222;
        }
    }
}
</style>
