<template>
  <!-- 加载中组件 此组件为请求数据时 在数据还未请求成功的情况下显示的logo-->
  <div class="container">

    <div class="loading">
      <!-- 图片 -->
      <div class="loading_image">
        <!-- <image src="/static/image/index/loading.gif" mode=""></image> -->
        <div class="renderjs" id="renderjs-div" v-show='true'>
        </div>
      </div>
      <!-- 文字 -->
      <!-- <div class="loading_text">
				加载中...
			</div> -->
    </div>

  </div>
</template>



<script >
import lottie from 'lottie-web'
// import lottie from '@/node_modules/vue-lottie'
import * as gif from '@/utils/data.json'
export default {
  name: 'loading',
  props: {
    status: {
      type: Boolean,
      default: false
    },
    loop: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      name: 'render-vm'
    }
  },
  mounted() {
    const script = document.createElement('script')
    script.src = lottie
    document.head.appendChild(script)
    script.onload = this.ready()
  },
  methods: {
    ready() {
      var params = {
        container: document.getElementById('renderjs-div'),
        renderer: 'svg',
        loop: this.loop, //是否循环播放
        autoplay: true, //是否自动播放
        animationData: gif // 加载json的文件名
      }
      var anim
      anim = lottie.loadAnimation(params) // 加载
      anim.addEventListener('complete', () => {
        /* 		this.$ownerInstance.callMethod('Indexemit', {
								destroy: true
							}) //传递响应事件，这里是播放完成自动销毁，我这里是开屏动画，用完直接销毁 */
      })
    },
    // 接收逻辑层发送的数据
    receiveMsg(newValue, oldValue, ownerVm, vm) {},
    // 发送数据到逻辑层
    emitData(e, ownerVm) {
      ownerVm.callMethod('receiveRenderData', this.name)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  // min-height: 100vh;
  margin-top: -100px;
  background-color: transparent;

  .loading {
    // margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .loading_image {
      width: 300.216px;
      height: 300.216px;

      image {
        width: 100%;
        height: 100%;
      }
    }

    .loading_text {
      font-size: 28px;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      text-align: center;
    }
  }
}
</style>
