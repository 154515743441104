<template>
    <div class="fixedr">
        <div class="class">
            <!-- <div v-for="(el,idx) in classList" @click="classType=idx" :key="idx" class="item pointer">
              <img :src="el.pic" />
              <span :class="idx==classType ? 'active':'' ">{{el.name}}</span>
            </div> -->
            <div v-for="(el,idx) in classList" :key="idx" class="item pointer" @click="$router.push('/Message')">
                <img :src="el.image"/>

                <span>{{ el.name }}</span>
                <div class="fixed" v-if="el.num">{{ el.num }}</div>
            </div>
        </div>
        <div class="list">
            <!-- <div class="item" v-for="el in 2" :key="el">
              <div class="p">43dsfasffasfafdfdffdfsfasdf242</div>
              <div @click="$router.push('/Message')" class="btn pointer">
                <span>查看电话</span>
              </div>
            </div> -->
            <div class="title" v-if="consultMe.length && type==0" @click.stop="type=1">
                <span>有一位客户需要咨询住宅服务</span>
                <span><i class="el-icon-arrow-right"></i> </span>
            </div>
            <div class="seek" v-if="type==1">
                <div class="fh" @click="type=0">
                    <span><i class="el-icon-arrow-left"></i> </span>
                </div>
                <div class="item" v-for="(el,idx) in consultMe" :key="idx">
                    <div :class="el.read_text=='未读' ?'read':'unread' ">{{ el.read_text }}</div>
                    <div>客户需要咨询住宅服务</div>
                    <div class="Detail">查看详情</div>
                </div>
            </div>
            <div v-if="type==0">

                <div class="p1" v-for="(el,idx) in rows" :key="idx+4" @click="$router.push('/Message')">
                    <div class="pic">
                        <!--            <img :src="el.friend.avatar[0].url" />-->
                        <img :src="el.friend.avatar?el.friend.avatar[0].url:'https://aiinfdesign.oss-cn-zhangjiakou.aliyuncs.com/static/my.png'"/>
                        <div class="selected" v-if="el.friend.settled == 3">
                            <img src="@/assets/static/ruzhu.png"/>
                        </div>
                    </div>
                    <div class="info">
                        <div class="name">
                            <span class="msg textOVhide">{{ el.friend.nick_name }}</span>
                            <span class="sum" v-if="el.un_read_count">{{ el.un_read_count }}</span>
                        </div>
                        <div class="message ">
                            <span class="msg textOVhide">{{ el.last_msg }}</span>
                            <span class="time">{{ time(el.last_time) }}</span>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import page from '@/config/page'

export default {
    mixins: [page],

    data() {
        return {
            type: 0,
            classType: 3,
            classList: [
                {image: require('@/assets/static/love.png'), name: '咨询', message: '有新的咨询消息', time: '昨天', num: 0},
                {
                    image: require('@/assets/static/commenton.png'),
                    name: '评论',
                    message: '有新的评论消息',
                    time: '昨天',
                    num: 0
                },
                {
                    image: require('@/assets/static/followwith-interest.png'),
                    name: '关注',
                    message: '有新的关注消息',
                    time: '昨天',
                    num: 0
                },
                {
                    image: require('@/assets/static/customerservice.png'),
                    name: '客服',
                    message: 'APP系统维护通知',
                    time: '昨天',
                    num: 0
                }
            ],
            action: 'design/friend/page',

            consultMe: []
        }
    },
    watch: {
        '$store.state.newMes'(n) {
            this.getList(1)
            return

            this.rows.forEach((el, idx) => {
                if (n.from_user_id == el.friend_id) {
                    if (n.from_user_id != this.sessionid) {
                        this.rows[idx].un_read_count += 1
                    }
                    this.rows[idx].last_msg = n.msg
                    this.rows[idx].last_time = n.create_time
                }
            })
        }
    },
    methods: {
        msgmyHandel() {
            this.$axios.post('design/message/my').then(({data}) => {
                this.classList[0].num = data.like
                this.classList[1].num = data.comment
                this.classList[2].num = data.follower
                this.classList[3].num = data.service
            })
        }
    },
    mounted() {
        this.msgmyHandel()
        this.getList(1)
        this.$axios.post('design/consult/consultMe').then((res) => {
            this.consultMe = res.data.rows
        })
    }
}
</script>
<style lang="scss" scoped>
.fixedr {
    position: fixed;
    top: 64px;
    right: 0;
    color: white;
    height: calc(100vh - 64px);
    width: 350px;
    z-index: 10;
    background: #222222;

    .seek {
        .read {
            color: $main-color;
        }

        .unread {
            color: #999;
        }

        .item {
            margin-top: 20px;
            font-size: 14px;
            display: flex;
            justify-content: space-between;

            .Detail {
                width: 72px;
                height: 26px;
                background: $main-color;
                text-align: center;
                line-height: 26px;
                border-radius: 4px 4px 4px 4px;
            }
        }
    }

    .list {
        .title {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
        }

        .p1 {
            // padding: 0 30px;
            box-sizing: border-box;
            height: 68px;
            display: flex;
            align-items: center;
            cursor: pointer;

            .info {
                display: flex;
                flex-direction: column;

                .name {
                    margin-bottom: 3px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #ffffff;
                    display: flex;
                    justify-content: space-between;

                    .msg {
                        flex: 1;
                        margin-right: 15px;
                    }

                    .sum {
                        flex-shrink: 0;
                        padding: 0 5px;
                        height: 20px;
                        background: red;
                        color: white;
                        text-align: center;
                        font-size: 12px;
                        line-height: 20px;
                        border-radius: 10px;
                    }
                }

                .message {
                    display: flex;
                    justify-content: space-between;
                    width: 250px;

                    .msg {
                        margin-right: 15px;
                        flex: 1;
                    }

                    .time {
                    }

                    font-size: 14px;
                    font-weight: 400;
                    color: #999999;
                }
            }

            .pic {
                position: relative;

                img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50px;
                    margin-right: 10px;
                }

                .selected {
                    width: 20px;
                    position: absolute;
                    right: 5px;
                    bottom: 2px;
                    line-height: 0;

                    img {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }

        margin-top: 20px;
        padding: 0 20px;

        .p {
            // width: 30px;
            margin-top: 20px;
            white-space: normal; /* 处理空格和换行符 */
            word-wrap: break-word; /* 允许单词内部换行 */
        }

        .btn {
            display: flex;
            justify-content: flex-end;

            span {
                background: $main-color;
                padding: 5px 15px;
                border-radius: 5px;
            }
        }
    }

    .class {
        display: flex;
        justify-content: space-around;

        .item {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            position: relative;

            .fixed {
                position: absolute;
                top: -5px;
                right: -5px;
                padding: 0 5px;
                height: 10px;
                height: 20px;
                font-size: 12px;
                text-align: center;
                line-height: 20px;
                background: red;
                border-radius: 10px;
            }

            img {
                width: 40px;
                height: 40px;
                border-radius: 50px;
            }

            .active {
                color: white;
            }

            span {
                margin-top: 10px;
                color: #777777;
            }
        }
    }

    .bottom {
        margin-top: 120px;
        color: white;
        display: flex;
        justify-content: center;

        .btn {
            width: 150px;
            cursor: pointer;
            height: 40px;
            text-align: center;
            line-height: 40px;
            border-radius: 10px;
            background: $main-color;
        }
    }

    .auto {
        .pic {
            width: 50px;
            height: 50px;

            background: #404040;
            border-radius: 50px;
        }

        .title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: white;
            position: relative;

            input {
                position: absolute;
                left: -60px;
                opacity: 0;
            }

            margin-top: 10px;
        }

        margin-top: 100px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .content {
        padding: 0 40px;

        .p1 {
            display: flex;

            .fk {
                .active {
                    background: $main-color;
                    border-radius: 10px;
                    cursor: pointer;
                }

                display: flex;
                width: 200px;
                background: #404040;
                border-radius: 10px;
                height: 30px;

                div {
                    flex: 1;
                    color: white;
                    line-height: 30px;
                    text-align: center;
                }
            }

            .name {
                width: 80px;
                color: white;
            }

            input,
            select {
                background: #404040;
                outline: none;
                border: 0;
                height: 30px;
                color: white;
                border-radius: 10px;
                padding-left: 10px;
                width: 180px;
            }

            select {
                width: 200px;
            }

            textarea {
                background: #404040;
                outline: none;
                border: 0;
                width: 180px;
                height: 200px;

                color: white;
                border-radius: 10px;
                padding-left: 5px;
            }

            margin-bottom: 20px;
        }
    }
}
</style>
