<template>
  <div class="stagingtop">
    <div class="item">
      <el-upload action="https://jsonplaceholder.typicode.com/posts/" list-type="picture-card" :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove">
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
    <div class="item">
      <div class="swiper mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">Slide 1</div>
          <div class="swiper-slide">Slide 2</div>
          <div class="swiper-slide">Slide 3</div>
          <div class="swiper-slide">Slide 4</div>
          <div class="swiper-slide">Slide 5</div>
          <div class="swiper-slide">Slide 6</div>
          <div class="swiper-slide">Slide 7</div>
          <div class="swiper-slide">Slide 8</div>
          <div class="swiper-slide">Slide 9</div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      // 文件上传
      dialogImageUrl: ''
    }
  },
  mounted() {
    var swiper = new this.$swiper('.mySwiper', {
      autoplay: true,
      delay: 3000,
      stopOnLastSlide: false,
      disableOnInteraction: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true // 点点点可点击
      }
    })
  },
  methods: {
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleRemove(file, fileList) {}
  }
}
</script>
<style lang="scss" scoped>
.stagingtop {
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  background: #111111;
  display: flex;
  justify-content: center;
  padding: 40px 0px;
  .item {
    display: flex;
    justify-content: center;
    align-items: center;
    // background: white;
    position: relative;
    overflow: hidden;
    border: 1px solid black;
    margin: 20px;
    width: 430px;
    height: 430px;
    border-radius: 10px;
    ::v-deep .el-upload--picture-card {
      background: transparent;
      border: none;
    }
    &:first-child {
      border: 1px dashed white;
    }
  }
}
</style>