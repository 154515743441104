export const prefix = 'QBFast'
export const PORT = ''
export const TOKEN_NAME = `QBFast${PORT}`
export const SESSION_KEY = '-SAFG-'
export const TABS_ROUTES = `TABS_ROUTES${PORT}`
export const AUTH = 'back' // 后台Back
export const versionNumber = '12.0.1'
export const UploadType = 'server'
export const COMPANY_NAME = 'AI无限设计大师'
// process.env.NODE_ENV == 'development' ? '192.168.1.120:2222' : 'aidesign.aiinfinitedesign.com/wss'
process.env.NODE_ENV == 'development' ? 'aidesign.aiinfinitedesign.com/wss' : 'aidesign.aiinfinitedesign.com/wss'
export const SOCKET_URL =
    process.env.NODE_ENV == 'development' ? '192.168.1.120:2222' : 'aidesign.aiinfinitedesign.com/wss'
