<template>
  <div class="stagingbottom">
    <div class="wrap">
      <div class="topitem">
        <div class="item" v-for="(el,idx) in 6" :key="idx">
          <img style="width:20px;height:20px;" src="@/assets/static/lock.png" />
          <span>室内设计</span>
        </div>

      </div>
      <div class="topitem" v-if="false">
        <div class="item">
          <img style="width:20px;height:20px;" src="@/assets/static/lock.png" />
          <span>室内设计</span>
        </div>
        <div class="seleinp">
          <div class="items" v-for="el in 3" :key="el">
            <div class="name">选择风格</div>
            <select>
              <option value="">fadsfdsfasf</option>
              <option value="">fadsfdsfasf</option>
              <option value="">fadsfdsfasf</option>

            </select>
          </div>
        </div>

      </div>
      <div class="bottomitem">
        <div class="l">
          <div class="p1 pointer">正方形</div>
          <div class="p1 pointer">模板</div>
          <div class="p1 pointer">整版</div>
        </div>
        <div class="c">
          <div class="p1">
            <div class="p2wrap">
              <span>张数</span> <input type="range" min="0" max="100" value="50">
              <span class="num">4</span>
            </div>
          </div>
          <div class="p1">
            <div class="p2wrap">
              <span>差异</span> <input type="range" min="0" max="100" value="50">
              <span class="num">4</span>
            </div>
            <div class="p2wrap">
              <span>倍数</span> <input type="range" min="0" max="100" value="50">
              <span class="num">4</span>
            </div>

          </div>
        </div>
        <div class="r">
          <div @click="$emit('createHandel')" class="item pointer">图像生成</div>
          <div class="item pointer">图像放大</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  props: {},
  mounted() {
    // 分类
    this.$axios.post('design/category/page').then((res) => {})
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.stagingbottom {
  background: #030303;
  flex-grow: 1; /* 占据剩余空间 */
  justify-content: center;
  display: flex;
  .wrap {
    width: 900px;
    // background: white;
    .bottomitem {
      margin-top: 40px;
      font-size: 14px;
      .r {
        .item {
          padding: 8px 14px;
          color: white;
          background: $main-color;
          margin-bottom: 10px;
          border-radius: 10px;
        }
        display: flex;
        flex-direction: column;
        border-radius: 5px;
      }
      .l {
        margin-right: 20px;
        .p1 {
          display: inline-block;
          margin-right: 20px;
          padding: 8px 20px;
          color: white;
          background: $main-color;
          margin-bottom: 10px;
          border-radius: 10px;
        }
      }
      display: flex;
      .c {
        margin-right: 20px;
        display: flex;
        color: white;
        .p1 {
          margin-right: 20px;
          .p2wrap {
            input {
              width: 150px;
            }
            height: 50px;
            display: flex;
            .num {
              padding: 3px 5px;
              background: #333333;
              height: 18px;
              border-radius: 5px;
              font-size: 14px;
              margin-left: 3px;
            }
            span {
              margin-right: 10px;
            }
          }
        }
      }
      [type='range'] {
        -webkit-appearance: none;
        appearance: none;
        outline: 0;
        // margin-left: 50px;
        background-color: transparent;
        width: 160px;
      }

      [type='range']::-webkit-slider-runnable-track {
        height: 4px;
        background: #eee;
      }

      [type='range' i]::-webkit-slider-container {
        height: 20px;
        overflow: hidden;
      }

      [type='range']::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: $main-color;
        border: 1px solid transparent;
        margin-top: -8px;
        border-image: linear-gradient($main-color, $main-color) 0 fill / 8 20 8 0 / 0px 0px 0 2000px;
      }
    }

    .topitem {
      display: flex;

      .seleinp {
        padding: 20px 0;
        box-sizing: border-box;
        width: 600px;
        display: flex;
        flex-wrap: wrap;
        .items {
          margin-right: 50px;
          color: white;
          width: 250px;
          .name {
            font-size: 14px;
            margin-bottom: 5px;
          }
          select {
            width: 260px;
            height: 30px;
            border-radius: 5px;
          }
        }
      }
      .item {
        cursor: pointer;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;
        background: rgb(75, 64, 64);
        margin: 20px;
        width: 120px;
        color: white;
        height: 100px;
        text-align: center;
        line-height: 30px;
        border-radius: 5px;
      }
    }
  }
}
</style>