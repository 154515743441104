<template>
    <div class="back" :style="{ top: top + 'px', left: left + 'px', right: right + 'px' }" @click="backHandle">
        <i class="el-icon-arrow-left"></i>
        返回
    </div>
</template>

<script>
export default {
    props: {
        top: {
            type: Number,
            default: 0,
        },
        left: {
            type: Number,
            default: 0,
        },
        right: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {}
    },
    methods: {
        backHandle() {
            this.$emit('back', -1)
        },
    },
}
</script>

<style lang="scss" scoped>
.back {
    text-align: center;
    line-height: 29px;
    color: white;
    cursor: pointer;
    font-size: 14px;
    width: 80px;
    height: 29px;
    position: absolute;
    background: rgba(51, 51, 51, 1);
    border-radius: 4px;
    z-index: 9;
    .el-icon-arrow-left {
        margin-right: 4px;
    }
}
</style>
