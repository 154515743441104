<template>
    <div class="diy-slider-container">
        <el-slider v-model="value" :step="0.5"></el-slider>
    </div>
</template>

<script>
export default {
    data() {
        return {
            value: 50,
        }
    },
}
</script>

<style lang="scss" scoped>
$changWidth: 0%;

.diy-slider-container {
    .el-slider {
        .el-slider__runway {
            .el-slider__bar {
                width: $changWidth !important;
            }
        }
    }
}
</style>
