<template>
    <div class="home">
        <headers :type="type" @send-message="handleMessage"></headers>
        <!-- <merge></merge> -->

        <!-- <staging v-if="type==2"></staging> -->
        <operating v-if="$store.state.headerType == 2"></operating>
        <!-- <work-pattern v-if="$store.state.headerType == 2"></work-pattern> -->
        <originality v-if="$store.state.headerType == 3"></originality>
        <Myinfo v-if="$store.state.headerType == 4"></Myinfo>
        <creation-centre v-if="$store.state.headerType == 5"></creation-centre>
    </div>
</template>

<script>
// 新
import headers from '@/components/header.vue'
import merge from '@/components/merge'
import Myinfo from '@/components/Myinfo'
import staging from '@/components/staging'
import originality from '@/components/originality'
import Operating from '@/components/operating.vue'
import CreationCentre from '@/views/CreationCentre.vue'
import CreationList from '@/views/CreationList.vue'
import workPattern from '@/views/workPattern.vue'
// import Entrymessage from '@/components/staging/Entrymessage'
export default {
    name: 'HomeView',
    data() {
        return {
            type: 2,
            showOperating: false,
        }
    },
    components: { headers, merge, Myinfo, staging, originality, Operating, CreationCentre, CreationList, workPattern },

    methods: {
        handleMessage(i) {
            // this.type = i
        },
        goModel(value) {
            console.log('value', value)
            this.$router.push({ path: '/merge', query: { backType: value, showBack: true } })
            this.showOperating = true
        },
    },
    mounted() {
        // 假设您有一个滑动事件的处理函数，可以在其中进行滑动操作
        // 监听滑动事件
        // console.log('?>>>>>>', this.$store.state.headerType)
    },
}
</script>
<style lang="scss" scoped></style>
