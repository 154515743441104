export default {
	install(Vue) {
		Vue.prototype.time = function (e) {
			// 获取当前时间
			const now = new Date();

			// 将字符串格式的时间转换为Date对象
			const timeStr = e;
			const time = new Date(timeStr);

			// 计算时间差，单位为毫秒
			const diffMs = now.getTime() - time.getTime();
			let timeName = '';
			// 根据时间差输出不同的结果
			if (diffMs < 60000) {
				// 一分钟之内
				timeName = '刚刚';
			} else if (diffMs < 3600000) {
				// 一小时之内
				const diffMins = Math.floor(diffMs / (1000 * 60));
				timeName = diffMins + '分钟前';
			} else if (diffMs < 86400000) {
				// 一天之内
				const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
				timeName = diffHours + '小时前';
			} else if (diffMs < 604800000) {
				// 一周之内
				const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
				timeName = diffDays + '天前';
			} else if (diffMs < 2592000000) {
				// 一个月之内
				const diffWeeks = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 7));
				timeName = diffWeeks + '周前';
			} else if (diffMs < 31536000000) {
				// 一年之内
				const diffMonths = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 30));
				timeName = diffMonths + '个月前';
			} else {
				// 一年以上
				const diffYears = Math.floor(diffMs / (1000 * 60 * 60 * 24 * 365));
				timeName = diffYears + '年前';
			}
			return timeName;
		};
	}
};
