<template>
    <div class="content">
        <headers :type="type" @send-message="handleMessage"></headers>
        <div class="box">
            <!-- 返回按钮 -->
            <backBtn :left="150" :top="20" @back="back"></backBtn>
            <div class="img-box" v-for="(item, index) in rows" :key="index" @click="goDetail(item)">
                <!-- <img
                    :src="item.sample && item.sample.length > 0 ? item.sample[0].url : defaultImg"
                    class="bj-img"
                    :style="{ width: '100%', height: '352px', borderRadius: '10px', objectFit: 'cover' }" /> -->
                <img :src="item.sample && item.sample.length > 0 ? item.sample[0].url : defaultImg" class="bj-img" />
                <div class="item">
                    <div class="item-box">
                        <!--头像和入驻-->
                        <div class="tx">
                            <!--<img src="@/assets/static/Ailogo.png">-->
                            <img
                                v-if="item.user"
                                v-lazy="{
                                    src: item.user.avatar[0].url,
                                    loading: '/image/commin.png',
                                    error: '/image/LoadFail.png',
                                }" />
                            <img v-else src="../assets/static/Ailogo.png" alt="" />
                            <div class="tx-icon">
                                <img src="@/assets/static/ruzhu.png" />
                            </div>
                        </div>
                        <!--信息和介绍-->
                        <div class="msg">
                            <!--信息-->
                            <div class="name-box">
                                <!--姓名-->
                                <div class="name">{{ item.user ? item.user.nick_name : '未知设计师' }}</div>
                                <!--    地区和职位-->
                                <div class="tip" v-if="item.user && item.user.address">{{ item.user.address }}</div>
                                <div class="tip" v-if="item.type_name">{{ item.type_name }}</div>
                            </div>
                            <!--    介绍-->
                            <div class="referral">{{ item.introduction }}</div>
                        </div>
                        <!--    图标-->
                        <div style="flex: 1; display: flex; justify-content: flex-end; align-items: center">
                            <div class="icon">
                                <img src="@/assets/static/cread-icon.png" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import page from '@/config/page'
import headers from '@/components/header.vue'
import backBtn from '@/components/backBtn.vue'
import defaultImg from '../assets/static/a12.png'
export default {
    mixins: [page],
    components: {
        headers,
        backBtn,
    },
    data() {
        return {
            defaultImg: defaultImg,
            type: 5,
            action: 'design/settled/page',
        }
    },
    created() {
        this.getList(1)
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        goDetail(item) {
            this.$router.push({
                path: '/CreationDetail',
                query: { detail: item },
            })
        },
        handleScroll() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
            const clientHeight = document.documentElement.clientHeight || window.innerHeight
            if (scrollTop + clientHeight >= scrollHeight) {
                if (this.rows.length < this.total) {
                    this.getList()
                }
            }
        },
        handleMessage(i) {
            // this.type = i
        },
        back(value) {
            this.$router.go(value)
        },
    },
}
</script>
<style lang="scss" scoped>
.content {
    height: 100vh;
}

.box {
    padding: 63px 260px 0;
    display: flex;
    background: #111111;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    .img-box {
        border-radius: 10px;
        position: relative;
        margin-top: 15px;
        width: 640px;
        height: 352px;
        overflow: hidden;

        .bj-img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .item {
            width: 389px;
            height: 115px;
            border-radius: 10px;
            background: rgba(40, 40, 40, 1);
            position: absolute;
            z-index: 9999;
            bottom: 0;

            .item-box {
                padding: 20px 14px;
                display: flex;
                flex-direction: row;
                //align-items: center;
                //头像和入驻
                .tx {
                    width: 54px;
                    height: 54px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    border-radius: 50%;

                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }

                    .tx-icon {
                        width: 18px;
                        height: 18px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        position: absolute;
                        border-radius: 50%;
                        top: 35px;
                        left: 35px;

                        img {
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                        }
                    }
                }

                //信息和介绍
                .msg {
                    width: 234px;
                    display: flex;
                    flex-direction: column;
                    margin-left: 14px;
                    //信息
                    .name-box {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;

                        .name {
                            width: 108px;
                            font-size: 18px;
                            font-weight: 400;
                            line-height: 26.06px;
                            color: rgba(255, 255, 255, 1);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        .tip {
                            padding: 3px 7px;
                            border-radius: 4.98px;
                            background: rgba(65, 65, 65, 1);
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin-left: 7px;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16.8px;
                            color: rgba(255, 255, 255, 1);
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    //    介绍
                    .referral {
                        margin-top: 8px;
                        font-size: 14px;
                        font-weight: 400;
                        letter-spacing: 0px;
                        line-height: 19.6px;
                        color: rgba(255, 255, 255, 1);
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                //    图标
                .icon {
                    width: 20px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //margin-left: 40px;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
</style>
